import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function BlogPreview(props) {
   const {Breadcrumbslistall,bloglistpreviewall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     bloglistpreview(props.match.params.Addpreview);
   
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_Blog);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 

 const [isbloglistpreview, setbloglistpreview] = useState([]);
 const bloglistpreview = async (ids) =>{
 try {
        const bloglistpreviewsss = await bloglistpreviewall(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('bloglistpreview',bloglistpreviewsss.bloglistpreviewlist);
                      setbloglistpreview(bloglistpreviewsss.bloglistpreviewlist);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="blogssspreviesss blogsss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isbloglistpreview.map((item, index) => ( 
               <div  key={item.blog_id } className="col-md-12">
                    
               <div className="sectionseven3">
               <h3> {item.title} </h3>
               <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
                  <img src={BaseURLuploads+item.image} alt="" />
                 
                 
                  <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: item.describtion }} /> 
                
                 
               </div>
               </div>
            ))}
  </div>
  </div>
   </div>
</div>






   </>
  );
}

export default BlogPreview;