import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Video() {
   const {Breadcrumbslistall,allVideolist} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
    
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_video);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 
 const [pageNumber, setPageNumber] = useState(1);
 const observer = useRef()
 const loadingRef = useCallback(node => {
   
   if (observer.current) observer.current.disconnect()
   observer.current = new IntersectionObserver(entries => {
     if (entries[0].isIntersecting ) {
       setPageNumber(prevPageNumber => prevPageNumber + 1)
       //latestfeeds(prevPageNumber => prevPageNumber + 1)
     }
   })
   if (node) observer.current.observe(node)
 }, [])
 useEffect( () => {
   window.scrollTo(0, 0);
}, []); 
 useEffect( () => {
   if (isFetching === true) return;
   allVideo(pageNumber);
       return () => {
         console.log("This will be logged on unmount");
       }
 }, [pageNumber])

 const [isallVideosss, setallVideosss] = useState([]);
 const [isFetching, setIsFetching] = useState(false);

 const allVideo = async(pageNumber) => {
   const allVideosss = await allVideolist(pageNumber);
   if(allVideosss.success === 1){
          console.log('allVideo',allVideosss);
          setallVideosss(prevBooks => {
                 return [...prevBooks, ...allVideosss.data]
          })
       
          if(allVideosss.data.length < 1){
                 setIsFetching(true);
          }
   }
}
const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- --------------------------------------------------------------------------------------------------- */}

<div className="videos">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isallVideosss.map((item, index) => ( 
         <div ref={loadingRef} key={item.Sermons_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-5">
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
        
         </div>
         <div className="col-md-6">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
            <Link to={`/VideoPreview/${item.Sermons_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> More </span></Link>
            </div>
         </div>  
      ))}
  </div>
  </div>
   </div>
</div>

  


   </>
  );
}

export default Video;