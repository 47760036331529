import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function About() {
   const {Breadcrumbslistall,aboutsall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     abouts();
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_about);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [isabouts, setabouts] = useState([]);
 const abouts = async() =>{
 try {
        const aboutsss = await aboutsall();
               if(aboutsss.success === 1){
                      console.log('abouts',aboutsss.aboutslist);
                      setabouts(aboutsss.aboutslist);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
{isabouts.map((item, index) => ( 
<div  key={item.about_id} >   
       {item.Who_We_Are_code === 'wwrc' ? <>
              {item.Who_We_Are_active === 'yes' || item.Who_We_Are_active === '' ?
                     <div className="aboutpages" >
                     <div className="container">
                            <div className="row">
                            <div className="col-md-12">
                            
                                   
                                   <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.W_W_A_heading }} /> 
                                   <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.Who_We_Are }} /> 
                            
                            </div>
                            </div>
                     </div>
                     </div>
              : null }
       </> : null }
</div>
      
))}
{/* -------------------- Statement of Faith --------------------------------------------------------------------------------------------------- */}

{isabouts.map((item, index) => ( 
<div  key={item.about_id} >   
       {item.S_of_Faith_code === 'sofc' ? <>
              {item.S_of_Faith_active === 'yes' || item.S_of_Faith_active === '' ?
<div className="aboutpages2" >
   <div className="container">
        <div className="row">
               <div className="col-md-12 aboutpages2qqq">   
               <h1   className="aboutpagesqqq3" dangerouslySetInnerHTML={{ __html: item.S_O_F_heading }} />  
               <div   className="aboutpagesqqq4" dangerouslySetInnerHTML={{ __html: item.Statement_of_Faith }} /> 
            </div>
      
           

        </div>
    </div>
</div>
   : null }
   </> : null }
</div>
  
))}
{/* --------------------  Mission --------------------------------------------------------------------------------------------------- */}
{isabouts.map((item, index) => ( 
<div  key={item.about_id} >   
       {item.Mission_code === 'MC' ? <>
              {item.Mission_active === 'yes' || item.Mission_active === '' ?

       <div id="Mission"  className="aboutpages3" style={{backgroundImage: `url(${BaseURLuploads+item.mission_img})`}}>
       <div className="container">
              <div className="row">
              
                     <div className="col-md-12"> 
                            <h1  className="aboutpagesqqq5" dangerouslySetInnerHTML={{ __html: item.M_C_heading }} />
                            <div  className="aboutpagesqqq6"  dangerouslySetInnerHTML={{ __html: item.Mission }} /> 
                     </div>
       

              </div>
       </div>
       </div>
 : null }
 </> : null }
</div>

))}
{/* --------------------  Vision --------------------------------------------------------------------------------------------------- */}
{isabouts.map((item, index) => ( 
<div  key={item.about_id} >   
       {item.Vision_code === 'VC' ? <>
              {item.Vision_active === 'yes' || item.Vision_active === '' ?
<div id="Vision" className="aboutpages" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
       {isabouts.map((item, index) => ( 
                     <div  key={item.about_id} className="col-md-12">
                            <h1   className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.V_C_heading }} />
              <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.Vision }} /> 
              </div>
       ))} 
             </div>
        </div>
    </div>
</div>
: null }
</> : null }
</div>

))}

{/* --------------------  History --------------------------------------------------------------------------------------------------- */}
{isabouts.map((item, index) => ( 
       <div  key={item.about_id} >   
              {item.History_code === 'HC' ? <>
                     {item.History_active === 'yes' || item.History_active === '' ?
<div className="aboutpages4" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
       
   {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12">
        
         <div className="col-md-6 " >
              <div className="" >
              <h1  className="aboutpagesqqq7" dangerouslySetInnerHTML={{ __html: item.H_C_heading }} />
              <div  className="aboutpagesqqq8" dangerouslySetInnerHTML={{ __html: item.History }} /> 
              </div>
         </div>
         <div className="col-md-6" >
                  <img className="attachmentsss" src={BaseURLuploads+item.history_img} alt="" />
         </div>
         </div>
   ))}  
             </div>
        </div>
    </div>
</div>
: null }
</> : null }
</div>

))}














   </>
  );
}

export default About;