import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { confirmAlert } from 'react-confirm-alert'; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminChurchChoir() {
   const {ChurchChoircsssall,deleteChurchChoircsss} = useContext(MyContext);

   useEffect( () => {
   
       ChurchChoircsss();
     window.scrollTo(0, 0);
  }, []);
 
  const [isabouts, setabouts] = useState([]);
  const ChurchChoircsss = async() =>{
  try {
         const aboutsss = await ChurchChoircsssall();
                if(aboutsss.success === 1){
                       console.log('ChurchChoircsss',aboutsss.ChurchChoirclist);
                       setabouts(aboutsss.ChurchChoirclist);
         }
  } catch (error) { throw error;}
 }
const deletes = (blogids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deleteblogs(blogids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deleteblogs = async (blogids) => {
           
       try {
              const deleteblogssss = await deleteChurchChoircsss(blogids);
              if(deleteblogssss.success === 1){
                     setabouts([]);
                     ChurchChoircsss();
     
              }
       } catch (error) { throw error;}      
     
     
     }
  return (
   <>
<Link to={`/AdminAddChurchChoir`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Create new section </span></Link>
<Link to={`/AdminServicesall`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link> 
{/* -------------------- --------------------------------------------------------------------------------------------------- */}
<div className="Adminservicespage" >

       
   {isabouts.map((item, index) => ( 
               <div  key={item.churchchoir_id } className="servicespage">

       <Link to={`/AdminChurchChoirEdit/${item.churchchoir_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></Link>
       <p onClick={()=>deletes(item.churchchoir_id)} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete  </span></p>

       <div className="col-md-12">
        <h1  dangerouslySetInnerHTML={{ __html: item.heading1 }} /> 
        
       
       <div className={ "table-header " + (item.image1 !== '' ? " servicespagelisttext " : "servicespagelisttext2 ")}>
       {item.image1 !== '' ? 
                     <img className="attachmentsss" src={BaseURLuploads+item.image1} alt="" />
       : null}
               <div  dangerouslySetInnerHTML={{ __html: item.text1 }} /> 
         </div>
         
         </div>
         </div>
   ))}  
             </div>














   </>
  );
}

export default AdminChurchChoir;