import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminpasterpagesUpdate from './AdminpasterpagesUpdate';
import AdminpasterpagesBBBUpdate from './AdminpasterpagesBBBUpdate';
import AdminpasterpagesUpdateCCC from './AdminpasterpagesUpdateCCC';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminPasterMessage() {
   const {PasterMessageall,SectionAAADisableall,SectionBBBDisableall,SectionCCCDisableall} = useContext(MyContext);
//    const {PasterMessageall,SectionwwrcDisableall,SectionsofcDisableall,SectionsMCDisableall,SectionsVCDisableall,SectionsHCDisableall} = useContext(MyContext);

   useEffect( () => {
     PasterMessage();
     window.scrollTo(0, 0);
  }, []);
 
  
 const [isPasterMessage, setPasterMessage] = useState([]);
 const PasterMessage = async() =>{
 try {
        const aboutsss = await PasterMessageall();
               if(aboutsss.success === 1){
                      console.log('PasterMessage',aboutsss.PasterMessagelist);
                      setPasterMessage(aboutsss.PasterMessagelist);
        }
 } catch (error) { throw error;}
}

 const [isPasterpagesEdit, setPasterpagesEdit] = useState(false);
// const [isaboutpagestwoEdit, setaboutpagestwoEdit] = useState(false);
// const [isaboutpagesMCEdit, setaboutpagesMCEdit] = useState(false);
// const [isaboutpagesVCEdit, setaboutpagesVCEdit] = useState(false);
 const [isPasterpagesBBBEdit, setPasterpagesBBBEdit] = useState(false);
 const [isPasterpagesCCCEdit, setPasterpagesCCCEdit] = useState(false);
 const PasterpagesEdit = () =>{ setPasterpagesEdit(true); }
// const aboutpagestwoEdit = () =>{ setaboutpagestwoEdit(true); }
// const aboutpagesMCEdit = () =>{ setaboutpagesMCEdit(true); }
// const aboutpagesVCEdit = () =>{ setaboutpagesVCEdit(true); }
 const PasterpagesBBBEdit = () =>{ setPasterpagesBBBEdit(true); }
 const PasterpagesCCCEdit = () =>{ setPasterpagesCCCEdit(true); }
 const editcancel = async() =>{ setPasterpagesEdit(false);}
// const editcanceltwo = async() =>{ setaboutpagestwoEdit(false);}
// const editcancelMC = async() =>{ setaboutpagesMCEdit(false);}
// const editcancelVC = async() =>{ setaboutpagesVCEdit(false);}
 const editcancelBBB = async() =>{ setPasterpagesBBBEdit(false);}
 const editcancelCCC = async() =>{ setPasterpagesCCCEdit(false);}

const SectionAAADisable = async(ids,datas) =>{
       try {
              const SectionwwrcDisablesss = await SectionAAADisableall(ids,datas);
                     if(SectionwwrcDisablesss.success === 1){
                            console.log('SectionAAADisable');
                            setPasterMessage([]);
                            PasterMessage();
                            
              }
       } catch (error) { throw error;}
     }
const SectionsBBBDisable = async(ids,datas) =>{
       try {
              const SectionBBBDisablesss = await SectionBBBDisableall(ids,datas);
                     if(SectionBBBDisablesss.success === 1){
                            console.log('SectionBBBDisable');
                            setPasterMessage([]);
                            PasterMessage();
                            
              }
       } catch (error) { throw error;}
     }
const SectionCCCDisable = async(ids,datas) =>{
       try {
              const SectionCCCDisablesss = await SectionCCCDisableall(ids,datas);
                     if(SectionCCCDisablesss.success === 1){
                            console.log('SectionCCCDisable');
                            setPasterMessage([]);
                            PasterMessage();
                            
              }
       } catch (error) { throw error;}
     }

     const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;


  return (
       <div className="paster Adminaboutpages" >

{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}

{isPasterpagesEdit === false ? 
<div className="aboutpages" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
           
            {isPasterMessage.map((item, index) => ( 
               <div  key={item.pastermsg_id} className="col-md-12">     
                {item.pm_sec1_code === 'AAA' ? <>
                <p onClick={PasterpagesEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                           
              {item.pm_sec1_active === 'yes' || item.pm_sec1_active === '' ? <>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionAAADisable('no','AAA')}    className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section </span></p>
               <p className="Adminbuttonlink2mmm"> This is section now visible on  pastor message page  </p>
               </>
            :
            <> 
               <p onClick={()=>SectionAAADisable('yes','AAA')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on pastor message page </span></p>
               <p  className="Adminbuttonlink2mmm2"> This is section now hidden on  pastor message page   </p>
            </>}
                     </> : null }

              <div className={ "col-md-12 " + (item.pm_sec1_active === 'yes' || item.pm_sec1_active === '' ? " adminenables " : " admindisablsss")}> 

                     
               <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.pm_sec1_heading }} /> 
               <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.pastermsg_sec1 }} /> 
            </div>
            </div>
      
            ))}
             </div>
        </div>
    </div>
</div>
: <>
 {isPasterMessage.map((item, index) => ( 
       <div  key={item.pastermsg_id} className="col-md-12">  
              <AdminpasterpagesUpdate ids={item.pastermsg_id} content={item.pastermsg_sec1}  heading={item.pm_sec1_heading} cancel={editcancel} refresh={ ()=>PasterMessage()}  />
       </div>
      
))}


       </>
}


{/* --------------------  Paster Message image --------------------------------------------------------------------------------------------------- */}
{isPasterpagesBBBEdit === false ? <>
{isPasterMessage.map((item, index) => ( 
<div key={item.pastermsg_id} className="aboutpages4" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
       
            {item.pm_sec2_code === 'BBB' ? <>
                <p onClick={PasterpagesBBBEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                        

              {item.pm_sec2_active === 'yes' || item.pm_sec2_active === '' ? <>
                     <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p onClick={()=>SectionsBBBDisable('no','BBB')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section  </span></p>
                     <p className="Adminbuttonlink2mmm"> This is section now visible on pastor message page  </p>
               </>
            :
            <> 
                     <p onClick={()=>SectionsBBBDisable('yes','BBB')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on pastor message </span></p>
                     <p  className="Adminbuttonlink2mmm2"> This is section now hidden on the pastor message  </p>
            </>}

                     </> : null }
                     <div className= "col-md-12" >
                            <h1  className="aboutpagesqqq7" dangerouslySetInnerHTML={{ __html: item.pm_sec2_heading }} />
                     </div>
       <div className={ "col-md-5" + (item.pm_sec2_active === 'yes' || item.pm_sec2_active === '' ? " adminenables " : " admindisablsss")}> 
                  <img className="pasmsgimg" src={BaseURLuploads+item.pastermsg_img} alt="" />
         </div>
         <div className="col-md-7 " >
         <div className={ " " + (item.pm_sec2_active === 'yes' || item.pm_sec2_active === '' ? " adminenables " : " admindisablsss")}> 
              
              <div  className="aboutpagesqqq8" dangerouslySetInnerHTML={{ __html: item.pastermsg_sec2 }} /> 
              </div>
         </div>
        
        
             </div>
        </div>
    </div>
</div>

))}  
 </>
 : <>
 {isPasterMessage.map((item, index) => ( 
       <div  key={item.pastermsg_id} className="col-md-12">  
              <AdminpasterpagesBBBUpdate ids={item.pastermsg_id} content={item.pastermsg_sec2} heading={item.pm_sec2_heading} cancel={editcancelBBB} refresh={ ()=>PasterMessage()}  />
       </div>
      
 ))}
 
 
       </>
 }


{isPasterpagesCCCEdit === false ? 
<div className="aboutpages" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
           
            {isPasterMessage.map((item, index) => ( 
               <div  key={item.pastermsg_id} className="col-md-12">     
                {item.pm_sec3_code === 'CCC' ? <>
                <p onClick={PasterpagesCCCEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                           
              {item.pm_sec3_active === 'yes' || item.pm_sec3_active === '' ? <>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionCCCDisable('no','CCC')}    className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section </span></p>
               <p className="Adminbuttonlink2mmm"> This is section now visible on  pastor message page  </p>
               </>
            :
            <> 
               <p onClick={()=>SectionCCCDisable('yes','CCC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on pastor message page </span></p>
               <p  className="Adminbuttonlink2mmm2"> This is section now hidden on  pastor message page   </p>
            </>}
                     </> : null }

              <div className={ "col-md-12 " + (item.pm_sec3_active === 'yes' || item.pm_sec3_active === '' ? " adminenables " : " admindisablsss")}> 

                     
               <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.pm_sec3_heading }} /> 

               {item.pastermsg_video.match(regExp) ? <> 
            {item.pastermsg_video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.pastermsg_video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}

               <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.pastermsg_sec3 }} /> 
            </div>
            </div>

            
      
            ))}
             </div>
        </div>
    </div>
</div>
: <>
 {isPasterMessage.map((item, index) => ( 
       <div  key={item.pastermsg_id} className="col-md-12">  
              <AdminpasterpagesUpdateCCC ids={item.pastermsg_id} content={item.pastermsg_sec3}  heading={item.pm_sec3_heading} video={item.pastermsg_video} cancel={editcancelCCC} refresh={ ()=>PasterMessage()}  />
       </div>
      
))}


       </>
}



   </div>
  );
}

export default AdminPasterMessage;