import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 
import { EditorState, convertToRaw, ContentState,convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {toast} from "react-toastify";


function AdminaboutpagestwoUpdate(props) {
  
  const {aboutpagetwoupdatesall} = useContext(MyContext);

  const [userInfo, setuserInfo] = useState({
       file:'',
         
});

  const [loadingadd, setloadingadd] = useState(false);

        let editorState = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.content)));
       const [heading, setheading] = useState(editorState);
       let editorStatetitle = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.heading)));
       const [headingtitle, setheadingtitle] = useState(editorStatetitle);
     

const html = '';
const contentBlock = htmlToDraft(html);
if (contentBlock) {
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
}
const onEditorStateChange = (editorState) => { setheading(editorState);}
const onEditorStateChangetitle = (editorStatetitle) => { setheadingtitle(editorStatetitle);}

const editcancel = () =>{
       props.cancel();
}
const aboutpagetwoupdates = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                    
                     const dataaddtwo = await aboutpagetwoupdatesall(userInfo.heading.value,props.ids,userInfo.headingtitle.value);
                     if(dataaddtwo.success === 1){
                            console.log('aboutpagetwoupdatesall',dataaddtwo);
                            setloadingadd(false);
                            event.target.reset(); 
                            props.cancel();
                            props.refresh();
                          
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}



  return (
   <>
     <div className="bookads2">
        <div className="col-md-12" style={{padding:0}}>
               <form onSubmit={aboutpagetwoupdates} className="central-meta ">
               <div className="clearfix"></div>
               
                 
                     <div className="form-row">
                     <div className="form-group col-md-12 editor editorTitle textalignblocksss2">
                     <label className="font-weight-bold"> Title  </label>
                         <Editor
                             editorState={headingtitle}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChangetitle}
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.headingtitle = val}
                      value={draftToHtml(convertToRaw(headingtitle.getCurrentContent())) }
                      />
                     </div>
                    <div className="form-group col-md-12 editor textalignblocksss4">
                    <label className="font-weight-bold"> Text  </label>
                         <Editor
                             editorState={heading}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChange}
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.heading = val}
                      value={draftToHtml(convertToRaw(heading.getCurrentContent())) }
                      />
                     </div>
                     
                    

                     </div>
                 
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
                     <p onClick={editcancel} className="Adminbuttonlink2" style={{marginRight:"17px"}} ><span> <FontAwesomeIcon icon={['fas','edit']} />Cancel </span></p>
                     <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                      
                   </div> 
                    }
               
              
             
           </form>
           </div>
 
 
 
           </div>


   </>
  );
}

export default AdminaboutpagestwoUpdate;