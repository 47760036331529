import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 

import {toast} from "react-toastify";


function AdminAddTeam(props) {
       
  let history = useHistory();
  const {Addteamsall} = useContext(MyContext);

   useEffect( () => {
     window.scrollTo(0, 0);
  }, []);

  const [loadingadd, setloadingadd] = useState(false);
const [userInfo, setuserInfo] = useState({
       name: '',
       file:'',
       subname:'',
       facebook:'',
       twitter:'',
       instagram:'',
         
});

const [fileempty, setfileempty] = useState(false);
const [filetemp, setfiletemp] = useState(null);
const [invalidImage, setinvalidImage] = useState(null);

const onChangeValue = (e) => {
  
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 


const Addteams = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                     if(invalidImage !== null){
                       toast.error(invalidImage, {
                         position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                       }); 
                       setloadingadd(false);
                       return;
                     }
                    
                     const dataadd = await Addteamsall(userInfo);
                     if(dataadd.success === 1){
                            console.log('Addteams',dataadd);
                            //console.log(userInfo.description.value);
                            setloadingadd(false);
                            event.target.reset(); 
                            history.push(`/AdminTeam`);
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}
// {props.match.params.Add}

const resetFile = (event) => {
       event.preventDefault();
       setfiletemp(null)
     }

     let reader = new FileReader();

const handleChange = (event) => {
const imageFile = event.target.files[0];
      const imageFilname = event.target.files[0].name;

      if (!imageFile) {
       setinvalidImage('Please select image.');
        return false;
      }
  
      if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
       setinvalidImage('Please select valid image JPG,JPEG,PNG');
        return false;
      }
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
         var canvas = document.createElement('canvas');
         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0);

         var MAX_WIDTH = 600;
         var MAX_HEIGHT = 600;
         var width = img.width;
         var height = img.height;

         if (width > height) {
           if (width > MAX_WIDTH) {
             height *= MAX_WIDTH / width;
             width = MAX_WIDTH;
           }
         } else {
           if (height > MAX_HEIGHT) {
             width *= MAX_HEIGHT / height;
             height = MAX_HEIGHT;
           }
         }
         canvas.width = width;
         canvas.height = height;
         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0, width, height);
         ctx.canvas.toBlob((blob) => {
           const file = new File([blob], imageFilname, {
               type: 'image/jpeg',
               lastModified: Date.now()
           });
           setuserInfo({
              ...userInfo,
              file:file,
         })
         }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
       setfiletemp( URL.createObjectURL(imageFile))
      
       setinvalidImage(null)
       };
        img.onerror = () => {
              setinvalidImage('Invalid image content.');
          return false;
        };
        //debugger
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);

}; 






  return (
   <>
     <div className="bookads2">
     <Link to={`/AdminTeam`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>                     
                      <div className="">
                             <div className="">
                                   
                                    <div className="col-md-12" style={{padding:0}}>
                                   
        
               <form onSubmit={Addteams} className="central-meta ">
             
               <div className="clearfix"></div>
               <div className="form-row">
              
 
 
 
                   <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Name <span className="adverts-form-required"> * </span> </label>
                       <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}  className="form-control" placeholder="Name" required />
                   </div>
                 
                   <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Image <span className="adverts-form-required"> * </span> </label>
                      <div className="clearfix"></div>
                      <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   required/>
                     
                  </div>
                  
                     {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }
                
                     <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Title  </label>
                       <input type="text" name="subname" value={userInfo.subname} onChange={onChangeValue}  className="form-control" placeholder="Title"  />
                   </div>
                   <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Facebook </label>
                       <input type="text" name="facebook" value={userInfo.facebook} onChange={onChangeValue}  className="form-control" placeholder="Facebook"  />
                   </div>
                   <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Twitter  </label>
                       <input type="text" name="twitter" value={userInfo.twitter} onChange={onChangeValue}  className="form-control" placeholder="Twitter"  />
                   </div>
                   <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Instagram  </label>
                       <input type="text" name="instagram" value={userInfo.instagram} onChange={onChangeValue}  className="form-control" placeholder="Instagram"  />
                   </div>
                  
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
               
                       <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                   </div> 
                    }
               
              
               </div>
               
           </form>
           </div>
 
 
   
           
           </div>
           </div>
 
 
 
 
 
           </div>





   </>
  );
}

export default AdminAddTeam;