import React,{useContext,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";

import { WEB_URL_UPLOAD,CAPTCHA_SITE_KEY } from '../contexts/Apilinks';

const TEST_SITE_KEY = CAPTCHA_SITE_KEY;
const DELAY = 1500;
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminPasswordUpdateEmail(props) {
   const {passwordchangeEmail} = useContext(MyContext);

   useEffect( () => {
     window.scrollTo(0, 0);
  }, []);
 
const [value, setvalue] = useState("[empty]");
const [load, setload] = useState(false);
const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);
const [loadinglogin, setloadinglogin] = useState(false);
const _reCaptchaRef = React.createRef()
const [userInfo, setuserInfo] = useState({
  name: '',
});
const onChangeValue = (e) => {
  setuserInfo({
      ...userInfo,
          [e.target.name]:e.target.value
  });
} 

useEffect( () => {
  setTimeout(() => {
    setload(true );
  }, DELAY);
  console.log("didMount - reCaptcha Ref-", _reCaptchaRef);
 }, []); 

 const handleChange = (value) => {
       setvalue(value);
      
     };
const asyncScriptOnLoad = () => {
  setrecaptchaLoaded(true);
  _reCaptchaRef.current.execute();
};

       const contacts = async (event) => {

              setloadinglogin(true);
              event.preventDefault();
              event.persist();
              const data = await passwordchangeEmail(userInfo,userInfo.recaptcha_response.value,props.match.params.Addpreview);
                     console.log(data.memberid)
                     if(data.success === 1){
                            setloadinglogin(false);
                            event.target.reset(); 
                            toast.success( 'Password Change Successfully.', {
                                   position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                   });  
                     
                     }
                     else{
                            setloadinglogin(false);
                            toast.error( data.msg, {
                            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                            });  
                     }

       }
     
  return (
   <>

{/* -------------------- Our Team --------------------------------------------------------------------------------------------------- */}


<div className="prayerreqsss">
<div className="container">
      <div className="row">
      <div className="col-md-1"> &nbsp; </div>
      <div className="col-md-10"> 
      <h5>  * indicates required </h5>
      <form onSubmit={contacts} className="login-forms">
             
             <div className="clearfix"></div>
         <div className="form-row">
         
             <div className="form-group col-md-12">
             <label className="font-weight-bolds "> New Password  <span className="adverts-form-required"> *  </span> </label>
                 <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}className="form-control" placeholder="" required   />
                
             </div>
          
               
            
             
          
        
             <input type="hidden" name="recaptcha_response" ref={(val) => userInfo.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

             {load && (
       <ReCAPTCHA
         style={{ display: "inline-block" }}
         theme="dark"
         size="invisible"
         ref={_reCaptchaRef}
         sitekey={TEST_SITE_KEY}
         onChange={handleChange}
         asyncScriptOnLoad={asyncScriptOnLoad}
       />
     )}

             <div className="form-group col-md-12 ">
             {loadinglogin ?
                         <>
                                <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                         </>
                      :  <>
                         <button type="submit" className="btn btn-style-two buttonlink" disabled={!recaptchaLoaded}> Send </button>
                   </>
             }
             </div>
            
         </div>
        
     </form>  
     </div>
</div>
</div>
</div>

  


   </>
  );
}

export default AdminPasswordUpdateEmail;