import React,{useContext,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';
import AdminContactDetailsupdate from './AdminContactDetailsupdate';


function AdminContactDetails() {
   const {addresslistall} = useContext(MyContext);

   useEffect( () => {
     address();
     window.scrollTo(0, 0);
  }, []);
 
 const [isaddress, setaddress] = useState([]);
 const [isphone, setphone] = useState([]);
 const [isemail, setemail] = useState([]);
 const [isfacebook, setfacebook] = useState([]);
 const [istwitter, settwitter] = useState([]);
 const [isinstagram, setinstagram] = useState([]);
 const [isyoutube, setyoutube] = useState([]);
 const [ismaps, setmaps] = useState([]);
 const [isids, setids] = useState([]);
 const [isgetintouch, setgetintouch] = useState([]);
  const address = async() =>{
    try {
           const addressresult = await addresslistall();
                  if(addressresult.success === 1){
                     setaddress(addressresult.addresslist[0].address);
                     setphone(addressresult.addresslist[0].phone);
                     setemail(addressresult.addresslist[0].email);
                     setfacebook(addressresult.addresslist[0].facebook);
                     settwitter(addressresult.addresslist[0].twitter);
                     setinstagram(addressresult.addresslist[0].instagram);
                     setyoutube(addressresult.addresslist[0].youtube);
                     setmaps(addressresult.addresslist[0].map);
                     setids(addressresult.addresslist[0].contactdetails_id);
                     setgetintouch(addressresult.addresslist[0].getintouch);
           }
    } catch (error) { throw error;}
  }
  const [isaboutpagesHCEdit, setaboutpagesHCEdit] = useState(false);
  const aboutpagesHCEdit = () =>{ setaboutpagesHCEdit(true); }
  const editcancel = async() =>{ setaboutpagesHCEdit(false);}
  return (
   <>

{/* -------------------- Our Team --------------------------------------------------------------------------------------------------- */}

<div className="admincontactforms">
{isaboutpagesHCEdit === false ? <>
<p onClick={aboutpagesHCEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit page </span></p>
<div className="contactforms">
<iframe src={ismaps} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
   <div className="container">
      <div className="row">
       <div className="col-md-12 contactforms2"> 
              <h1> GET IN <span> TOUCH </span> </h1> 
              <p className="dgt-sub-title"> {isgetintouch} </p>
       
       <div className="col-md-4"> 
       <div className="dgt-contact-info"> 
              <FontAwesomeIcon icon={['fas','map-marker-alt']} className="contacticonsss" />
              <h3> Address Info </h3>
              <p>{isaddress}</p>
       </div> 
       </div> 
       <div className="col-md-4"> 
       <div className="dgt-contact-info"> 
              <FontAwesomeIcon icon={['fas','mobile-alt']} className="contacticonsss" />
              <h3> Phone & Email </h3>
              <p>{isphone}</p>
              <p>{isemail}</p>
       </div>
       </div>
        <div className="col-md-4">
        <div className="dgt-contact-info"> 
              <FontAwesomeIcon icon={['fas','share-alt']} className="contacticonsss" />
              <h3> Social </h3>
              <ul>
                    {istwitter !== '' ? 
                      <li className="twitter"><a title="Twitter" href={istwitter} rel="noopener noreferrer" target="_blank"><span></span><FontAwesomeIcon icon={['fab','twitter']} className="ion-social-twitter"/></a></li>
                    : null}
                    {isfacebook !== '' ?
                      <li className="facebook"><a title="Facebook" href={isfacebook} rel="noopener noreferrer" target="_blank"><span></span><FontAwesomeIcon icon={['fab','facebook-f']} className="ion-social-facebook"/></a></li>
                     : null}
                    {isinstagram !== '' ? 
                      <li className="Instagram"><a title="Instagram" href={isinstagram} rel="noopener noreferrer" target="_blank"><span></span><FontAwesomeIcon icon={['fab','instagram']} className="ion-social-instagram"/></a></li>
                    : null}
                    {isyoutube !== '' ? 
                      <li className="youtube"><a title="Youtube" href={isyoutube} rel="noopener noreferrer" target="_blank"><span></span><FontAwesomeIcon icon={['fab','youtube']} className="ion-social-youtube-outline"/></a></li>
                    : null}
                    </ul>
       </div>
       </div>
       </div>
       </div>
   </div>
   
</div>
</>: <>
      
              <AdminContactDetailsupdate ids={isids} maps={ismaps} address={isaddress} phone={isphone} email={isemail} twitter={istwitter} facebook={isfacebook} instagram={isinstagram} youtube={isyoutube} getintouch={isgetintouch} cancel={editcancel} refresh={ ()=>address()}  />
     
       </>
 }




</div>



   </>
  );
}

export default AdminContactDetails;