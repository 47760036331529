import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from "react-router-dom";
import AdminserviestimeEdittwo from './AdminserviestimeEdittwo';
import {Link } from "react-router-dom"; 



function AdminserviestimeEdit(props) {
       
  let history = useHistory();

   const {servieslistpreviewall} = useContext(MyContext);

   useEffect( () => {
     servieslistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

 const [isbloglistpreview, setbloglistpreview] = useState([]);
 const servieslistpreview = async (ids) =>{
 try {
        const bloglistpreviewsss = await servieslistpreviewall(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('servieslistpreview',bloglistpreviewsss.servieslistpreviewqqq);
                      setbloglistpreview(bloglistpreviewsss.servieslistpreviewqqq);
        }
 } catch (error) { throw error;}
}



  return (
   <>
         {isbloglistpreview.map((item, index) => ( 
               <div  key={item.blog_id} className="col-md-12">
                    
                 <AdminserviestimeEdittwo serviesids={item.serviessubtime_id } timeone={item.timeone} timetwo={item.timetwo} timethree={item.timethree} days={item.days}  nameone={item.nameone} nametwo={item.nametwo} namethree={item.namethree}/>
                     
           </div>
          
            ))}
  
 
 


   </>
  );
}

export default AdminserviestimeEdit;