
 //export const WEB_URL_UPLOAD = "http://localhost/church/uploads/";
 //export const WEB_URL = "http://192.168.0.128:8080/";


 export const WEB_URL_UPLOAD = "https://npcentre.ca/uploads/";
 export const WEB_URL = "https://npcentre.ca/";

export const CAPTCHA_SITE_KEY = "6LffCwoaAAAAALwLIoy_Xw_3jLizC4MchOwWurXZ";
// react ---------------------------------------------------------------------

// npm install react-bootstrap bootstrap
// npm install --save react-router-dom
// npm install axios --save
// npm install --save react-toastify

//     npm i --save @fortawesome/fontawesome-svg-core  @fortawesome/free-solid-svg-icons @fortawesome/react-fontawesome

// npm i --save @fortawesome/free-brands-svg-icons
// npm install --save react-motion
// npm install react-content-loader --save
// npm install --save react-google-recaptcha
// npm install react-loader-spinner --save
// npm install --save react-draft-wysiwyg draft-js
// npm install html-to-draftjs --save
// npm install draftjs-to-html
// npm install react-select
// npm install --save react-stripe-checkout
// npm install react-date-picker
// npm install --save moment react-moment
// npm install @react-pdf/renderer
// npm install react-confirm-alert --save
// npm install reactjs-popup --save
// npm i bootstrap-switch-button-react --save
// npm i @brainhubeu/react-carousel
// npm i @react-pdf/renderer
// npm install --save react-router-hash-link
// npm install react-phone-input-2 --save
// npm install --save simple-react-lightbox
// npm install --save react-date-range
// npm install --save react date-fns


// Node -------------------------------------------------------

// npm install stripe --save
// npm install bcrypt
// npm install body-parser
// npm install cors
// npm install express
// npm install --save multer
// npm install --save mysql2
// npm install nodemailer
// npm install -g nodemon
// npm install paypal-rest-sdk
// npm install strtotime
// npm install uuidv4
// npm install react-scripts
// npm install sharp
