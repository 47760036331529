import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../../contexts/MyContext';

import {toast} from "react-toastify";
import {NavLink} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL_UPLOAD } from '../../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminNavbarmenu() {
       const {menuslistall} = useContext(MyContext);
       useEffect( () => {
         menus();
      }, []);
     
  const [islogo, setlogo] = useState([]);
  const menus = async() =>{
  try {
         const menusresult = await menuslistall();
                if(menusresult.success === 1){
                       console.log('adminlogo',menusresult.menulist);
                       setlogo(menusresult.menulist[0].logo);
         }
  } catch (error) { throw error;}
}
  const [isMenuadmin, setisMenuadmin] = useState(false);
  const toggleClass = () => {
    setisMenuadmin(isMenuadmin === false ? true : false);
};

      let boxClass = ["main-menuadmin menu-rightadmin menuq1admin"];
      if(isMenuadmin) {
        boxClass.push('menuq2admin');
      }else{
        boxClass.push('');
      }
      
   

      return(
<>
  <div className="app-header">
       <div className="adminlogo">
              <NavLink to="/">
                     <img src={BaseURLuploads+islogo} alt="logo" /> 
              </NavLink>
       </div>
       <div className="adminnavright">
       
       {/* <NavLink to={`/AdminGalleryMultiple`} className="Adminbuttonlink"><span> gallery </span></NavLink>  */}
       <NavLink to={`/AdminChangePassword`} className="Adminbuttonlink"><span> Change Admin Password </span></NavLink>
       <NavLink to={`/AdminChangeLogo`} className="Adminbuttonlink"><span> Change Logo </span></NavLink>
       <NavLink to={`/`} className="Adminbuttonlink"><span> Preview </span></NavLink>
       <NavLink to={`/Logout`} className="Adminbuttonlink"><span> Log out </span></NavLink>
       </div>
</div>

<div className="app-sidebar " >
<div className="simplebar-wrapper" >
<ul className={boxClass.join(' ')}>
<li onClick={toggleClass}><NavLink to={`/AdminHome`}> <FontAwesomeIcon icon={['fas','home']} className="ion-android-call"/> Home </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminAbout`}> <FontAwesomeIcon icon={['fas','file-alt']} className="ion-android-call"/> About </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminPasterMessage`}> <FontAwesomeIcon icon={['fab','accusoft']} className="ion-android-call"/> Pastor Message </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminVideo`}> <FontAwesomeIcon icon={['fab','youtube']} className="ion-android-call"/> Sermon </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminBlog`}> <FontAwesomeIcon icon={['fas','blog']} className="ion-android-call"/> Blog </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminLive`}> <FontAwesomeIcon icon={['fab','youtube']} className="ion-android-call"/> Post your live service Youtube link </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminEvents`}> <FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-android-call"/> Events </NavLink></li>
                     
                     <li onClick={toggleClass}><NavLink to={`/AdminTeam`}> <FontAwesomeIcon icon={['fas','users']} className="ion-android-call"/> Our Team </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminGallery`}> <FontAwesomeIcon icon={['fas','images']} className="ion-android-call"/> Gallery </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminServiceTime`}> <FontAwesomeIcon icon={['fas','clock']} className="ion-android-call"/> Service Time </NavLink></li>
                    
                     
                     <li onClick={toggleClass}><NavLink to={`/AdminVideogallery`}> <FontAwesomeIcon icon={['fab','youtube']} className="ion-android-call"/> Video </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminServicesall`}> <FontAwesomeIcon icon={['fas','users-cog']} className="ion-android-call"/> Our Services </NavLink></li>
                     <li onClick={toggleClass}><NavLink to={`/AdminContactDetails`}> <FontAwesomeIcon icon={['fas','address-book']} className="ion-android-call"/> Contact Details </NavLink></li>
                     
                      
              </ul>
</div>
</div>

</>
        );
    }

export default AdminNavbarmenu;

