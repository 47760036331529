import React from 'react';
import MyContextProvider from './contexts/MyContext';
import Apppremission from './Apppremission';

function App() {
  return (
    <MyContextProvider>
        <Apppremission />
    </MyContextProvider>
  );
}

export default App;