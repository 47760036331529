import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminBlog() {
       const {rootState,allblogslist} = useContext(MyContext);
       const {isAuth,theUser} = rootState;

   useEffect( () => {
     window.scrollTo(0, 0);
  }, []);
 
  const [pageNumber, setPageNumber] = useState(1);
 const observer = useRef()
 const loadingRef = useCallback(node => {
   
   if (observer.current) observer.current.disconnect()
   observer.current = new IntersectionObserver(entries => {
     if (entries[0].isIntersecting ) {
       setPageNumber(prevPageNumber => prevPageNumber + 1)
       //latestfeeds(prevPageNumber => prevPageNumber + 1)
     }
   })
   if (node) observer.current.observe(node)
 }, [])

 useEffect( () => {
   if (isFetching === true) return;
   allblogs(pageNumber);
       return () => {
         console.log("This will be logged on unmount");
       }
 }, [pageNumber])

 const [isallblogs, setallblogs] = useState([]);
 const [isFetching, setIsFetching] = useState(false);

 const allblogs = async(pageNumber) => {
   const allblogsss = await allblogslist(pageNumber);
   if(allblogsss.success === 1){
          console.log('allblogs',allblogsss);
          setallblogs(prevBooks => {
                 return [...prevBooks, ...allblogsss.data]
          })
       
          if(allblogsss.data.length < 1){
                 setIsFetching(true);
          }
   }
}


  return (
   <>

<div className="Adminblogs">
<Link to={`/AdminAddBlog`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Create Blog </span></Link>
         <div className="col-md-12">
         {isallblogs.map((item, index) => ( 
               <div ref={loadingRef} key={item.blog_id } className="col-md-12">
                     <div className="Adminblogs3">
                     <div className="Adminblogs2 col-md-2">
                     <img src={BaseURLuploads+item.image} alt="" />
                     </div>
                     <div className="col-md-6">
                            <h3> {item.title} </h3>
                            <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                                 {item.date} 
                                                 </Moment> </p>
                          
                     </div>
                   <div className="col-md-4">
                          
                            <Link to={`/AdminBlogPreview/${item.blog_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Preview </span></Link>
                            <Link to={`/AdminBlogEdit/${item.blog_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></Link>
                            <Link to={`/AdminBlogDelete/${item.blog_id}`} className="Adminbuttonlink2" ><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></Link>
                     </div>
                   
             
                   
                 
               
               </div>
               </div>
            ))}
  </div>
  
</div>

  

   </>
  );
}

export default AdminBlog;