import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from "react-router-dom";
import AdminVidoEdittwogallery from './AdminVidoEdittwogallery';
import {Link } from "react-router-dom"; 



function AdminVidoEditgallery(props) {
       
  let history = useHistory();

   const {VideoPreviewallgallery} = useContext(MyContext);

   useEffect( () => {
       videolistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

  const [isvideolistpreview, setvideolistpreview] = useState([]);
  const videolistpreview = async (ids) =>{
  try {
         const videolistpreviewsss = await VideoPreviewallgallery(ids);
                if(videolistpreviewsss.success === 1){
                       console.log('VideoPreviewallgallery',videolistpreviewsss.videolistpreviewlist);
                       setvideolistpreview(videolistpreviewsss.videolistpreviewlist);
         }
  } catch (error) { throw error;}
 }
 const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;

  return (
   <>
         {isvideolistpreview.map((item, index) => ( 
               <div  key={item.videos_id} className="col-md-12">
                    
                 <AdminVidoEdittwogallery videosids={item.videos_id} title={item.title} description={item.describtion} video={item.video}  />
                     
           </div>
          
            ))}
  




   </>
  );
}

export default AdminVidoEditgallery;