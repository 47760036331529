import React,{useContext,useState,useEffect } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Gallery() {
   const {Breadcrumbslistall,Gallerysssall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     Gallerysss()
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_gallery);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 
 const [isallgallery, setallgallery] = useState([]);

 const Gallerysss = async() => {
   const Gallerysssqqq = await Gallerysssall();
   if(Gallerysssqqq.success === 1){
          console.log('Gallerysssqqq',Gallerysssqqq.Galleryssslist);
          setallgallery(Gallerysssqqq.Galleryssslist);
       
   }
}

  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- --------------------------------------------------------------------------------------------------- */}


<div className="gallerysss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isallgallery.map((item, index) => ( 
               <div  key={item.gallery_category_id } className="col-md-4">
                  <Link to={`/GalleryPreview/${item.gallery_category_id}`} className="">
                   <h3> {item.gallery_category_name} </h3>
                  <div className="gallerysss2">
                  <h3> {item.gallery_category_name} </h3>
                     <img src={BaseURLuploads+item.gallery_category_image} alt="" />
                  </div>
                  </Link>
               </div>
            ))}
  </div>
  </div>
   </div>
</div>


   </>
  );
}

export default Gallery;