import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from "react-router-dom";
import AdminBlogEdittwo from './AdminBlogEdittwo';
import {Link } from "react-router-dom"; 



function AdminBlogEdit(props) {
       
  let history = useHistory();

   const {bloglistpreviewall} = useContext(MyContext);

   useEffect( () => {
     bloglistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

 const [isbloglistpreview, setbloglistpreview] = useState([]);
 const bloglistpreview = async (ids) =>{
 try {
        const bloglistpreviewsss = await bloglistpreviewall(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('bloglistpreview',bloglistpreviewsss.bloglistpreviewlist);
                      setbloglistpreview(bloglistpreviewsss.bloglistpreviewlist);
        }
 } catch (error) { throw error;}
}



  return (
   <>
         {isbloglistpreview.map((item, index) => ( 
               <div  key={item.blog_id} className="col-md-12">
                    
                 <AdminBlogEdittwo blogids={item.blog_id} title={item.title} description={item.describtion} />
                     
           </div>
          
            ))}
  




   </>
  );
}

export default AdminBlogEdit;