import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert'; 
import AdminLiveEdit from './AdminLiveEdit';

function AdminLive() {
   const {VideoLiveall,deletelivelist} = useContext(MyContext);

   useEffect( () => {
    
    videolistlive();
   
     window.scrollTo(0, 0);
  }, []);
 
 const [isvideolistpreview, setvideolistpreview] = useState([]);
 const videolistlive = async (ids) =>{
 try {
        const videolistpreviewsss = await VideoLiveall(ids);
               if(videolistpreviewsss.success === 1){
                      console.log('videolistpreview',videolistpreviewsss.videolistlivelist);
                      setvideolistpreview(videolistpreviewsss.videolistlivelist);
        }
 } catch (error) { throw error;}
}

const deletes = (blogids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deleteblogs(blogids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deleteblogs = async (blogids) => {
           
       try {
              const deleteblogssss = await deletelivelist(blogids);
              if(deleteblogssss.success === 1){
                     setvideolistpreview([]);
                     videolistlive();
     
              }
       } catch (error) { throw error;}      
     
     
     }

const [isourliveidsedit, setourliveedit] = useState(false);
     const editaminsteamlive = async() =>{
       setourliveedit(true);
    }
    const editcancel = async() =>{
       window.scrollTo(0, 0);
       setourliveedit(false);
    }

const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;


  return (
   <>

{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="Adminvideoslives">
{isvideolistpreview.length > 0 ? 
      null
:  <Link to={`/AdminAddVideolive`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Add Live Youtube Video Link </span></Link> }
<div className="videospreviews videos">

   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isvideolistpreview.map((item, index) => ( 
         <div key={item.videolive_id} className="col-md-12 sectioneighttwosss ">
              {isourliveidsedit === true ? 
                     <AdminLiveEdit liveids={item.videolive_id} title={item.title} description={item.describtion} video={item.video}  cancel={editcancel} refresh={ ()=>videolistlive()} />
              : null }

  
   {isourliveidsedit === false ? <> 
       <p onClick={editaminsteamlive} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></p> 
 <p onClick={()=>deletes(item.videolive_id)} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete  </span></p>
         <div className="col-md-12">
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
        
         </div>
         <div className="col-md-12">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
           
            </div>
            </> : null }
         </div>  
      ))}
  </div>
  </div>
   </div>
  
</div>
</div>






   </>
  );
}

export default AdminLive;