import React,{useState,useEffect,useContext} from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import {NavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
import { MyContext } from '../contexts/MyContext';
import Navbarmenu from './menu/Navbarmenu';
import Home from './Home';
import About from './About';
import OurTeam from './OurTeam';
import PrayerRequest from './PrayerRequest';
import ContactDetails from './ContactDetails';
import Events from './Events';
import Eventsrec from './Eventsrec';
import EventsPreview from './EventsPreview';
import Blog from './Blog';
import BlogPreview from './BlogPreview';
import Video from './Video';
import VideoPreview from './VideoPreview';
import Gallery from './Gallery';
import GalleryPreview from './GalleryPreview';
import Search from './Search';
import Blogsearchall from './Blogsearchall';
import Eventsearchall from './Eventsearchall';
import Videosearchall from './Videosearchall';

import AdminLogin from './AdminLogin';

import Facilities from './Facilities';
import Worship from './Worship';
import SundaySchool from './SundaySchool';
import ChurchChoir from './ChurchChoir';
import WomensForum from './WomensForum';
import SocialWelfare from './SocialWelfare';

import Videolives from './Videolives';
import Videogallery from './Videogallery';
import VideoGalleryPreview from './VideoGalleryPreview';
import EventsOldest from './EventsOldest';
import SocialsPreview from './SocialsPreview';
import FacilitiesPreview from './FacilitiesPreview';
import ChurchPreview from './ChurchPreview';
import SundaysPreview from './SundaysPreview';
import WomensPreview from './WomensPreview';
import PastorMessage from './PastorMessage';

import {SRLWrapper } from 'simple-react-lightbox';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab)

let BaseURLuploads = WEB_URL_UPLOAD;

const options = {
  settings: {
    overlayColor: "#7b2e00",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "black",
    iconColor: "rgba(126, 172, 139, 0.8)",
  },
  caption: {
    captionColor: "#a6cfa5",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  }
};

function Dashboard() {
  const {menuslistall,Galleryfooterall} = useContext(MyContext);

  useEffect( () => {
    window.addEventListener('scroll', toggleVisibility);
    menus();
    Galleryfooter();
    
  }, []);
  const [islogo, setlogo] = useState([]);
  const [ismenulist, setmenulist] = useState([]);
  const [istopheaderbgimg, settopheaderbgimg] = useState([]);
  const [isserviceslist, setserviceslist] = useState([]);
  const menus = async() =>{
    try {
           const menusresult = await menuslistall();
                  if(menusresult.success === 1){
                         console.log(menusresult.menulist);
                         setmenulist(menusresult.menulist);
                         setlogo(menusresult.menulist[0].logo);
                         settopheaderbgimg(menusresult.menulist[0].topheade_bgimg);
                         setserviceslist(menusresult.serviceslist);
           }
    } catch (error) { throw error;}
  }
 
  
  const [isGalleryfooter, setGalleryfooter] = useState([]);
  const Galleryfooter = async() =>{
    try {
           const Galleryfootersss = await Galleryfooterall();
                  if(Galleryfootersss.success === 1){
                         console.log('Galleryfooter',Galleryfootersss.Galleryfooterlist);
                         setGalleryfooter(Galleryfootersss.Galleryfooterlist);
           }
    } catch (error) { throw error;}
  }
  const scrollToTop =()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
const [is_visible, set_visible] = useState(false);

const toggleVisibility =()=> {
  if (window.pageYOffset > 700) {
    set_visible(true);
  
  } else {
    set_visible(false);
  }
}


  return (
    <BrowserRouter basename="/">
      <ToastContainer />
   
      <div className="App" id="App">
          <Navbarmenu />
          
          <div className="clearfix"></div>
       

        <Switch>
         <Route exact path="/" component={Home}/>
         <Route exact path="/About" component={About}/>
         <Route exact path="/OurTeam" component={OurTeam}/>
         <Route exact path="/PrayerRequest" component={PrayerRequest}/>
         <Route exact path="/ContactDetails" component={ContactDetails}/>
         <Route exact path="/Events" component={Events}/>
         <Route exact path="/Eventsrec" component={Eventsrec}/>
         <Route exact path="/EventsPreview/:Addpreview" component={EventsPreview}/>
         <Route exact path="/Blog" component={Blog}/>
         <Route exact path="/BlogPreview/:Addpreview/:BlogTitle" component={BlogPreview}/>
         <Route exact path="/Video" component={Video}/>
         <Route exact path="/VideoPreview/:Addpreview" component={VideoPreview}/>
         <Route exact path="/Gallery" component={Gallery}/>
         <Route exact path="/GalleryPreview/:Addpreview" component={GalleryPreview}/>
         <Route exact path="/Search" component={Search}/>
         <Route exact path="/Blogsearchall/:Addpreview" component={Blogsearchall}/>
         <Route exact path="/Eventsearchall/:Addpreview" component={Eventsearchall}/>
         <Route exact path="/Videosearchall/:Addpreview" component={Videosearchall}/>

         <Route exact path="/AdminLogin" component={AdminLogin}/>
         <Route exact path="/EventsOldest" component={EventsOldest}/>

         <Route exact path="/Facilities" component={Facilities}/>
         <Route exact path="/Worship" component={Worship}/>
         <Route exact path="/SundaySchool" component={SundaySchool}/>
         <Route exact path="/ChurchChoir" component={ChurchChoir}/>
         <Route exact path="/WomensForum" component={WomensForum}/>
         <Route exact path="/SocialWelfare" component={SocialWelfare}/>
         <Route exact path="/PastorMessage" component={PastorMessage}/>

         <Route exact path="/Videolives" component={Videolives}/>
         <Route exact path="/Videogallery" component={Videogallery}/>
         <Route exact path="/VideoGalleryPreview/:Addpreview" component={VideoGalleryPreview}/>

         <Route exact path="/SocialsPreview/:Addpreview" component={SocialsPreview}/>
         <Route exact path="/FacilitiesPreview/:Addpreview" component={FacilitiesPreview}/>
         <Route exact path="/ChurchPreview/:Addpreview" component={ChurchPreview}/>
         <Route exact path="/SundaysPreview/:Addpreview" component={SundaysPreview}/>
         <Route exact path="/WomensPreview/:Addpreview" component={WomensPreview}/>
      
       
         
        </Switch>
          <div className="clearfix"></div>









          
         

          <div className="clearfix"></div>
         
          <div className="footersss2" style={{backgroundImage: `url(${BaseURLuploads+istopheaderbgimg})`}}>
          <div className="container">
          <div className="row">
          <div className="col-md-6 ">
            <p className="pull-left"><Link to="/" > NpCentre.ca </Link> © All Rights Reserved - 2021 </p>
          </div>
          <div className="col-md-6 ">
            <p> Site by <a title="Crystalline Noble" href="https://www.crystallinenoble.com/" rel="noopener noreferrer" target="_blank"> Crystalline Noble </a></p>
          </div>
          {is_visible && ( <>
           
              <span onClick={scrollToTop} className="scrolls"> <span className="ltx-hex-inner2">Back to top </span><FontAwesomeIcon icon={['fas','angle-up']} className="gold" /></span><span className="ltx-hex"><span className="ltx-hex-inner"></span></span>	
              </>
              )}
          </div>
          </div>
          </div>
            
       
        </div>


      
    </BrowserRouter>
  );
}

export default Dashboard;
