
import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
  

    state = {
        isAuth:false,
        theUser:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
    loginUser = async (user,recaptcha_response) => {
        const login = await Axios.post('login',{
            email:user.email,
            password:user.password,
            recaptcha_response:recaptcha_response,
        });
        return login.data;
    }
    isLoggedIn = async () => {
        let userstorageid = JSON.parse(localStorage.getItem('loginTokenNPC'));
        if (localStorage.getItem('loginTokenNPC')) {
            this.setState({
                ...this.state,
                isAuth:true,
                theUser:userstorageid
            });
            //console.log(this.state.isAuth);
        }
    }
    logoutUser = () => {
        localStorage.removeItem('loginTokenNPC');
        this.setState({
            ...this.state,
            isAuth:false
        })
        window.location.href = '/';
    }
        menuslistall = async () => {
            const menuslistallqqq = await Axios.get('menuslistallsss');
            return menuslistallqqq.data;
        }
        addresslistall = async () => {
            const addresslistallqqq = await Axios.get('addresslistallsss');
            return addresslistallqqq.data;
        }
        bannerimgall = async () => {
            const bannerimgallqqq = await Axios.get('bannerimgallsss');
            return bannerimgallqqq.data;
        }
        sectiononeall = async () => {
            const sectiononeallqqq = await Axios.get('sectiononeallsss');
            return sectiononeallqqq.data;
        }
        servicesall = async () => {
            const servicesallqqq = await Axios.get('servicesallsss');
            return servicesallqqq.data;
        }
        Sermonsall = async () => {
            const Sermonsallqqq = await Axios.get('Sermonsallsss');
            return Sermonsallqqq.data;
        }
        homebgall = async () => {
            const homebgallqqq = await Axios.get('homebgallsss');
            return homebgallqqq.data;
        }
        eventsall = async () => {
            const eventsallqqq = await Axios.get('eventsallsss');
            return eventsallqqq.data;
        }
        eventsallOldest = async () => {
            const eventsallqqqOldest = await Axios.get('eventsallsssOldest');
            return eventsallqqqOldest.data;
        }
        eventsallrec = async () => {
            const eventsallqqqrec = await Axios.get('eventsallsssrec');
            return eventsallqqqrec.data;
        }
        aboutsall = async () => {
            const aboutsallqqq = await Axios.get('aboutsallsss');
            return aboutsallqqq.data;
        }
        blogsall = async () => {
            const blogsallqqq = await Axios.get('blogsallsss');
            return blogsallqqq.data;
        }
        Breadcrumbslistall = async () => {
            const Breadcrumbslistallqqq = await Axios.get('Breadcrumbslistallsss');
            return Breadcrumbslistallqqq.data;
        }
        ourteamsall = async () => {
            const ourteamsallqqq = await Axios.get('ourteamsallsss');
            return ourteamsallqqq.data;
        }
        PrayerRequestadd = async (user,recaptcha_response,phonecountry) => {
            const PrayerRequestaddqqq = await Axios.post('PrayerRequestaddsss',{
                name:user.name,
                email:user.email,
                subject:user.subject,
                request:user.request,
                recaptcha_response:recaptcha_response,
                phonecountry:phonecountry,
            });
            return PrayerRequestaddqqq.data;
        }
        contactsadd = async (user,recaptcha_response,phonecountry) => {
            const contactsaddqqq = await Axios.post('contactsaddsss',{
                name:user.name,
                email:user.email,
                message:user.message,
                recaptcha_response:recaptcha_response,
                phonecountry:phonecountry,
            });
            return contactsaddqqq.data;
        }
        alleventslist  = async (pageNumber) => {
            const alleventslistqqq = await Axios.get(`alleventslistsss/${pageNumber}`);
            return alleventslistqqq.data;
        }
        alleventslistoldest  = async (pageNumber) => {
            const alleventslistqqqoldest = await Axios.get(`alleventsoldest/${pageNumber}`);
            return alleventslistqqqoldest.data;
        }
        alleventslistadmin  = async (pageNumber) => {
            const alleventslistqqqadmin = await Axios.get(`alleventslistsssadmin/${pageNumber}`);
            return alleventslistqqqadmin.data;
        }
        alleventslistrec  = async (pageNumber) => {
            const alleventslistqqqrec = await Axios.get(`alleventslistsssrec/${pageNumber}`);
            return alleventslistqqqrec.data;
        }
        eventslistpreviewall = async (ids) => {
            const eventslistpreviewallqqq = await Axios.post('eventslistpreviewallsss',{
                ids:ids,
            });
            return eventslistpreviewallqqq.data;
        } 
        allblogslist  = async (pageNumber) => {
            const allblogslistqqq = await Axios.get(`allblogslistsss/${pageNumber}`);
            return allblogslistqqq.data;
        }
        bloglistpreviewall = async (ids) => {
            const bloglistpreviewallqqq = await Axios.post('bloglistpreviewallsss',{
                ids:ids,
            });
            return bloglistpreviewallqqq.data;
        }
        allVideolist  = async (pageNumber) => {
            const allVideolistqqq = await Axios.get(`allVideolistsss/${pageNumber}`);
            return allVideolistqqq.data;
        }
        VideoPreviewall = async (ids) => {
            const VideoPreviewqqq = await Axios.post('VideoPreviewsss',{
                ids:ids,
            });
            return VideoPreviewqqq.data;
        }
        Gallerysssall = async () => {
            const Gallerysssallqqq = await Axios.get('Gallerysssallsss');
            return Gallerysssallqqq.data;
        }
        GalleryPreviewlistall = async (ids) => {
            const GalleryPreviewlistallqqq = await Axios.post('GalleryPreviewlistallsss',{
                ids:ids,
            });
            return GalleryPreviewlistallqqq.data;
        }
        ApiBlogAdslist = async (searchvalue) => {
            const ApiBlogAdslistqqq = await Axios.get(`ApiBlogAdslistwww/${searchvalue}`);
            return ApiBlogAdslistqqq.data;
        }
        ApiEventsAdslist = async (searchvalue) => {
            const ApiEventsAdslistqqq = await Axios.get(`ApiEventsAdslistwww/${searchvalue}`);
            return ApiEventsAdslistqqq.data;
        }
        ApiVideoAdslist = async (searchvalue) => {
            const ApiVideoAdslistqqq = await Axios.get(`ApiVideoAdslistwww/${searchvalue}`);
            return ApiVideoAdslistqqq.data;
        }
        Apipageslist = async (searchvalue) => {
            const Apipageslistqqq = await Axios.get(`Apipageslistwww/${searchvalue}`);
            return Apipageslistqqq.data;
        }
        Blogsearchalllistall = async (searchvalue) => {
            const Blogsearchalllistallqqq = await Axios.get(`Blogsearchallsss/${searchvalue}`);
            return Blogsearchalllistallqqq.data;
        }
        eventssearchall = async (searchvalue) => {
            const eventssearchallqqq = await Axios.get(`eventssearchallwww/${searchvalue}`);
            return eventssearchallqqq.data;
        }
        vodeosearchall = async (searchvalue) => {
            const vodeosearchall = await Axios.get(`vodeosearchallwww/${searchvalue}`);
            return vodeosearchall.data;
        }
        Galleryfooterall = async () => {
            const Galleryfooterallqqq = await Axios.get('Galleryfooterallsss');
            return Galleryfooterallqqq.data;
        }
        newblogsadd = async (book,desc) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            const booksaddsss = await Axios.post('blogaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return booksaddsss.data;
        }
        blogupdatesall = async (book,desc,fileempty,blogsids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('fileempty',fileempty);
            formData.append('blogsids',blogsids);
            const blogupdatesallsss = await Axios.post('blogupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return blogupdatesallsss.data;
        }
        deleteblogslist = async (ids) => {
            const deleteblogsss = await Axios.post('deleteblogsqqq',{
                ids:ids,
            });
            return deleteblogsss.data;
        }
        EventsAddall = async (book,desc,sdate,ldate) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('location',book.location);
            formData.append('time',book.time);
            formData.append('sdate',sdate);
            formData.append('ldate',ldate);
            const EventsAddallsss = await Axios.post('EventsAddallwww',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return EventsAddallsss.data;
        } 
        EventsAddallOrganizer = async (book,ids,fileempty) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('avatar',book.file);
            formData.append('ids',ids);
            formData.append('fileempty',fileempty);
            const EventsAddallOrganizerqqq = await Axios.post('EventsAddallOrganizersss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return EventsAddallOrganizerqqq.data;
        }
        Eventsupdatesall = async (book,desc,fileempty,sdate,ldate,eventsids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('fileempty',fileempty);
            formData.append('location',book.location);
            formData.append('time',book.time);
            formData.append('sdate',sdate);
            formData.append('ldate',ldate);
            formData.append('eventsids',eventsids);
            const Eventsupdatesallsss = await Axios.post('Eventsupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return Eventsupdatesallsss.data;
        }
        deleteEventslist = async (ids) => {
            const deleteEventslistsss = await Axios.post('deleteEventslistqqq',{
                ids:ids,
            });
            return deleteEventslistsss.data;
        }
        videoAddsss = async (book,desc,) => {
            const videoAddsssqqq = await Axios.post('videoAddssswww',{
                title:book.title,
                video:book.video,
                desc:desc,
            });
            return videoAddsssqqq.data;
        }
        Videoupdatesall = async (book,desc,ids) => {
            const Videoupdatesallqqq = await Axios.post('Videoupdatesallsss',{
                title:book.title,
                video:book.video,
                desc:desc,
                ids:ids,
            });
            return Videoupdatesallqqq.data;
        }
        deletevideolist = async (ids) => {
            const deletevideolistsss = await Axios.post('deletevideolistqqq',{
                ids:ids,
            });
            return deletevideolistsss.data;
        }
        Facilitiessssall = async () => {
            const Facilitiessssallqqq = await Axios.get('Facilitiessssallsss');
            return Facilitiessssallqqq.data;
        }
        VideoLiveall = async () => {
            const VideoLiveallqqq = await Axios.get('VideoLiveallsss');
            return VideoLiveallqqq.data;
        }
        Worshipsssall = async () => {
            const Worshipsssallqqq = await Axios.get('Worshipsssallsss');
            return Worshipsssallqqq.data;
        }
        SundaySchoolsssall = async () => {
            const SundaySchoolsssallqqq = await Axios.get('SundaySchoolsssallsss');
            return SundaySchoolsssallqqq.data;
        }
        ChurchChoircsssall = async () => {
            const ChurchChoircsssall = await Axios.get('ChurchChoircsssallsss');
            return ChurchChoircsssall.data;
        }
        WomensForumsssall = async () => {
            const WomensForumsssallqqq = await Axios.get('WomensForumsssallsss');
            return WomensForumsssallqqq.data;
        }
        SocialWelfaresssall = async () => {
            const SocialWelfaresssallqqq = await Axios.get('SocialWelfaresssallsss');
            return SocialWelfaresssallqqq.data;
        }
        Addteamsall = async (book) => {
            const formData = new FormData();
            formData.append('name',book.name);
            formData.append('avatar',book.file);
            formData.append('subname',book.subname);
            formData.append('facebook',book.facebook);
            formData.append('twitter',book.twitter);
            formData.append('instagram',book.instagram);
            const Addteamsallqqq = await Axios.post('Addteamsallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return Addteamsallqqq.data;
        }
        teamupdatesall = async (book,fileempty,teamids) => {
            const formData = new FormData();
            formData.append('name',book.name);
            formData.append('avatar',book.file);
            formData.append('subname',book.subname);
            formData.append('facebook',book.facebook);
            formData.append('twitter',book.twitter);
            formData.append('instagram',book.instagram);
            formData.append('fileempty',fileempty);
            formData.append('teamids',teamids);
            const teamupdatesallsss = await Axios.post('teamupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return teamupdatesallsss.data;
        }
        deleteTeamlist = async (ids) => {
            const deleteTeamlistqqq = await Axios.post('deleteTeamlistsss',{
                ids:ids,
            });
            return deleteTeamlistqqq.data;
        }
        galleryAddcategoryads = async (book) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('avatar',book.file);
            const  galleryAddcategoryadsqqq = await Axios.post('galleryAddcategoryadsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return  galleryAddcategoryadsqqq.data;
        }
        gallcatsupdatesall = async (book,fileempty,galcatsids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('avatar',book.file);
            formData.append('fileempty',fileempty);
            formData.append('galcatsids',galcatsids);
            const gallcatsupdatesallqqq = await Axios.post('gallcatsupdatesallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return gallcatsupdatesallqqq.data;
        }
        deletegallerycategorylist = async (ids) => {
            const deletegallerycategorylistqqq = await Axios.post('deletegallerycategorylistsss',{
                ids:ids,
            });
            return deletegallerycategorylistqqq.data;
        }
        galcatlistpreviewall = async (ids) => {
            const galcatlistpreviewallqqq = await Axios.post('galcatlistpreviewallsss',{
                ids:ids,
            });
            return galcatlistpreviewallqqq.data;
        }
        galleryAddall = async (book) => {
            const formData = new FormData();
            formData.append('category',book.category);
            formData.append('avatar',book.file);
            const  galleryAddallqqq = await Axios.post('galleryAddallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return  galleryAddallqqq.data;
        }
        deletegallerylist = async (ids) => {
            const deletegallerylistqqq = await Axios.post('deletegallerylistsss',{
                ids:ids,
            });
            return deletegallerylistqqq.data;
        }
        servieslistpreviewall = async (ids) => {
            const servieslistpreviewallqqq = await Axios.post('servieslistpreviewallsss',{
                ids:ids,
            });
            return servieslistpreviewallqqq.data;
        }
        servicestimeupdatesall = async (book,servicesids) => {
            const servicestimeupdatesallqqq = await Axios.post('servicestimeupdatesallsss',{
                nameone:book.nameone,
                nametwo:book.nametwo,
                namethree:book.namethree,
                timeone:book.timeone,
                timetwo:book.timetwo,
                timethree:book.timethree,
                servicesids:servicesids,
            });
            return servicestimeupdatesallqqq.data;
        }
        videoAddssslives = async (book,desc) => {
            const videoAddssslivesqqq = await Axios.post('videoAddssslivesss',{
                title:book.title,
                video:book.video,
                desc:desc,
            });
            return videoAddssslivesqqq.data;
        }
        deletelivelist = async () => {
            const deletelivelistsss = await Axios.post('deletelivelistqqq');
            return deletelivelistsss.data;
        }
        videoliveeditslist = async (book,desc,ids) => {
            const videoliveeditslistqqq = await Axios.post('videoliveeditslistsss',{
                title:book.title,
                video:book.video,
                desc:desc,
                ids:ids,
            });
            return videoliveeditslistqqq.data;
        }
        bannerimgalleditlist = async (ids) => {
            const bannerimgallqqqwww = await Axios.post('bannerimgalleditlistqqq',{
                ids:ids,
            });
            return bannerimgallqqqwww.data;
        }
        sliderupdatesall = async (book,fileempty,sliderids) => {
            const formData = new FormData();
            formData.append('bannertext1',book.bannertext1);
            formData.append('bannertext2',book.bannertext2);
            formData.append('bannertext3',book.bannertext3);
            formData.append('avatar',book.file);
            formData.append('fileempty',fileempty);
            formData.append('sliderids',sliderids);
            const sliderupdatesallqqq = await Axios.post('sliderupdatesallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return sliderupdatesallqqq.data;
        }
        SectiononeDisableall = async (ids) => {
            const SectiononeDisableallqqq = await Axios.post('SectiononeDisableallsss',{
                ids:ids,
            });
            return SectiononeDisableallqqq.data;
        }
        seconeupdatesall = async (book,heading,text1,text2,text3,fileempty,ids) => {
            const formData = new FormData();
            formData.append('avatar',book.file);
            formData.append('buttons',book.buttons);
            formData.append('buttonlink',book.buttonlink);
            formData.append('heading',heading);
            formData.append('text1',text1);
            formData.append('text2',text2);
            formData.append('text3',text3);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            const seconeupdatesallsss = await Axios.post('seconeupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return seconeupdatesallsss.data;
        }
        SectioneightDisableall = async (ids,datas) => {
            const SectioneightDisableallsss = await Axios.post('SectioneightDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectioneightDisableallsss.data;
        }
        SectiontwoDisableall = async (ids,datas) => {
            const SectiontwoDisableallsss = await Axios.post('SectiontwoDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectiontwoDisableallsss.data;
        }
        
        SectionVisionDisableall = async (ids,datas) => {
            const SectionVisionDisableallsss = await Axios.post('SectionVisionDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionVisionDisableallsss.data;
        }
        SectionMissionDisableall = async (ids,datas) => {
            const SectionMissionDisableallsss = await Axios.post('SectionMissionDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionMissionDisableallsss.data;
        }
        secfourupdatesall = async (book,heading,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('avatar',book.file);
            formData.append('buttons',book.buttons);
            formData.append('buttonlink',book.buttonlink);
            formData.append('heading',heading);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            const secfourupdatesallsss = await Axios.post('secfourupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return secfourupdatesallsss.data;
        }
        SectionfourDisableall = async (ids,datas) => {
            const SectionfourDisableallsss = await Axios.post('SectionfourDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionfourDisableallsss.data;
        }
        secnineupdatesall = async (book,heading,fileempty,ids,title) => {
            const formData = new FormData();
            formData.append('avatar',book.file);
            formData.append('heading',heading);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            formData.append('title',title);
            const secnineupdatesallsss = await Axios.post('secnineupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return secnineupdatesallsss.data;
        }
        SectionnineDisableall = async (ids,datas) => {
            const SectionnineDisableallsss = await Axios.post('SectionnineDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionnineDisableallsss.data;
        }
        SectionsevenDisableall = async (ids,datas) => {
            const SectionsevenDisableallsss = await Axios.post('SectionsevenDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionsevenDisableallsss.data;
        }
        allvideogallerylist  = async (pageNumber) => {
            const allvideogallerylistsss = await Axios.get(`allvideogallerylistqqq/${pageNumber}`);
            return allvideogallerylistsss.data;
        }
        VideogalleryPreviewall = async (ids) => {
            const VideogalleryPreviewallqqq = await Axios.post('VideogalleryPreviewallsss',{
                ids:ids,
            });
            return VideogalleryPreviewallqqq.data;
        }
        aboutpageupdatesall = async (desc,ids,title) => {
            const aboutpageupdatesallqqq = await Axios.post('aboutpageupdatesallsss',{
                desc:desc,
                ids:ids,
                title:title,
            });
            return aboutpageupdatesallqqq.data;
        }
        SectionwwrcDisableall = async (ids,datas) => {
            const SectionwwrcDisableallsss = await Axios.post('SectionwwrcDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionwwrcDisableallsss.data;
        }
        slidercreateall = async (book) => {
            const formData = new FormData();
            formData.append('bannertext1',book.bannertext1);
            formData.append('bannertext2',book.bannertext2);
            formData.append('bannertext3',book.bannertext3);
            formData.append('avatar',book.file);
            const slidercreateallsss = await Axios.post('slidercreateallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return slidercreateallsss.data;
        }
        SectionsofcDisableall = async (ids,datas) => {
            const SectionsofcDisableallsss = await Axios.post('SectionsofcDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionsofcDisableallsss.data;
        }
        aboutpagetwoupdatesall = async (desc,ids,title) => {
            const aboutpagetwoupdatesallqqq = await Axios.post('aboutpagetwoupdatesallsss',{
                desc:desc,
                ids:ids,
                title:title,
            });
            return aboutpagetwoupdatesallqqq.data;
        }
        SectionsMCDisableall = async (ids,datas) => {
            const SectionsMCDisableallsss = await Axios.post('SectionsMCDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionsMCDisableallsss.data;
        }
        deletebannerslist = async (ids) => {
            const deletebannerslistsss = await Axios.post('deletebannerslistqqq',{
                ids:ids,
            });
            return deletebannerslistsss.data;
        }
        secMCupdatesall = async (book,heading,fileempty,ids,title) => {
            const formData = new FormData();
            formData.append('avatar',book.file);
            formData.append('heading',heading);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            formData.append('title',title);
            const secMCupdatesallsss = await Axios.post('secMCupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return secMCupdatesallsss.data;
        }
        SectionsVCDisableall = async (ids,datas) => {
            const SectionsVCDisableallsss = await Axios.post('SectionsVCDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionsVCDisableallsss.data;
        }
        aboutpageupdatesVCall = async (desc,ids,title) => {
            const aboutpageupdatesVCallsss = await Axios.post('aboutpageupdatesVCallqqq',{
                desc:desc,
                ids:ids,
                title:title,
            });
            return aboutpageupdatesVCallsss.data;
        }
        SectionsHCDisableall = async (ids,datas) => {
            const SectionsHCDisableallsss = await Axios.post('SectionsHCDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionsHCDisableallsss.data;
        }
        secHCupdatesall = async (book,heading,fileempty,ids,title) => {
            const formData = new FormData();
            formData.append('avatar',book.file);
            formData.append('heading',heading);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            formData.append('title',title);
            const secHCupdatesallsss = await Axios.post('secHCupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return secHCupdatesallsss.data;
        }
        allVideolistgallery  = async (pageNumber) => {
            const allVideolistgalleryqqq = await Axios.get(`allVideolistgallerysss/${pageNumber}`);
            return allVideolistgalleryqqq.data;
        }
        VideoPreviewallgallery = async (ids) => {
            const VideoPreviewallgallerysss = await Axios.post('VideoPreviewallgalleryqqq',{
                ids:ids,
            });
            return VideoPreviewallgallerysss.data;
        }
        videoAddsssgallery = async (book,desc,) => {
            const videoAddsssgalleryqqq = await Axios.post('videoAddsssgallerysss',{
                title:book.title,
                video:book.video,
                desc:desc,
            });
            return videoAddsssgalleryqqq.data;
        }
        Videoupdatesallgallery = async (book,desc,ids) => {
            const Videoupdatesallgalleryqqq = await Axios.post('Videoupdatesallgallerysss',{
                title:book.title,
                video:book.video,
                desc:desc,
                ids:ids,
            });
            return Videoupdatesallgalleryqqq.data;
        }
        deletevideolistgallery = async (ids) => {
            const deletevideolistgalleryqqq = await Axios.post('deletevideolistgallerysss',{
                ids:ids,
            });
            return deletevideolistgalleryqqq.data;
        }        
        newfacilitesadd = async (userInfo,text,fileempty) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('category',userInfo.category);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            const newfacilitesaddsss = await Axios.post('newfacilitesaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfacilitesaddsss.data;
        }
        FacilitiesPreviewall = async (ids) => {
            const FacilitiesPreviewallqqq = await Axios.post('FacilitiesPreviewallsss',{
                ids:ids,
            });
            return FacilitiesPreviewallqqq.data;
        }  
        newfacilitesupdate = async (userInfo,text,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            formData.append('category',userInfo.category);
            formData.append('ids',ids);
            const newfacilitesupdatesss = await Axios.post('newfacilitesupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfacilitesupdatesss.data;
        } 
        deleteFacilitieslist = async (ids) => {
            const deleteFacilitieslistqqq = await Axios.post('deleteFacilitieslistsss',{
                ids:ids,
            });
            return deleteFacilitieslistqqq.data;
        } 
       
        deleteChurchChoircsss = async (ids) => {
            const deleteChurchChoircssssss = await Axios.post('deleteChurchChoircsssqqq',{
                ids:ids,
            });
            return deleteChurchChoircssssss.data;
        }  
        deleteSundaySchools = async (ids) => {
            const deleteSundaySchoolssss = await Axios.post('deleteSundaySchoolsqqq',{
                ids:ids,
            });
            return deleteSundaySchoolssss.data;
        }  
        deleteWorshipsss = async (ids) => {
            const deleteWorshipssssss = await Axios.post('deleteWorshipsssqqq',{
                ids:ids,
            });
            return deleteWorshipssssss.data;
        }  
        deleteWomensForumsss = async (ids) => {
            const deleteWomensForumssssss = await Axios.post('deleteWomensForumsssqqq',{
                ids:ids,
            });
            return deleteWomensForumssssss.data;
        }  
        deleteSocialWelfaresss = async (ids) => {
            const deleteSocialWelfaressssss = await Axios.post('deleteSocialWelfaresssqqq',{
                ids:ids,
            });
            return deleteSocialWelfaressssss.data;
        }  
        
       
        newChurchChoiradd = async (userInfo,text,fileempty) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('category',userInfo.category);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            const newChurchChoiraddsss = await Axios.post('newChurchChoiraddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newChurchChoiraddsss.data;
        }
        newSundaySchooladd = async (userInfo,text,fileempty) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('category',userInfo.category);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            const newSundaySchooladdsss = await Axios.post('newSundaySchooladdqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newSundaySchooladdsss.data;
        }
        newWorshipadd = async (userInfo,text,fileempty) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('category',userInfo.category);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            const newWorshipaddsss = await Axios.post('newWorshipaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newWorshipaddsss.data;
        }
        newWomensForumadd = async (userInfo,text,fileempty) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('category',userInfo.category);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            const newWomensForumaddsss = await Axios.post('newWomensForumaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newWomensForumaddsss.data;
        }
        newSocialWelfareadd = async (userInfo,text,fileempty) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('category',userInfo.category);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            const newSocialWelfareaddsss = await Axios.post('newSocialWelfareaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newSocialWelfareaddsss.data;
        }
        ChurchChoirPreviewall = async (ids) => {
            const ChurchChoirPreviewallqqq = await Axios.post('ChurchChoirPreviewallsss',{
                ids:ids,
            });
            return ChurchChoirPreviewallqqq.data;
        } 
        SundaySchoolPreviewall = async (ids) => {
            const SundaySchoolPreviewallqqq = await Axios.post('SundaySchoolPreviewallsss',{
                ids:ids,
            });
            return SundaySchoolPreviewallqqq.data;
        } 
        WorshipPreviewall = async (ids) => {
            const WorshipPreviewallqqq = await Axios.post('WorshipPreviewallsss',{
                ids:ids,
            });
            return WorshipPreviewallqqq.data;
        } 
        WomensForumPreviewall = async (ids) => {
            const WomensForumPreviewallqqq = await Axios.post('WomensForumPreviewallsss',{
                ids:ids,
            });
            return WomensForumPreviewallqqq.data;
        } 
        SocialWelfarePreviewall = async (ids) => {
            const SocialWelfarePreviewallqqq = await Axios.post('SocialWelfarePreviewallsss',{
                ids:ids,
            });
            return SocialWelfarePreviewallqqq.data;
        } 
       
        newchurchchoirupdate = async (userInfo,text,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            formData.append('category',userInfo.category);
            formData.append('ids',ids);
            const newchurchchoirupdatesss = await Axios.post('newchurchchoirupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newchurchchoirupdatesss.data;
        }
        newsundayschoolupdate = async (userInfo,text,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            formData.append('category',userInfo.category);
            formData.append('ids',ids);
            const newsundayschoolupdatesss = await Axios.post('newsundayschoolupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newsundayschoolupdatesss.data;
        }
        newworshipupdate = async (userInfo,text,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            formData.append('category',userInfo.category);
            formData.append('ids',ids);
            const newworshipupdatesss = await Axios.post('newworshipupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newworshipupdatesss.data;
        }
        newwomensforumupdate = async (userInfo,text,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            formData.append('category',userInfo.category);
            formData.append('ids',ids);
            const newwomensforumupdatesss = await Axios.post('newwomensforumupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newwomensforumupdatesss.data;
        }
        newsocialwelfareupdate = async (userInfo,text,fileempty,ids) => {
            const formData = new FormData();
            formData.append('title',userInfo.title);
            formData.append('avatar',userInfo.file);
            formData.append('text',text);
            formData.append('fileempty',fileempty);
            formData.append('category',userInfo.category);
            formData.append('ids',ids);
            const newsocialwelfareupdatesss = await Axios.post('newsocialwelfareupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newsocialwelfareupdatesss.data;
        }
        ContactDetailsupdatesall = async (userInfo,ids) => {
            const ContactDetailsupdatesallsss = await Axios.post('ContactDetailsupdatesallqqq',{
                map: userInfo.maps,
                address: userInfo.address,
                phone: userInfo.phone,
                email: userInfo.email,
                twitter: userInfo.twitter,
                facebook: userInfo.facebook,
                instagram: userInfo.instagram,
                youtube: userInfo.youtube,
                getintouch: userInfo.getintouch,
                ids:ids,
            });
            return ContactDetailsupdatesallsss.data;
        } 
        pasterupdatesall = async (userInfo,text,fileempty,ids,title) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('heading',text);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            formData.append('title',title);
            const pasterupdatesallsss = await Axios.post('pasterupdatesallqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return pasterupdatesallsss.data;
        }
        
        pastorDisableall = async (ids,datas) => {
            const pastorDisableallqqq = await Axios.post('pastorDisableallsss',{
                ids:ids,
                datas:datas,
            });
            return pastorDisableallqqq.data;
        }
        logochangeupdate = async (userInfo) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            const logochangeupdatesss = await Axios.post('logochangeupdateqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return logochangeupdatesss.data;
        }
        passwordchange = async (user,recaptcha_response) => {
            const passwordchangeqqq = await Axios.post('passwordchangesss',{
                password:user.name,
                recaptcha_response:recaptcha_response,
            });
            return passwordchangeqqq.data;
        }
        passwordchangeEmail = async (user,recaptcha_response,emilpassword) => {
            const passwordchangeEmailsss = await Axios.post('passwordchangeEmailqqq',{
                password:user.name,
                recaptcha_response:recaptcha_response,
                emilpassword:emilpassword,
            });
            return passwordchangeEmailsss.data;
        }

        alleventslistrec  = async (pageNumber) => {
            const alleventslistqqqrec = await Axios.get(`alleventslistsssrec/${pageNumber}`);
            return alleventslistqqqrec.data;
        }
        eventslistpreviewallrec = async (ids) => {
            const eventslistpreviewallqqqrec = await Axios.post('eventslistpreviewallsssrec',{
                ids:ids,
            });
            return eventslistpreviewallqqqrec.data;
        }
        EventsAddallrec = async (book,desc,sdate,ldate) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('location',book.location);
            formData.append('time',book.time);
            formData.append('period',book.period);
            formData.append('sdate',sdate);
            formData.append('ldate',ldate);
            const EventsAddallsssrec = await Axios.post('EventsAddallwwwrec',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return EventsAddallsssrec.data;
        } 
        EventsAddallOrganizerrec = async (book,ids,fileempty) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('avatar',book.file);
            formData.append('ids',ids);
            formData.append('fileempty',fileempty);
            const EventsAddallOrganizerqqqrec = await Axios.post('EventsAddallOrganizersssrec',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return EventsAddallOrganizerqqqrec.data;
        }
        Eventsupdatesallrec = async (book,desc,fileempty,sdate,ldate,eventsids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('fileempty',fileempty);
            formData.append('location',book.location);
            formData.append('time',book.time);
            formData.append('period',book.period);
            formData.append('sdate',sdate);
            formData.append('ldate',ldate);
            formData.append('eventsids',eventsids);
            const Eventsupdatesallsssrec = await Axios.post('Eventsupdatesallqqqrec',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return Eventsupdatesallsssrec.data;
        }
        deleteEventslistrec = async (ids) => {
            const deleteEventslistsssrec = await Axios.post('deleteEventslistqqqrec',{
                ids:ids,
            });
            return deleteEventslistsssrec.data;
        }
        galleryorderall = async (number,ids) => {
            const galleryorderallqqq = await Axios.post('galleryorderallsss',{
                number:number,
                ids:ids,
            });
            return galleryorderallqqq.data;
        }
        SocialWelfaresssallpreview = async (ids) => {
            const SocialWelfaresssallpreviewqqq = await Axios.post('SocialWelfaresssallpreviewsss',{
                ids:ids,
            });
            return SocialWelfaresssallpreviewqqq.data;
        }
        
        Facilitiesallpreview = async (ids) => {
            const Facilitiesallpreviewqqq = await Axios.post('Facilitiesallpreviewsss',{
                ids:ids,
            });
            return Facilitiesallpreviewqqq.data;
        }
        Churchallpreview = async (ids) => {
            const Churchallpreviewqqq = await Axios.post('Churchallpreviewsss',{
                ids:ids,
            });
            return Churchallpreviewqqq.data;
        }
        Sundayallpreview = async (ids) => {
            const Sundayallpreviewqqq = await Axios.post('Sundayallpreviewsss',{
                ids:ids,
            });
            return Sundayallpreviewqqq.data;
        }
        Womenallpreview = async (ids) => {
            const Womenallpreviewqqq = await Axios.post('Womenallpreviewsss',{
                ids:ids,
            });
            return Womenallpreviewqqq.data;
        }
        eventsallnew = async () => {
            const eventsallnewqqq = await Axios.get('eventsallnewsss');
            return eventsallnewqqq.data;
        } 
        eventsallpin = async () => {
            const eventsallpinqqq = await Axios.get('eventsallpinsss');
            return eventsallpinqqq.data;
        } 
        checkpinall = async () => {
            const checkpinallqqq = await Axios.get('checkpinallsss');
            return checkpinallqqq.data;
        }
        pinnedlist = async (ids) => {
            const pinnedlistqqq = await Axios.post('pinnedlistsss',{
                ids:ids,
            });
            return pinnedlistqqq.data;
        }
        unpinnedlist = async (ids) => {
            const unpinnedlistqqq = await Axios.post('unpinnedlistsss',{
                ids:ids,
            });
            return unpinnedlistqqq.data;
        }
        PasterMessageall = async () => {
            const PasterMessageallqqq = await Axios.get('PasterMessageallsss');
            return PasterMessageallqqq.data;
        }
        SectionAAADisableall = async (ids,datas) => {
            const SectionAAADisableallsss = await Axios.post('SectionAAADisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionAAADisableallsss.data;
        }
        SectionBBBDisableall = async (ids,datas) => {
            const SectionBBBDisableallsss = await Axios.post('SectionBBBDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionBBBDisableallsss.data;
        }
        SectionCCCDisableall = async (ids,datas) => {
            const SectionCCCDisableallsss = await Axios.post('SectionCCCDisableallqqq',{
                ids:ids,
                datas:datas,
            });
            return SectionCCCDisableallsss.data;
        }
       
        pasterpageupdatesall = async (desc,ids,title) => {
            const pasterpageupdatesallqqq = await Axios.post('pasterpageupdatesallsss',{
                desc:desc,
                ids:ids,
                title:title,
            });
            return pasterpageupdatesallqqq.data;
        }
        secBBBupdatesall = async (book,heading,fileempty,ids,title) => {
            const formData = new FormData();
            formData.append('avatar',book.file);
            formData.append('heading',heading);
            formData.append('fileempty',fileempty);
            formData.append('ids',ids);
            formData.append('title',title);
            const secBBBupdatesallqqq = await Axios.post('secBBBupdatesallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return secBBBupdatesallqqq.data;
        }
        
        pasterpageupdatesallCCC = async (userinfo,desc,ids,title) => {
            const pasterpageupdatesallCCCqqq = await Axios.post('pasterpageupdatesallCCCsss',{
                video:userinfo.video,
                desc:desc,
                ids:ids,
                title:title,
            });
            return pasterpageupdatesallCCCqqq.data;
        }
        
    render(){
        const contextValue = {
            
            rootState:this.state,
            loginUser:this.loginUser,
            isLoggedIn:this.isLoggedIn,
            logoutUser:this.logoutUser,


            menuslistall:this.menuslistall,
            addresslistall:this.addresslistall,
            bannerimgall:this.bannerimgall,
            sectiononeall:this.sectiononeall,
            servicesall:this.servicesall,
            Sermonsall:this.Sermonsall,
            homebgall:this.homebgall,
            eventsall:this.eventsall,
            eventsallrec:this.eventsallrec,
            aboutsall:this.aboutsall,
            blogsall:this.blogsall,
            Breadcrumbslistall:this.Breadcrumbslistall,
            ourteamsall:this.ourteamsall,
            PrayerRequestadd:this.PrayerRequestadd,
            contactsadd:this.contactsadd,
            alleventslist:this.alleventslist,
            alleventslistadmin:this.alleventslistadmin,
            alleventslistrec:this.alleventslistrec,
            eventslistpreviewall:this.eventslistpreviewall,
            allblogslist:this.allblogslist,
            bloglistpreviewall:this.bloglistpreviewall,
            allVideolist:this.allVideolist,
            VideoPreviewall:this.VideoPreviewall,
            Gallerysssall:this.Gallerysssall,
            GalleryPreviewlistall:this.GalleryPreviewlistall,
            ApiBlogAdslist:this.ApiBlogAdslist,
            ApiEventsAdslist:this.ApiEventsAdslist,
            ApiVideoAdslist:this.ApiVideoAdslist,
            Apipageslist:this.Apipageslist,
            Blogsearchalllistall:this.Blogsearchalllistall,
            eventssearchall:this.eventssearchall,
            vodeosearchall:this.vodeosearchall,
            Galleryfooterall:this.Galleryfooterall,

            newblogsadd:this.newblogsadd,
            blogupdatesall:this.blogupdatesall,
            deleteblogslist:this.deleteblogslist,

            EventsAddall:this.EventsAddall,
            Eventsupdatesall:this.Eventsupdatesall,
            deleteEventslist:this.deleteEventslist,
            EventsAddallOrganizer:this.EventsAddallOrganizer,

            videoAddsss:this.videoAddsss,
            Videoupdatesall:this.Videoupdatesall,
            deletevideolist:this.deletevideolist,

            Facilitiessssall:this.Facilitiessssall,
            Worshipsssall:this.Worshipsssall,
            SundaySchoolsssall:this.SundaySchoolsssall,
            ChurchChoircsssall:this.ChurchChoircsssall,
            WomensForumsssall:this.WomensForumsssall,
            SocialWelfaresssall:this.SocialWelfaresssall,

            Addteamsall:this.Addteamsall,
            teamupdatesall:this.teamupdatesall,
            deleteTeamlist:this.deleteTeamlist,

            galleryAddcategoryads:this.galleryAddcategoryads,
            deletegallerycategorylist:this.deletegallerycategorylist,
            gallcatsupdatesall:this.gallcatsupdatesall,
            galcatlistpreviewall:this.galcatlistpreviewall,
            galleryAddall:this.galleryAddall,
            deletegallerylist:this.deletegallerylist,

            servieslistpreviewall:this.servieslistpreviewall,
            servicestimeupdatesall:this.servicestimeupdatesall,

            VideoLiveall:this.VideoLiveall,
            videoAddssslives:this.videoAddssslives,
            deletelivelist:this.deletelivelist,
            videoliveeditslist:this.videoliveeditslist,

            bannerimgalleditlist:this.bannerimgalleditlist,
            sliderupdatesall:this.sliderupdatesall,
            SectiononeDisableall:this.SectiononeDisableall,
            seconeupdatesall:this.seconeupdatesall,
            SectioneightDisableall:this.SectioneightDisableall,
            SectiontwoDisableall:this.SectiontwoDisableall,
            SectionVisionDisableall:this.SectionVisionDisableall,
            SectionMissionDisableall:this.SectionMissionDisableall,
            secfourupdatesall:this.secfourupdatesall,
            SectionfourDisableall:this.SectionfourDisableall,
            secnineupdatesall:this.secnineupdatesall,
            SectionnineDisableall:this.SectionnineDisableall,
            SectionsevenDisableall:this.SectionsevenDisableall,
            allvideogallerylist:this.allvideogallerylist,
            VideogalleryPreviewall:this.VideogalleryPreviewall,
            aboutpageupdatesall:this.aboutpageupdatesall,
            SectionwwrcDisableall:this.SectionwwrcDisableall,
            slidercreateall:this.slidercreateall,
            SectionsofcDisableall:this.SectionsofcDisableall,
            aboutpagetwoupdatesall:this.aboutpagetwoupdatesall,
            SectionsMCDisableall:this.SectionsMCDisableall,
            deletebannerslist:this.deletebannerslist,
            secMCupdatesall:this.secMCupdatesall,

            SectionsVCDisableall:this.SectionsVCDisableall,
            aboutpageupdatesVCall:this.aboutpageupdatesVCall,
            SectionsHCDisableall:this.SectionsHCDisableall,
            secHCupdatesall:this.secHCupdatesall,
            allVideolistgallery:this.allVideolistgallery,
            videoAddsssgallery:this.videoAddsssgallery,
            VideoPreviewallgallery:this.VideoPreviewallgallery,
            Videoupdatesallgallery:this.Videoupdatesallgallery,
            deletevideolistgallery:this.deletevideolistgallery,

            newfacilitesadd:this.newfacilitesadd,
            FacilitiesPreviewall:this.FacilitiesPreviewall,
            newfacilitesupdate:this.newfacilitesupdate,
            deleteFacilitieslist:this.deleteFacilitieslist,

            deleteChurchChoircsss:this.deleteChurchChoircsss,
            deleteSundaySchools:this.deleteSundaySchools,
            deleteWorshipsss:this.deleteWorshipsss,
            deleteWomensForumsss:this.deleteWomensForumsss,
            deleteSocialWelfaresss:this.deleteSocialWelfaresss,

            newChurchChoiradd:this.newChurchChoiradd,
            newSundaySchooladd:this.newSundaySchooladd,
            newWorshipadd:this.newWorshipadd,
            newWomensForumadd:this.newWomensForumadd,
            newSocialWelfareadd:this.newSocialWelfareadd,

            ChurchChoirPreviewall:this.ChurchChoirPreviewall,
            SundaySchoolPreviewall:this.SundaySchoolPreviewall,
            WorshipPreviewall:this.WorshipPreviewall,
            WomensForumPreviewall:this.WomensForumPreviewall,
            SocialWelfarePreviewall:this.SocialWelfarePreviewall,

            newchurchchoirupdate:this.newchurchchoirupdate,
            newsundayschoolupdate:this.newsundayschoolupdate,
            newworshipupdate:this.newworshipupdate,
            newwomensforumupdate:this.newwomensforumupdate,
            newsocialwelfareupdate:this.newsocialwelfareupdate,
            ContactDetailsupdatesall:this.ContactDetailsupdatesall,
            pasterupdatesall:this.pasterupdatesall,
            pastorDisableall:this.pastorDisableall,
            logochangeupdate:this.logochangeupdate,
            passwordchange:this.passwordchange,
            passwordchangeEmail:this.passwordchangeEmail,

            alleventslistrec:this.alleventslistrec,
            eventslistpreviewallrec:this.eventslistpreviewallrec,
            EventsAddallrec:this.EventsAddallrec,
            Eventsupdatesallrec:this.Eventsupdatesallrec,
            deleteEventslistrec:this.deleteEventslistrec,
            EventsAddallOrganizerrec:this.EventsAddallOrganizerrec,
            galleryorderall:this.galleryorderall,
            eventsallOldest:this.eventsallOldest,
            alleventslistoldest:this.alleventslistoldest,
            
            SocialWelfaresssallpreview:this.SocialWelfaresssallpreview,
            Facilitiesallpreview:this.Facilitiesallpreview,
            Churchallpreview:this.Churchallpreview,
            Sundayallpreview:this.Sundayallpreview,
            Womenallpreview:this.Womenallpreview,
            eventsallnew:this.eventsallnew,
            pinnedlist:this.pinnedlist,
            unpinnedlist:this.unpinnedlist,
            eventsallpin:this.eventsallpin,
            checkpinall:this.checkpinall,
            PasterMessageall:this.PasterMessageall,
            SectionAAADisableall:this.SectionAAADisableall,
            pasterpageupdatesall:this.pasterpageupdatesall,
            SectionBBBDisableall:this.SectionBBBDisableall,
            secBBBupdatesall:this.secBBBupdatesall,
            SectionCCCDisableall:this.SectionCCCDisableall,
            pasterpageupdatesallCCC:this.pasterpageupdatesallCCC,

           
            
           
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;