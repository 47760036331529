import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from "react-router-dom";
import AdminwomensforumEdittwo from './AdminwomensforumEdittwo';
import {Link } from "react-router-dom"; 



function AdminWomensForumEdit(props) {
       
  let history = useHistory();

   const {WomensForumPreviewall} = useContext(MyContext);

   useEffect( () => {
       videolistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 


  const [isvideolistpreview, setvideolistpreview] = useState([]);
  const videolistpreview = async (ids) =>{
  try {
         const videolistpreviewsss = await WomensForumPreviewall(ids);
                if(videolistpreviewsss.success === 1){
                       console.log('WomensForumlistpreview',videolistpreviewsss.FacilitiesPreviewalllist);
                       setvideolistpreview(videolistpreviewsss.FacilitiesPreviewalllist);
         }
  } catch (error) { throw error;}
 }
 
  return (
   <>
         {isvideolistpreview.map((item, index) => ( 
               <div  key={item.womensforum_id} className="col-md-12">
                    
                 <AdminwomensforumEdittwo Facilitiesid={item.womensforum_id} title={item.heading1} text1={item.text1}   />
                     
           </div>
          
            ))}
  




   </>
  );
}

export default AdminWomensForumEdit;