import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Link } from "react-router-dom"; 
import {toast} from "react-toastify";
import { WEB_URL } from '../contexts/Apilinks';
let BaseURL = WEB_URL;

function AdminGalleryMultiple(props) {

  let history = useHistory();

       
       const {Gallerysssall} = useContext(MyContext);
       
       const [loadingadd, setloadingadd] = useState(false);
      
       const [userInfo, setuserInfo] = useState({
              category: '',
              file:[],
                
       });
       
   
       useEffect( () => {
        window.scrollTo(0, 0);
        Gallerysss()
           }, []); 
           const [isallgallery, setallgallery] = useState([]);

           const Gallerysss = async() => {
             const Gallerysssqqq = await Gallerysssall();
             if(Gallerysssqqq.success === 1){
                    console.log('Gallerysssqqq',Gallerysssqqq.Galleryssslist);
                    setallgallery(Gallerysssqqq.Galleryssslist);
                 
             }
          }


    
       const onChangeValue = (e) => {
         
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 


       const galleryAddcategory = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            console.log('xxxxxxxxxxxxxxx',userInfo.file);
                            if(userInfo.file.length > 10){
                              toast.error('Maximum images allowed : 10', {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;


                            }
                            if(userInfo.file.length === 0){
                              toast.error('Minimum images allowed : 1', {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;


                            }
                            var formData = new FormData();
                            console.log('uuuuuuuuuuu',userInfo.file);
                             for (const key of Object.keys(userInfo.file)) {
                                 formData.append('avatar', userInfo.file[key])
                             }
                            formData.append('category',userInfo.category);
                            axios.post(BaseURL+`galleryAddallssswww`, formData, {
                            }).then(res => {
                              console.log(res)
                              setloadingadd(false);
                              if(res.error_code === 1){
                                toast.error('Server Problem in uploading image files.Please try again', {
                                  position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                }); 
                                setloadingadd(false);
                                return;
  
                              }

                              if(res.data.success === 1){
                                toast( 'Image uploaded successfully.', {
                                  position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                });  
                                setloadingadd(false);
                                event.target.reset(); 
                                history.push(`/AdminGallery`);
                         }else{
                                setloadingadd(false);
                                 toast.error( 'Server Problem in uploading image files. Please Try Again', {
                                   position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                 });  
                         }

                         setloadingadd(false);
                         
                          
                            })


              } catch (error) { throw error;}    
       }
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
     


       var multipleimage = [];
      const handleChange = (event) => {
        if(event.target.files.length > 10){
          toast.error('Maximum images allowed : 10', {
            position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          }); 
          return;
        }
       setinvalidImage(null);
        for (const keyvalue of Object.keys(event.target.files)) {

          const imageFile = event.target.files[keyvalue];
          const imageFilname = event.target.files[keyvalue].name;
          if (!imageFile) {
            setinvalidImage('Please select image.');
            return false;
          }
          if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
            setinvalidImage('Please select valid image JPG,JPEG,PNG');
            return false;
          }

          let reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = async() => {
          //setinvalidImage(' sdasdasf df sdf asdf ');
              //----------------------------- Resize img code -------------------------------------------------
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);

              var MAX_WIDTH = 1000;
              var MAX_HEIGHT = 477;
              var width = img.width;
              var height = img.height;

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);
              ctx.canvas.toBlob((blob) => {
                const file = new File([blob], event.target.files[keyvalue].name, {
                    type: 'image/png',
                    lastModified: Date.now(),
                    length:[keyvalue]
                });
              
                multipleimage.push(file);

              }, 'image/png', 1);


              //-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(event.target.files[keyvalue]))
              setfileempty(true);
              setinvalidImage(null)

            };
            img.onerror = () => {setinvalidImage('Invalid image content.');return false;};
            //debugger
            img.src = e.target.result;
          };
          reader.readAsDataURL(event.target.files[keyvalue]);
        }
        console.log('qqqqqqqqqqqqqq',event.target.files);
        console.log('multipleimage',multipleimage);

        setuserInfo({
               ...userInfo,
               file:multipleimage
           });


      }
        return(
               <div className="bookads2">
                   <Link to={`/AdminGallery`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>     
                     <div className="">
                            <div className="">
                                  
                                   <div className="col-md-12" style={{padding:0}}>
                                  
       
              <form onSubmit={galleryAddcategory} className="central-meta">
            
              <div className="clearfix"></div>
              <div className="form-row">
             


             
                     
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Category Name <span className="adverts-form-required"> * </span> </label>
                      <select  name="category" value={userInfo.category} onChange={onChangeValue} className="wqsss form-control"  required>
                      <option value=""> Choose the field </option>
                      {isallgallery.map((item, index) => ( 
                              <option key={item.gallery_category_id }> {item.gallery_category_name }</option>
                      ))}
                              
                            </select>
                  </div>
                
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Images <span className="adverts-form-required"> * </span> </label>
                      <div className="clearfix"></div>

                      <div className="dropzone-wrapper">
                        <div className="dropzone-desc">
                        <FontAwesomeIcon icon={['fas','upload']} />
                          <p>Choose an image file or drag it here.</p>
                        </div>
                        <input type="file" name="file" className="form-control dropzone" placeholder="Title"  onChange={handleChange}  multiple required/>
                      
                      </div>

                     
                     
                  </div>
                  {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null } 
              
                  {/* {isImagePreviews !== '' && (
                        <div style={{ textAlign: "center" }}>
                        
                             <img className="previewimg"  src={isImagePreviews} alt="UploadImage" />
                      
                        </div>
                        )}  */}
             
            
                         

                {invalidImage !== null ? null :<>     
              {isallgallery.length > 0 ? <>   
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#7b2e00" height={67} width={67} style={{float: "right",width: "100%",textAlign: "right"}}/>
                            </>
                         : 
                     <div className="form-group col-sm-12 text-right">
              
                            <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                            
                     </div> 
                   }
              </> : null }
              </>} 
              </div>
              
          </form>
          </div>


  
          
          </div>
          </div>





          </div>
        );
    }

export default AdminGalleryMultiple;