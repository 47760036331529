import React,{useContext,useState,useEffect } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert'; 

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminGallery() {
   const {Gallerysssall,deletegallerycategorylist} = useContext(MyContext);

   useEffect( () => {
     Gallerysss()
     window.scrollTo(0, 0);
  }, []);
 
  
 
 const [isallgallery, setallgallery] = useState([]);

 const Gallerysss = async() => {
   const Gallerysssqqq = await Gallerysssall();
   if(Gallerysssqqq.success === 1){
          console.log('Gallerysssqqq',Gallerysssqqq.Galleryssslist);
          setallgallery(Gallerysssqqq.Galleryssslist);
       
   }
}

const deletes = (blogids) => {
   confirmAlert({
     title: 'Confirm to Delete ',
     message: 'Are you sure to do this.',
     buttons: [
       {
         label: 'Yes',
         onClick: () => deleteblogs(blogids)
       },
       {
         label: 'No',
       }
     ]
   })
 };     
 const deleteblogs = async (blogids) => {
       
   try {
          const deleteblogssss = await deletegallerycategorylist(blogids);
          if(deleteblogssss.success === 1){
            setallgallery([]);
            Gallerysss();
 
          }
   } catch (error) { throw error;}      
 
 
 }

  return (
   <>

{/* -------------------- --------------------------------------------------------------------------------------------------- */}


<div className="Admingallerysss">
<Link to={`/Admingallerycategory`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Create Category </span></Link>
<Link to={`/AdminRearrangecategory`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Rearrange Category </span></Link>
   
<div className="gallerysss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isallgallery.map((item, index) => ( 
               <div  key={item.gallery_category_id } className="col-md-4">
                   
               <div className="ggaallsss">
               <Link to={`/AdmingallerycategoryEdit/${item.gallery_category_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></Link>
               <p className="Adminbuttonlink2" onClick={()=>deletes(item.gallery_category_id)}><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></p>
                  <Link to={`/AdminGalleryPreview/${item.gallery_category_id}`} className="">
                   <h3> {item.gallery_category_name} </h3>
                  <div className="gallerysss2">
                  <h3> {item.gallery_category_name} </h3>
                     <img src={BaseURLuploads+item.gallery_category_image} alt="" />
                  </div>
                  </Link>
               </div>
               </div>
            ))}
  </div>
  </div>
   </div>
</div>
</div>


   </>
  );
}

export default AdminGallery;