import React,{useContext,useState,useEffect} from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { MyContext } from '../contexts/MyContext';
import Carousel, {autoplayPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import AdminSeconeUpdate from './AdminSeconeUpdate';
import AdminfourUpdate from './AdminfourUpdate';
import AdminnineUpdate from './AdminnineUpdate';
import AdminpastorUpdate from './AdminpastorUpdate';
import { confirmAlert } from 'react-confirm-alert'; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminHome() {
   const {bannerimgall,sectiononeall,Sermonsall,homebgall,eventsall,aboutsall,blogsall,SectiononeDisableall,SectioneightDisableall,SectiontwoDisableall,SectionVisionDisableall,SectionMissionDisableall,SectionfourDisableall,SectionnineDisableall,SectionsevenDisableall,deletebannerslist,pastorDisableall} = useContext(MyContext);

   useEffect( () => {
     bannerimg();
     sectiononelist();
     Sermons();
     homebg();
     events();
     abouts();
     blogs();
     window.scrollTo(0, 0);
  }, []);
  
  const [isbannerimg, setbannerimg] = useState([]);
   const bannerimg = async() =>{
   try {
          const bannerimgresult = await bannerimgall();
                 if(bannerimgresult.success === 1){
                        console.log('banner',bannerimgresult.bannerlist);
                        setbannerimg(bannerimgresult.bannerlist) ;
          }
   } catch (error) { throw error;}
 }
 const [issectionone, setsectionone] = useState([]);
 const [issectiononebg, setsectiononebg] = useState([]);
 const [issectiononebgtwo, setsectiononebgtwo] = useState([]);
   const sectiononelist = async() =>{
   try {
          const sectiononelistqqq = await sectiononeall();
                 if(sectiononelistqqq.success === 1){
                        console.log('sectionone',sectiononelistqqq.sectiononelistall);
                        setsectionone(sectiononelistqqq.sectiononelistall) ;
                        setsectiononebg(sectiononelistqqq.sectiononelistall[0].bg);
                        setsectiononebgtwo(sectiononelistqqq.sectiononelistall[0].bg2);
          }
   } catch (error) { throw error;}
 }
   
const [isSermons, setSermons] = useState([]);
   const Sermons = async() =>{
   try {
          const Sermonswww = await Sermonsall();
                 if(Sermonswww.success === 1){
                        console.log('Sermons',Sermonswww.Sermonslist);
                        setSermons(Sermonswww.Sermonslist);
                      
          }
   } catch (error) { throw error;}
 }
const [ishomebg, sethomebg] = useState([]);
   const homebg = async() =>{
   try {
          const homebgsss = await homebgall();
                 if(homebgsss.success === 1){
                        console.log('homebg',homebgsss.homebglist);
                        sethomebg(homebgsss.homebglist);
          }
   } catch (error) { throw error;}
 }
const [isevents, setevents] = useState([]);
   const events = async() =>{
   try {
          const eventsss = await eventsall();
                 if(eventsss.success === 1){
                        console.log('events',eventsss.eventslist);
                        setevents(eventsss.eventslist);
          }
   } catch (error) { throw error;}
 }
const [isabouts, setabouts] = useState([]);
   const abouts = async() =>{
   try {
          const aboutsss = await aboutsall();
                 if(aboutsss.success === 1){
                        console.log('abouts',aboutsss.aboutslist);
                        setabouts(aboutsss.aboutslist);
          }
   } catch (error) { throw error;}
 }
const [isblogs, setblogs] = useState([]);
   const blogs = async() =>{
   try {
          const blogsss = await blogsall();
                 if(blogsss.success === 1){
                        console.log('blogs',blogsss.blogslist);
                        setblogs(blogsss.blogslist);
          }
   } catch (error) { throw error;}
 }
 
 
 const SectiononeDisable = async(ids) =>{
       try {
              const SectiononeDisablesss = await SectiononeDisableall(ids);
                     if(SectiononeDisablesss.success === 1){
                            console.log('SectiononeDisablesss');
                            setsectionone([]);
                            sectiononelist();
              }
       } catch (error) { throw error;}
     }
     const SectioneightDisable = async(ids,datas) =>{
       try {
             
              const SectioneightDisablesss = await SectioneightDisableall(ids,datas);
                     if(SectioneightDisablesss.success === 1){
                            console.log('SectioneightDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const SectiontwoDisable = async(ids,datas) =>{
       try {
             
              const SectiontwoDisablesss = await SectiontwoDisableall(ids,datas);
                     if(SectiontwoDisablesss.success === 1){
                            console.log('SectiontwoDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
   
     const SectionVisionDisable = async(ids,datas) =>{
       try {
        
              const SectionVisionDisablesss = await SectionVisionDisableall(ids,datas);
                     if(SectionVisionDisablesss.success === 1){
                            console.log('SectionVisionDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const SectionMissionDisable = async(ids,datas) =>{
       try {
             
              const SectionMissionDisablesss = await SectionMissionDisableall(ids,datas);
                     if(SectionMissionDisablesss.success === 1){
                            console.log('SectionMissionDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const SectionfourDisable = async(ids,datas) =>{
       try {
              const SectionfourDisablesss = await SectionfourDisableall(ids,datas);
                     if(SectionfourDisablesss.success === 1){
                            console.log('SectionfourDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const SectionnineDisable = async(ids,datas) =>{
       try {
              const SectionnineDisablesss = await SectionnineDisableall(ids,datas);
                     if(SectionnineDisablesss.success === 1){
                            console.log('SectionnineDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const SectionsevenDisable = async(ids,datas) =>{
       try {
              const SectionsevenDisablesss = await SectionsevenDisableall(ids,datas);
                     if(SectionsevenDisablesss.success === 1){
                            console.log('SectionsevenDisable');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const pastorDisable = async(ids,datas) =>{
       try {
              const pastorDisablesss = await pastorDisableall(ids,datas);
                     if(pastorDisablesss.success === 1){
                            console.log('pastorDisableall');
                            sethomebg([]);
                            homebg();
              }
       } catch (error) { throw error;}
     }
     const [isSectiononeEdit, setSectiononeEdit] = useState(false);
     const [isSectionfourEdit, setSectionfourEdit] = useState(false);
     const [isSectionnineEdit, setSectionnineEdit] = useState(false);
     const [ispastorEdit, setpastorEdit] = useState(false);
const SectiononeEdit = () =>{ setSectiononeEdit(true); }
const SectionfourEdit = () =>{ setSectionfourEdit(true); }
const SectionnineEdit = () =>{ setSectionnineEdit(true); }
const pastorEdit = () =>{ setpastorEdit(true); }
const editcancel = async() =>{ setSectiononeEdit(false);}
const editpastor = async() =>{ setpastorEdit(false);}
const editcancelfour = async() =>{ setSectionfourEdit(false);}
const editcancelnine = async() =>{ setSectionnineEdit(false);}
 const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
 
 const deletes = (blogids) => {
   confirmAlert({
     title: 'Confirm to Delete ',
     message: 'Are you sure to do this.',
     buttons: [
       {
         label: 'Yes',
         onClick: () => deleteblogs(blogids)
       },
       {
         label: 'No',
       }
     ]
   })
 };     
 const deleteblogs = async (blogids) => {
       
   try {
          const deleteblogssss = await deletebannerslist(blogids);
          if(deleteblogssss.success === 1){
            setbannerimg([]);
            bannerimg();
 
          }
   } catch (error) { throw error;}      
 
 
 }
  return (
   <>
<div className="Adminhomesss">
       <div className="banner">
      <Carousel slidesPerScroll={1} slidesPerPage={1} infinite  infinite arrows={true} 
       breakpoints={{ 640: { slidesPerPage: 1, arrows: false}, 900: {slidesPerPage: 1, arrows: false  }  }}  >
           {isbannerimg.map((item, index) => ( 
               <div  key={item.banner_id} className="bannerimgsss">
                     {/* <p onClick={()=>deletes(item.banner_id)} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete  </span></p> */}
                     <Link to={`/AdminSliderEdit/${item.banner_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit Image </span></Link>
                     {/* <Link to={`/AdminCreateSlider`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','plus']} /> Create Slider </span></Link> */}
                     
                  <img src={BaseURLuploads+item.slider} className=""/>
                  <div className="banners2">
                  {item.bannertext_1 !== '' ?  <h1> {item.bannertext_1}</h1> : <h1> &nbsp; </h1>}
                  {item.bannertext_2 !== '' ?  <h1> {item.bannertext_2}</h1> : <h1> &nbsp; </h1>}
                  {item.bannertext_3 !== '' ?  <p> {item.bannertext_3}</p> : <p> &nbsp; </p>}
                  {item.bannertext_button !== '' ?  <h6 style={{cursor:"pointer"}}> <FontAwesomeIcon icon={['fas','arrow-down']} className="bounce" /> </h6> : <h6> &nbsp; </h6>}
                  </div></div> 
           ))}</Carousel>
</div>
{/* -------------------------------------------------------------------------------------- */}
<div className="adminpostersss">
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'pastor' ? <>
           
         <p onClick={pastorEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
           
            
           {item.Active === 'yes' || item.Active === '' ?  <> 
              <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
              <p  onClick={()=>pastorDisable('no','pastor')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
              <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
              </>
           :
           <> 
              <p onClick={()=>pastorDisable('yes','pastor')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
              <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
              <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
           </>}
   {ispastorEdit === false ? <>   
               <div className={ "sectionpastor" + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")}>
          
               <div className="container">
                  <div className="row">
                     
                  {item.images !== '' ?<>
                           <div className="col-md-3"> 
                              <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
                           </div>
                        </>
                        : null}
                    <div class="col">
                        <div class="selectionemptsss"></div>
                     </div>
                     <div className={ " " + (item.images !== '' ? " col-md-8 " : "col-md-12 ")}>
                        <h1 className="sectionpastor3" dangerouslySetInnerHTML={{ __html: item.heading }} />
                        <div className="sectionpastor2" dangerouslySetInnerHTML={{ __html: item.content }} />
                     </div>

            </div>
            </div>
            </div>
            </> :<>
       <AdminpastorUpdate ids={item.homeimages_id} heading={item.heading} content={item.content}  cancel={editpastor} refresh={ ()=>homebg()} />
 </>}
        


      
      </> : null}
    </div>
))}
</div>

{/* -------------------------------------------------------------------------------------- */}
<div className="Sectionone" style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>
<img className="p_absoulte" src={BaseURLuploads+issectiononebgtwo} alt={issectiononebgtwo} />
   <div className="container">
      <div className="row">
{issectionone.map((item, index) => ( 
   <div  key={item.hom_sec_oneid} className="">
            <p onClick={SectiononeEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
           
            
            {item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p  onClick={()=>SectiononeDisable('no')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectiononeDisable('yes')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </>}

<div className={ "adminsss" + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")}>

{isSectiononeEdit === false ? <>   

       <img className="pegion" src={BaseURLuploads+item.bg4} alt="" />
         <div className="col-md-12">
         <h1 className="Sectiononetitle" dangerouslySetInnerHTML={{ __html: item.heading }} />
           
         </div>
         <div className="col-md-6 ">
            <div className="creative_about_img">
               <div className="creative_shap"></div>
               <img src={BaseURLuploads+item.images} alt="" />
               <img className="dot p_absoulte" src={BaseURLuploads+item.bg3} alt="" />
               </div>
            </div>
            <div className="col-md-6 Sectiononetitle2">
               <p> <b className="" dangerouslySetInnerHTML={{ __html: item.text_1 }} /> </p>
               <ul className="" dangerouslySetInnerHTML={{ __html: item.text_2 }} />
              
               <p className="" dangerouslySetInnerHTML={{ __html: item.text_3 }} />
               {item.buttons !== '' ? 
                     <Link to={`/${item.buttonlink}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> {item.buttons} </span></Link>
               : null}
              
         </div>

</> :<>
       <AdminSeconeUpdate ids={item.hom_sec_oneid} heading={item.heading} text1={item.text_1} text2={item.text_2} text3={item.text_3} cancel={editcancel} refresh={ ()=>sectiononelist()} buttonlink={item.buttonlink} buttons={item.buttons} />
 </>}
         </div>
         </div>
))}
  </div>
   </div>
</div>

{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------- Recent Sermons ------------------------------------------------------ */}

               
<div className="sectioneight">
   <div className="container">
      <div className="row">
             
         <div className="col-md-12">
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Sermons'? <>

{item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p   onClick={()=>SectioneightDisable('no','Sermons')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectioneightDisable('yes','Sermons')}   className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </>}
                 <h1 dangerouslySetInnerHTML={{ __html: item.content }} />
                 </>
               : null}
            </div>
         ))}
         </div>
      </div>
   </div>
</div>

{ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Sermons'? <>
<div className={ "sectioneighttwo" + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")} style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>

         <img className="p_absoulte2" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
    
   <div className="container">
      <div className="row">
          
      {isSermons.map((item, index) => ( 
         <div key={item.Sermons_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-5">
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
                 
         </div>
         <div className="col-md-6">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
            <Link to={`/VideoPreview/${item.Sermons_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> More </span></Link>
            </div>
         </div>  
      ))}
       <Link to={`/Video`} className="buttonlink7"><span>   View All  </span></Link>
         </div>
      </div>
   </div>
   </>: null}
            </div>
         ))}
{/* ---------------------------------- Events ---------------------------------------------------- */}
<div className="sectiontwo">
<img className="p_absoulte" src={BaseURLuploads+issectiononebgtwo} alt={issectiononebgtwo} />
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Event' ? <>
             
{item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectiontwoDisable('no','Event')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectiontwoDisable('yes','Event')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </> }
                 <div dangerouslySetInnerHTML={{ __html: item.content }} />

                
           
           
      
           <div className={ "col-md-12 events" + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")}>
       

         {isevents.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3" style={{overflow: "hidden"}}>
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
             
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
</>
}
          
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}
  
  <Link to={`/Events`} className="buttonlink72"><span>   View All  </span></Link>
  
  </div>
</> : null}
</div>
))}
</div>
</div>
</div>
</div>
{/* -------------------------------------------------------------------------------------- */}

{/* ------------------------------- Vision & Mission ------------------------------------ */}
<div className="sectionfive" style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>
   <div className="container" >
      <div className="row" >
     
      {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12">

               {ishomebg.map((item, index) => ( 
                  <div key={item.homeimages_id} >
                        {item.image_name === 'Vision' ? <>
                        
                     {item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionVisionDisable('no','Vision')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectionVisionDisable('yes','Vision')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </>}
                        
                        </>
                     : null}
                  </div>
               ))}


{ishomebg.map((itemwww, index) => ( 
            <div key={itemwww.homeimages_id} >
               {itemwww.image_name === 'Vision' ? <>
                           
<div className={ "col-md-12 " + (itemwww.Active === 'yes' || itemwww.Active === '' ? " adminenables " : " admindisablsss")}>

         <div className="col-md-6" >
         
               
                  <img className="attachmentsss" src={BaseURLuploads+itemwww.images} alt={issectiononebgtwo} /> 
                 
         </div>
         <div className="col-md-6 " >
         <div className=" </div> " >
            <h1  dangerouslySetInnerHTML={{ __html: item.V_C_heading }} /> 
            <div  dangerouslySetInnerHTML={{ __html: item.Vision }} /> 
         </div>
            <Link to={`/About/#Vision`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
        
         </div>
         </div>
         </>
               : null}
            </div>
         ))}




         </div>
   ))}  
    {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12">    
                {/* {ishomebg.map((item, index) => ( 
                  <div key={item.homeimages_id} className=" sectionfive2 " >
                        {item.image_name === 'Mission' ? <>
                        {item.Active === 'yes' || item.Active === '' ?
                        <p onClick={()=>SectionMissionDisable('no','Mission')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Disable </span></p>
                     :
                        <p onClick={()=>SectionMissionDisable('yes','Mission')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Enable </span></p>
                     }
                        
                        </>
                     : null}
                  </div>
               ))}  */}
{ishomebg.map((itemwwwmmm, index) => ( 
            <div key={itemwwwmmm.homeimages_id} >
               {itemwwwmmm.image_name === 'Mission' ? <>
                           
<div className={ "col-md-12 " + (itemwwwmmm.Active === 'yes' || itemwwwmmm.Active === '' ? " adminenables " : " admindisablsss")}>               
         <div className="col-md-6 sectionfive2 " >
            <div className="sectionfive3" >
            <h1  dangerouslySetInnerHTML={{ __html: item.M_C_heading }} /> 
            <div  dangerouslySetInnerHTML={{ __html: item.Mission }} /> 
            </div>
            <Link to={`/About/#Mission`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
         </div>
         <div className="col-md-6 sectionfive2 " >
        
             
                  <img className="attachmentsss" src={BaseURLuploads+itemwwwmmm.images} alt={issectiononebgtwo} />
             
         </div>
         </div>
         </>
               : null}
            </div>
         ))}



         </div>
   ))}  
      </div>
   </div>
</div>

{/* -------------------------------- We Love God We Believe in God ------------------------------------------------------ */}


{ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
                {item.image_name === 'WeLoveGod' ?<>
               <p onClick={SectionfourEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
               {item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionfourDisable('no','WeLoveGod')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectionfourDisable('yes','WeLoveGod')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </>}
{isSectionfourEdit === false ? <>  
   <div className={ "adminsectionfour sectionfour " + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")}> 
   <div className="swmsc_half_bg_container swmsc_half_bg_right col-md-6" style={{backgroundImage: `url(${BaseURLuploads+item.images})`}}> 
                <div className="swmsc_half_bg2 swmsc_half_bg2sss">
                      <div className="row">
                         <div className="col-md-12">
                         <div  className="colmd12nesss" dangerouslySetInnerHTML={{ __html: item.content }} /> 
                         <Link to={`/${item.buttonlink}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> {item.buttons} </span></Link>
                         </div>
                      </div>
                    </div>
                </div>
                <div className="swmsc_half_bg col-md-6" > 
      <div className="swmsc_half_bg2">
         <div className="row">
            <div className="col-md-12">
                  <h1  dangerouslySetInnerHTML={{ __html: item.heading }} /> 
                  <div className="colmd12nesss" dangerouslySetInnerHTML={{ __html: item.content }} /> 
               <Link to={`/${item.buttonlink}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> {item.buttons} </span></Link>
            </div>
         </div>
       </div>
   </div>
</div>
   </> : <>   

   <AdminfourUpdate ids={item.homeimages_id} content={item.content}  heading={item.heading} cancel={editcancelfour} refresh={ ()=>homebg()} buttonlink={item.buttonlink} buttons={item.buttons} />
   
   
   </>}   
   </> : null}
            </div>
         ))}
  
 

{/* -------------------------------- Niagara Prayer Center ------------------------------------------------------ */}

{ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
                {item.image_name === 'NPC' ?<>
             

{isSectionnineEdit === false ? <>   
<div className="sectionnine" style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>
   <div className="container">
      <div className="row">
      <div className="col-md-12">
                  <p onClick={SectionnineEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                 

                {item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionnineDisable('no','NPC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectionnineDisable('yes','NPC')} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </>}
            </div>
            <div className={ "col-md-12 " + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")}>   
         <div className="col-md-6">
            <h1  dangerouslySetInnerHTML={{ __html: item.heading }} /> 
            <div className="sectionninedesc" dangerouslySetInnerHTML={{ __html: item.content }} /> 
         </div>
         <div className="col-md-6">
               <img className="" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
         </div>
      </div>
      </div>
   </div>
</div>
</> : <>   

<AdminnineUpdate ids={item.homeimages_id} content={item.content}  heading={item.heading} cancel={editcancelnine} refresh={ ()=>homebg()}  />


</>}   
</> : null}
         </div>
      ))}



{/* -------------------------------------------------------------------------------------- */}
{ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
                {item.image_name === 'blog' ?<>

<div className="sectionseven">
   <div className="container">
      <div className="row">
      <div className="col-md-12">
                

                 {item.Active === 'yes' || item.Active === '' ?  <> 
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionsevenDisable('no','blog')}    className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p className="Adminbuttonlink2mmm"><span> This is section now visible on home page  </span></p>
               </>
            :
            <> 
               <p onClick={()=>SectionsevenDisable('yes','blog')}className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"><span> This is section now hidden on home page  </span></p>
            </>}
            </div>
      <div className="col-md-12">
      <h1  dangerouslySetInnerHTML={{ __html: item.content }} /> 
      </div>
      
      {isblogs.map((itemblogsss, index) => ( 
               <div key={itemblogsss.blog_id }   className={ "col-md-4 " + (item.Active === 'yes' || item.Active === '' ? " adminenables " : " admindisablsss")}> 
               <div className="sectionseven3">
                  <img src={BaseURLuploads+itemblogsss.image} alt="" />
                  <h3> {itemblogsss.title} </h3>
                  <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {itemblogsss.date} 
                                    </Moment> </p>
                  <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: itemblogsss.describtion }} /> 
                
                  <Link to={`/BlogPreview/${itemblogsss.blog_id}`}  className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
                   
                 
               </div>
               </div>
            ))}
     
      
      </div>
   </div>
</div>
</> : null}
         </div>
      ))}







</div>





   </>
  );
}

export default AdminHome;