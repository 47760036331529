import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminEventsrec() {
   const {alleventslistrec,homebgall,pinnedlist,unpinnedlist,checkpinall} = useContext(MyContext);

   useEffect( () => {
     homebg();
     window.scrollTo(0, 0);
  }, []);
 
 const [ishomebg, sethomebg] = useState([]);
 const homebg = async() =>{
 try {
        const homebgsss = await homebgall();
               if(homebgsss.success === 1){
                      console.log('homebg',homebgsss.homebglist);
                      sethomebg(homebgsss.homebglist);
        }
 } catch (error) { throw error;}
}
 const [pageNumber, setPageNumber] = useState(1);
 const observer = useRef()
 const loadingRef = useCallback(node => {
   
   if (observer.current) observer.current.disconnect()
   observer.current = new IntersectionObserver(entries => {
     if (entries[0].isIntersecting ) {
       setPageNumber(prevPageNumber => prevPageNumber + 1)
       //latestfeeds(prevPageNumber => prevPageNumber + 1)
     }
   })
   if (node) observer.current.observe(node)
 }, [])
 
 useEffect( () => {
   if (isFetching === true) return;
   allevents(pageNumber);
       return () => {
         console.log("This will be logged on unmount");
       }
 }, [pageNumber])

 const [isallevents, setallevents] = useState([]);
 const [isFetching, setIsFetching] = useState(false);

 const allevents = async(pageNumber) => {
   const alleventsqqq = await alleventslistrec(pageNumber);
   if(alleventsqqq.success === 1){
          console.log('allevents',alleventsqqq);
          setallevents(prevBooks => {
                 return [...prevBooks, ...alleventsqqq.data]
          })
       
          if(alleventsqqq.data.length < 1){
                 setIsFetching(true);
          }
   }
}
const pinned = async (pinnedids) => {
   try {
      
      const checkpinsss = await checkpinall();
      if(checkpinsss.success === 1){
         confirmAlert({
            title: 'You already pinned a post. Do you want to unpin that post and pin this post ',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => checkpinals(pinnedids)
              },
              {
                label: 'No',
              }
            ]
          })

      }else if(checkpinsss.success === 0){
         checkpin(pinnedids);
      }

   } catch (error) { throw error;}      
 
 }
 const checkpinals = async(pinnedids) =>{
   try {
      const unpinnedsss = await unpinnedlist(pinnedids);
      if(unpinnedsss.success === 1){            
        
         const pinnedsss = await pinnedlist(pinnedids);
         if(pinnedsss.success === 1){
            window.open(`/AdminEventsrec`, '_self');
         }
   }

   } catch (error) { throw error;}
  }
 const checkpin = async(pinnedids) =>{
   try {
      const pinnedsss = await pinnedlist(pinnedids);
      if(pinnedsss.success === 1){
         window.open(`/AdminEventsrec`, '_self');
      }
   } catch (error) { throw error;}
  }

 const unpinned = async (pinnedids) => {
   try {
      const unpinnedsss = await unpinnedlist(pinnedids);
      if(unpinnedsss.success === 1){
         window.open(`/AdminEventsrec`, '_self');
      }

   } catch (error) { throw error;}      
 
 }

  return (
   <>

{/* -------------------- --------------------------------------------------------------------------------------------------- */}

<div className="AdminEvents">
<Link to={`/AdminAddEventrec`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Create Recurring Events </span></Link>
<Link to={`/AdminEvents`} className="Adminbuttonlink2 Adminblogs4" style={{marginRight:'77px'}}><span> Events </span></Link>        
<div className="sectiontwo resss2">
   <div className="container">
      <div className="row">
     
         {isallevents.map((item, index) => ( 
         <div ref={loadingRef} key={item.Events_id}  className="col-md-12 events">
                
         <div className="col-md-2"style={{padding:0,overflow: "hidden"}}>
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-5" >
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
       
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 

               {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   
             </p>                  
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/> Recurring  {item.period} </p>
            : null }
         </div>
         </div>
         <div className="col-md-5 resss" style={{padding:0}}>
         <div className="col-md-12" style={{padding:0,marginBottom:"27px"}}>
                          
                          <Link to={`/AdminEventsPreview/${item.Events_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Preview </span></Link>
                          <Link to={`/AdminEventsEditrec/${item.Events_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></Link>
                          <Link to={`/AdminEventsDelete/${item.Events_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></Link>
                  {item.pin === 0 ? <>
                          <p onClick={()=>pinned(item.Events_id)} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','thumbtack']} /> Pin </span></p> </>
                  : <>
                           <p onClick={()=>unpinned(item.Events_id)}className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','thumbtack']} /> Unpin </span></p>
                 </> }
                   </div>
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
               : null}
            </div>
         ))}
</>
}
      
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
            <Link to={`/AdminOrganzorsupdate/${item.Events_id}`} className="buttonlink"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit Organizer Name & Image </span></Link>
         </div>
         </div>
     
         ))}
  

  </div>
   </div>
</div>
</div>

  


   </>
  );
}

export default AdminEventsrec;