import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sampleimgs from '../Img/default.jpg';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function WomensForum() {
   const {Breadcrumbslistall,WomensForumsssall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     WomensForumsss();
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_WomensForum);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [isabouts, setabouts] = useState([]);
 const WomensForumsss = async() =>{
 try {
        const aboutsss = await WomensForumsssall();
               if(aboutsss.success === 1){
                      console.log('WomensForumsss',aboutsss.WomensForumlist);
                      setabouts(aboutsss.WomensForumlist);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- --------------------------------------------------------------------------------------------------- */}
<div className="servicespage"> 
<div className="container">
      <div className="row">
         <div className="col-md-12">
{isabouts.map((item, index) => ( 
               <div  key={item.womensforum_id } className="col-md-4">
 <div className="servicespagelist">

{item.image1 !== '' ? 
            <img className="attachmentsssnewsss" src={BaseURLuploads+item.image1} alt="" />
:  <img className="attachmentsssnewsss" src={sampleimgs} alt="" />} 
         <h3 dangerouslySetInnerHTML={{ __html: item.heading1 }} /> 
        
         <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: item.text1 }} /> 
       
       <Link to={`/WomensPreview/${item.womensforum_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
       
       {/* <div className={ "table-header " + (item.image1 !== '' ? " servicespagelisttext " : "servicespagelisttext2 ")}>
       {item.image1 !== '' ? 
                     <img className="attachmentsss" src={BaseURLuploads+item.image1} alt="" />
       : null}
               <div  dangerouslySetInnerHTML={{ __html: item.text1 }} /> 
         </div> */}
         
         </div>
         </div>
    
))}  
 </div>
 </div>
 </div>
 </div>



   </>
  );
}

export default WomensForum;