
import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminEventsPreviewrec(props) {
   const {eventslistpreviewall,homebgall} = useContext(MyContext);

   useEffect( () => {
       eventslistpreview(props.match.params.Addpreview);
       homebg();
       window.scrollTo(0, 0);
  }, []);
 
  const [ishomebg, sethomebg] = useState([]);
  const homebg = async() =>{
  try {
         const homebgsss = await homebgall();
                if(homebgsss.success === 1){
                       console.log('homebg',homebgsss.homebglist);
                       sethomebg(homebgsss.homebglist);
         }
  } catch (error) { throw error;}
 }
  const [iseventslistpreview, seteventslistpreview] = useState([]);
  const eventslistpreview = async (ids) =>{
  try {
         const eventslistpreviewsss = await eventslistpreviewall(ids);
                if(eventslistpreviewsss.success === 1){
                       console.log('eventslistpreview',eventslistpreviewsss.eventslistpreviewlist);
                       seteventslistpreview(eventslistpreviewsss.eventslistpreviewlist);
         }
  } catch (error) { throw error;}
 }
  return (
   <>

<div className="adminEventspreviesss">
<div className="previewevents sectiontwo">
   <div className="container">
      <div className="row">
         
         
         {iseventslistpreview.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-12">
         <h2> {item.title} </h2>
              <img src={BaseURLuploads+item.image} className="attachment-full size-fullwww" alt=""  />                        
         </div>
         <div className="col-md-12 previewevents2">
         <div className="col-md-6 ">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
               : null}
            </div>
         ))}
</>
}
          
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            <p>Organizer</p>
         </div>
         <div className="col-md-6 ">
        
        
         <div className="block-ellipsis2">
       
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/>{item.location} </p>
           
         </div>
         </div>
         </div>
         <div className="col-md-12">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
        
         </div>
     
         ))}
  

  </div>
   </div>
</div>
</div>






   </>
  );
}

export default AdminEventsPreviewrec;