import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function PastorMessage() {
   const {Breadcrumbslistall,PasterMessageall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     PasterMessage();
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_msg);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [isPasterMessage, setPasterMessage] = useState([]);
 const PasterMessage = async() =>{
 try {
        const aboutsss = await PasterMessageall();
               if(aboutsss.success === 1){
                      console.log('PasterMessage',aboutsss.PasterMessagelist);
                      setPasterMessage(aboutsss.PasterMessagelist);
        }
 } catch (error) { throw error;}
}

const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;

  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Sec 1 --------------------------------------------------------------------------------------------------- */}
<div className="pastorspagesqqq" >
{isPasterMessage.map((item, index) => ( 
<div  key={item.pastermsg_id} >   
       {item.pm_sec1_code === 'AAA' ? <>
              {item.pm_sec1_active === 'yes' || item.pm_sec1_active === '' ?
                     <div className="pastorspages" >
                     <div className="container">
                            <div className="row">
                            <div className="col-md-12">
                            
                                   
                                   <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.pm_sec1_heading }} /> 
                                   <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.pastermsg_sec1 }} /> 
                            
                            </div>
                            </div>
                     </div>
                     </div>
              : null }
       </> : null }
</div>
      
))}
{/* -------------------- Sec 2 --------------------------------------------------------------------------------------------------- */}
{isPasterMessage.map((item, index) => ( 
<div  key={item.pastermsg_id} >   
       {item.pm_sec2_code === 'BBB' ? <>
              {item.pm_sec2_active === 'yes' || item.pm_sec2_active === '' ?
                     <div className="pastorspages pastorspages2" >
                     <div className="container">
                            <div className="row">
                            <div className="col-md-12" >
                                   <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.pm_sec2_heading }} /> 
                            </div>
                            <div className="col-md-5" >
                                   <img className="attachmentsss" src={BaseURLuploads+item.pastermsg_img} alt="" />
                            </div>
                            <div className="col-md-7">
                                   
                                   <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.pastermsg_sec2 }} /> 
                            
                            </div>
                            </div>
                     </div>
                     </div>
              : null }
       </> : null }
</div>
      
))}
{/* -------------------- Sec 3 --------------------------------------------------------------------------------------------------- */}
{isPasterMessage.map((item, index) => ( 
<div  key={item.pastermsg_id} >   
       {item.pm_sec3_code === 'CCC' ? <>
              {item.pm_sec3_active === 'yes' || item.pm_sec3_active === '' ?
                     <div className="pastorspages" >
                     <div className="container">
                            <div className="row">
                            <div className="col-md-12">
                            
                                   
                                   <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.pm_sec3_heading }} /> 
                                   {item.pastermsg_video.match(regExp) ? <> 
            {item.pastermsg_video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.pastermsg_video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
                                   <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.pastermsg_sec3 }} /> 
                            
                            </div>
                            </div>
                     </div>
                     </div>
              : null }
       </> : null }
</div>
      
))}

</div>


   </>
  );
}

export default PastorMessage;