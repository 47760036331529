import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdmingallcateEdittwo from './AdmingallcateEdittwo';
import {Link } from "react-router-dom"; 



function AdmingallerycategoryEdit(props) {
       

   const {galcatlistpreviewall} = useContext(MyContext);

   useEffect( () => {
     galcatlistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

 const [isbloglistpreview, setbloglistpreview] = useState([]);
 const galcatlistpreview = async (ids) =>{
 try {
        const bloglistpreviewsss = await galcatlistpreviewall(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('gallerycatgorylist',bloglistpreviewsss.ggglistpreviewlist);
                      setbloglistpreview(bloglistpreviewsss.ggglistpreviewlist);
        }
 } catch (error) { throw error;}
}



  return (
   <>
         {isbloglistpreview.map((item, index) => ( 
               <div  key={item.gallery_category_id } className="col-md-12">
                    
                 <AdmingallcateEdittwo galcategoryids={item.gallery_category_id} title={item.gallery_category_name}  />
                     
           </div>
          
            ))}
  




   </>
  );
}

export default AdmingallerycategoryEdit;