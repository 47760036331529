import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert'; 

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;


function AdminGalleryPreview(props) {
   const {GalleryPreviewlistall,deletegallerylist} = useContext(MyContext);

   useEffect( () => {
     GalleryPreviewlist(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);

 

 const [isGalleryPreview, setGalleryPreview] = useState([]);
 const GalleryPreviewlist = async (ids) =>{
 try {
        const GalleryPreviewlistqqq = await GalleryPreviewlistall(ids);
               if(GalleryPreviewlistqqq.success === 1){
                      console.log('GalleryPreview',GalleryPreviewlistqqq.GalleryPreviewlistsss);
                      setGalleryPreview(GalleryPreviewlistqqq.GalleryPreviewlistsss);
        }
 } catch (error) { throw error;}
}

const deletes = (blogids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deleteblogs(blogids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deleteblogs = async (blogids) => {
           
       try {
              const deleteblogssss = await deletegallerylist(blogids);
              if(deleteblogssss.success === 1){
                     setGalleryPreview([]);
                     GalleryPreviewlist(props.match.params.Addpreview);
     
              }
       } catch (error) { throw error;}      
     
     
     }

  return (
   <>

{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="admingallerysss">
<div className="gallerysss">
<Link to={`/AdminGalleryMultiple`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Add New Image </span></Link>
         <div className="col-md-12">
      
        
          {isGalleryPreview.map((item, index) => ( 
                  <div  key={item.galleryall_id } className="col-md-4" >
                          <p className="Adminbuttonlink2" onClick={()=>deletes(item.galleryall_id)}><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></p>
                  <img src={BaseURLuploads+item.galleryall_image} alt=""/>
                  </div>
            ))} 
                
        
                 
          
   </div>
</div>
</div>






   </>
  );
}

export default AdminGalleryPreview;