import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { useHistory } from "react-router-dom";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminEventsDelete(props) {
       const {eventslistpreviewall,homebgall,deleteEventslist} = useContext(MyContext);
       let history = useHistory();   
   useEffect( () => {
     window.scrollTo(0, 0);
     eventslistpreview(props.match.params.Addpreview);
     homebg();
  }, []);
 
  const [ishomebg, sethomebg] = useState([]);
  const homebg = async() =>{
  try {
         const homebgsss = await homebgall();
                if(homebgsss.success === 1){
                       console.log('homebg',homebgsss.homebglist);
                       sethomebg(homebgsss.homebglist);
         }
  } catch (error) { throw error;}
 }
  const [iseventslistpreview, seteventslistpreview] = useState([]);
  const eventslistpreview = async (ids) =>{
  try {
         const eventslistpreviewsss = await eventslistpreviewall(ids);
                if(eventslistpreviewsss.success === 1){
                       console.log('eventslistpreview',eventslistpreviewsss.eventslistpreviewlist);
                       seteventslistpreview(eventslistpreviewsss.eventslistpreviewlist);
         }
  } catch (error) { throw error;}
 }

const deletes = (blogids) => {
  confirmAlert({
    title: 'Confirm to Delete ',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => deleteblogs(blogids)
      },
      {
        label: 'No',
      }
    ]
  })
};     
const deleteblogs = async (blogids) => {
      
  try {
         const deleteblogssss = await deleteEventslist(blogids);
         if(deleteblogssss.success === 1){
              history.push(`/AdminEvents`);

         }
  } catch (error) { throw error;}      


}

  return (
   <>
<div className="adminEventspreviesss">
<Link to={`/AdminEvents`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>       
<div className="previewevents sectiontwo">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         
                     
                     {iseventslistpreview.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
                <p className="Adminbuttonlink2" onClick={()=>deletes(item.Events_id)}><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></p>
         <div className="col-md-12">
         <h2> {item.title} </h2>
              <img src={BaseURLuploads+item.image} className="attachment-full size-fullwww" alt=""  />                        
         </div>
         <div className="col-md-12 previewevents2">
         <div className="col-md-6 ">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
               : null}
            </div>
         ))}
</>
}
          
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            <p>Organizer</p>
         </div>
         <div className="col-md-6 ">
        
        
         <div className="block-ellipsis2">
       
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/>{item.location} </p>
           
         </div>
         </div>
         </div>
         <div className="col-md-12">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
        
         </div>
     
         ))}
  </div>
  </div>
   </div>
</div>
</div>
       
                    
  
  

   </>
  );
}

export default AdminEventsDelete;