import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function VideoGalleryPreview(props) {
   const {Breadcrumbslistall,VideogalleryPreviewall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
    videolistpreview(props.match.params.Addpreview);
   
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_Videogallery);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 

 const [isvideolistpreview, setvideolistpreview] = useState([]);
 const videolistpreview = async (ids) =>{
 try {
        const videolistpreviewsss = await VideogalleryPreviewall(ids);
               if(videolistpreviewsss.success === 1){
                      console.log('VideogalleryPreviewall',videolistpreviewsss.videolistpreviewlist);
                      setvideolistpreview(videolistpreviewsss.videolistpreviewlist);
        }
 } catch (error) { throw error;}
}
const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="videospreviews videos">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isvideolistpreview.map((item, index) => ( 
         <div key={item.Sermons_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-12">
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
        
         </div>
         <div className="col-md-12">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
           
            </div>
         </div>  
      ))}
  </div>
  </div>
   </div>
</div>






   </>
  );
}

export default VideoGalleryPreview;