import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Moment from 'react-moment';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Search(props) {
  
  const {ApiBlogAdslist,ApiEventsAdslist,ApiVideoAdslist,Apipageslist} = useContext(MyContext);
 
       
       const [issearchValue, setsearchValue] = useState({
              title: '',
       });
       const [issearchactivesss, setsearchactivesss] = useState(false);

       const handleOnChange = (e) => {
              setsearchactivesss(true);
              setsearchValue({
                     ...issearchValue,
                     [e.target.name]:e.target.value
              });
              if(isBlogAds === false && issearchValue.title !== '' ){ 
                     ApiBlogAds(issearchValue.title);
                     ApiEventsAds(issearchValue.title);
                     ApiVideoAds(issearchValue.title);
                     Apipages(issearchValue.title);
              }
              // if(ispeople === true && issearchValue.title !== '' ){ 
              //        ApiPeople(issearchValue.title);
              // }
             
          }
       const [isBlogAds, setisBlogAds] = useState(false);
     

       const [isBlogAdspreview, setisBlogAdspreview] = useState([]);
       const [isblogtitle, setblogtitle] = useState([]);
       const ApiBlogAds = async (searchValue) => {
              try {
                     const ApiBlogAdsss = await ApiBlogAdslist(searchValue);
                      if(ApiBlogAdsss.success === 1){
                            console.log('BlogAds',ApiBlogAdsss);
                             setisBlogAdspreview(ApiBlogAdsss.BlogAdsqqq);
                             setblogtitle(ApiBlogAdsss.BlogAdstitle);
                      }
              } catch (error) { throw error;}    
       }
       const [isEventsAdspreview, setisEventsAdspreview] = useState([]);
       const [iseventstitle, seteventstitle] = useState([]);
       const ApiEventsAds = async (searchValue) => {
              try {
                     const ApiEventsAdsss = await ApiEventsAdslist(searchValue);
                      if(ApiEventsAdsss.success === 1){
                            console.log('EventsAds',ApiEventsAdsss);
                             setisEventsAdspreview(ApiEventsAdsss.EventsAdsqqq);
                             seteventstitle(ApiEventsAdsss.eventsAdstitle);
                      }
              } catch (error) { throw error;}    
       }
  const [isVideoAdspreview, setisVideoAdspreview] = useState([]);
  const [isvideotitle, setvideotitle] = useState([]);
       const ApiVideoAds = async (searchValue) => {
              try {
                     const ApiVideoAdsss = await ApiVideoAdslist(searchValue);
                      if(ApiVideoAdsss.success === 1){
                            console.log('VideoAds',ApiVideoAdsss);
                             setisVideoAdspreview(ApiVideoAdsss.VideoAdsqqq);
                             setvideotitle(ApiVideoAdsss.videoAdstitle);
                      }
              } catch (error) { throw error;}    
       }
 const [ispagesteam, setispagesteam] = useState([]);
 const [ispagesgallery, setispagesgallery] = useState([]);
 const [ispagesabout, setispagesabout] = useState([]);
 const [ispageshome, setispageshome] = useState([]);
          
       const Apipages = async (searchValue) => {
              try {
                     const Apipagesss = await Apipageslist(searchValue);
                      if(Apipagesss.success === 1){
                            console.log('pages',Apipagesss);
                             setispagesteam(Apipagesss.pageslistteam);
                             setispagesgallery(Apipagesss.pageslistgallery);
                             setispagesabout(Apipagesss.pageslistabout);
                             setispageshome(Apipagesss.pageslisthome);
                      }
              } catch (error) { throw error;}    
       }
 


     
       const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;   
       
  return (
    // Pass on our props
    <div className="searchbar">
      
      <div className="container">
             <div className="row">
          
   
    <div className="col-md-2"> &nbsp; </div>      
    <div className="col-md-8">
<div className="searchbar2">
       <form className="">
              <input name="title"  type="text" placeholder="Search..."  onChange={handleOnChange}   value={issearchValue.title}
                />
           
              
       </form>
</div>
</div>                 
            
            
            
           
{issearchactivesss === true ? <>  
<div className="searchlistsss">

{/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
<div className="col-md-12 searchlistsss2">
<div className="searchheadingsss">
              <h1> Blog </h1>
       </div>
     
       {isBlogAdspreview.map((item, index) => ( 
               <div key={item.blog_id } className="col-md-3">
               <div className="sectionseven3">
                  <img src={BaseURLuploads+item.image} alt="" />
                  <h3> {item.title} </h3>
                  <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
              
                
                  <Link to={`/BlogPreview/${item.blog_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
                   
                 
               </div>
               </div>
       ))}
       <div className="col-md-12">
          {isBlogAdspreview.length === 0 ? <p> No results found </p> : <>
              {isBlogAdspreview.length > 5 ?
              <Link to={`/Blogsearchall/${isblogtitle}`} className="buttonlinksearsss"><span>  View All  </span></Link> 
              : null }
          </>}
       </div>

       <div className="searchheadingsss searchheadingsss2">
              <h1> Events </h1>
       </div>
       
 
       {isEventsAdspreview.map((item, index) => ( 
               <div key={item.Events_id}  className="col-md-6 ">
               <div className="events">
               <div className="col-md-5">
               <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
               </div>
               <div className="col-md-7">
               <h2>{item.title} </h2>
               
               <div className="block-ellipsis2">
             
                  <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
                  <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                             {item.date} 
                                          </Moment> </p>
                  <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
                  <div className="dgt-blog-readmore">
                  <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
                 </div>
               </div>
               </div>
              
               </div>
               </div>
       ))}
       
 <div className="col-md-12">
          {isEventsAdspreview.length === 0 ? <p> No results found </p> : <>
              {isEventsAdspreview.length > 5 ?
              <Link to={`/Eventsearchall/${iseventstitle}`} className="buttonlinksearsss"><span>  View All  </span></Link> 
              : null }
          </>}
       </div>


    <div className="searchheadingsss searchheadingsss2">
              <h1> Video </h1>
       </div>
       
 
       {isVideoAdspreview.map((item, index) => ( 
              <div key={item.Sermons_id} className="col-md-6 sectioneighttwosss ">
            
                
              {item.video.match(regExp) ? <> 
                 {item.video.match(regExp).length >= 2 ? <>      
                    <iframe id="ytplayer" type="text/html" className="videosss"
                       src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                              frameBorder="0" allowFullScreen></iframe> 
                 </>:null}
              </>:null}
             
              
                 <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
                 
                 <Link to={`/VideoPreview/${item.Sermons_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> More </span></Link>
               
                 
              </div>  
       ))}
       <div className="col-md-12">
          {isVideoAdspreview.length === 0 ? <p> No results found </p> : <>
              {isVideoAdspreview.length > 5 ?
              <Link to={`/Videosearchall/${isvideotitle}`} className="buttonlinksearsss"><span>  View All  </span></Link> 
              : null }
          </>}
       </div>
       


<div className="searchheadingsss searchheadingsss2">
              <h1> Pages </h1>
       </div>
       <div className="col-md-3">  
              <div className="searchheadingsss3">  
                     {ispageshome.length === 0 ? <p> No results found </p> : <> 
                            <h1> Home  </h1>
                            <div className="dgt-blog-readmore"> <Link to={`/`} className="link-btn">  View Page </Link></div>
                     </>}
              </div>
       </div> 
         <div className="col-md-3">  
              <div className="searchheadingsss3">  
                     {ispagesabout.length === 0 ? <p> No results found </p> : <> 
                            <h1> About  </h1>
                            <div className="dgt-blog-readmore"> <Link to={`/About`} className="link-btn">  View Page </Link></div>
                     </>}
              </div>
       </div>
        <div className="col-md-3">  
              <div className="searchheadingsss3">  
                     {ispagesteam.length === 0 ? <p> No results found </p> : <> 
                            <h1> Our Team  </h1>
                            <div className="dgt-blog-readmore"> <Link to={`/OurTeam`} className="link-btn">  View Page </Link></div>
                     </>}
              </div>
       </div>
       <div className="col-md-3">  
              <div className="searchheadingsss3">  
                     {ispagesgallery.length === 0 ? <p> No results found </p> : <> 
                            <h1> Gallery </h1>
                            <div className="dgt-blog-readmore"> <Link to={`/Gallery`} className="link-btn">  View Page </Link></div>
                     </>}
              </div>
       </div>
       

















</div>
</div>

</> : null }




    </div>
    </div>
    </div>
  );
};

export default Search;