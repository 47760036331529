import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertToRaw, ContentState,convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 

import {toast} from "react-toastify";


function AdminVidoEdittwo(props) {
       
  let history = useHistory();

   const {Videoupdatesall} = useContext(MyContext);

   useEffect( () => {
     window.scrollTo(0, 0);
  }, []);
 
  const [loadingadd, setloadingadd] = useState(false);
       let editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.description)
        ));
       const [description, setDescription] = useState(editorState);
const [userInfo, setuserInfo] = useState({
       title: props.title,
       video:props.video,
         
});

const html = '';
const contentBlock = htmlToDraft(html);
if (contentBlock) {
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //const editorState = EditorState.createWithContent(contentState);
  //setDescription(editorState);
}
//text editior
const onEditorStateChange = (editorState) => {
       setDescription(editorState);
     }
const onChangeValue = (e) => {
  
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 


const Videoupdates = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                    
                     const dataadd = await Videoupdatesall(userInfo,userInfo.description.value,props.videosids);
                     if(dataadd.success === 1){
                            console.log('blogupdatesall',dataadd);
                            //console.log(userInfo.description.value);
                            setloadingadd(false);
                            event.target.reset(); 
                            history.push(`/AdminVideo`);
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}
// {props.match.params.Add}




  return (
   <>
    <div className="bookads2 textalignblocksss">
    <Link to={`/AdminVideo`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>       
                      <div className="">
                             <div className="">
                                   
                                    <div className="col-md-12" style={{padding:0}}>
                                   
        
               <form onSubmit={Videoupdates} className="central-meta">
             
               <div className="clearfix"></div>
               <div className="form-row">
              
 
 
 
                   <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Title <span className="adverts-form-required"> * </span> </label>
                       <input type="text" name="title" value={userInfo.title} onChange={onChangeValue}  className="form-control" placeholder="Title" required />
                   </div>
                  <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Youtube Video Url <span className="adverts-form-required"> * </span> </label>
                       <input type="text" name="video" value={userInfo.video} onChange={onChangeValue}  className="form-control" placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxxx" required />
                   </div>
                 
              
                     <div className="form-group col-md-12 editor">
                     <label className="font-weight-bolds"> Description <span className="adverts-form-required"> * </span> </label>
                         <Editor
                             editorState={description}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChange}
                             
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.description = val}
                     
                      value={draftToHtml(convertToRaw(description.getCurrentContent())) }
                      />
                     </div>
   
                  
                  
          
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
               
                       <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                   </div> 
                    }
               
              
               </div>
               
           </form>
           </div>
 
 
   
           
           </div>
           </div>
 
 
 
 
 
           </div>



   </>
  );
}

export default AdminVidoEdittwo;