import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { useHistory } from "react-router-dom";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminBlogDelete(props) {
       const {bloglistpreviewall,deleteblogslist} = useContext(MyContext);
       let history = useHistory();   
   useEffect( () => {
     window.scrollTo(0, 0);
     bloglistpreview(props.match.params.Addpreview);
  }, []);
 
  const [isbloglistpreview, setbloglistpreview] = useState([]);
 const bloglistpreview = async (ids) =>{
 try {
        const bloglistpreviewsss = await bloglistpreviewall(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('bloglistpreview',bloglistpreviewsss.bloglistpreviewlist);
                      setbloglistpreview(bloglistpreviewsss.bloglistpreviewlist);
        }
 } catch (error) { throw error;}
}


const deletes = (blogids) => {
  confirmAlert({
    title: 'Confirm to Delete ',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => deleteblogs(blogids)
      },
      {
        label: 'No',
      }
    ]
  })
};     
const deleteblogs = async (blogids) => {
      
  try {
         const deleteblogssss = await deleteblogslist(blogids);
         if(deleteblogssss.success === 1){
              history.push(`/AdminBlog`);

         }
  } catch (error) { throw error;}      


}

  return (
   <>
<div className="adminblogssspreviesss">
<Link to={`/AdminBlog`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>   
<div className="blogssspreviesss blogsss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isbloglistpreview.map((item, index) => ( 
               <div  key={item.blog_id } className="col-md-12">
                     <p className="Adminbuttonlink2" onClick={()=>deletes(item.blog_id)}><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></p>
               <div className="sectionseven3">
               <h3> {item.title} </h3>
               <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
                  <img src={BaseURLuploads+item.image} alt="" />
                 
                 
                  <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: item.describtion }} /> 
                
                 
               </div>
               </div>
            ))}
  </div>
  </div>
   </div>
</div>
</div>
       
                    
  
  

   </>
  );
}

export default AdminBlogDelete;