import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from "react-router-dom";
import AdminEventsEdittworec from './AdminEventsEdittworec';
import {Link } from "react-router-dom"; 



function AdminEventsEditrec(props) {
       
  let history = useHistory();

   const {eventslistpreviewall} = useContext(MyContext);

   useEffect( () => {
       eventslistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

  const [iseventslistpreview, seteventslistpreview] = useState([]);
  const eventslistpreview = async (ids) =>{
  try {
         const eventslistpreviewsss = await eventslistpreviewall(ids);
                if(eventslistpreviewsss.success === 1){
                       console.log('eventslistpreview',eventslistpreviewsss.eventslistpreviewlist);
                       seteventslistpreview(eventslistpreviewsss.eventslistpreviewlist);
         }
  } catch (error) { throw error;}
 }


  return (
   <>
         {iseventslistpreview.map((item, index) => ( 
               <div  key={item.Events_id} className="col-md-12">
                    
                 <AdminEventsEdittworec eventsids={item.Events_id} title={item.title} description={item.description} location={item.location}  time={item.time}  date={item.date} lastdate={item.lastdate} period={item.period} />
                     
           </div>
          
            ))}
  




   </>
  );
}

export default AdminEventsEditrec;