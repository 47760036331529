import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { useHistory } from "react-router-dom";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Events() {
   let history = useHistory();
   const {Breadcrumbslistall,alleventslist,homebgall,eventsallrec,eventsallpin} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     homebg();
     eventsrec();
     eventspin();
  }, []);
  const [iseventsrec, seteventsrec] = useState([]);
   

  const eventsrec = async() =>{
  try {
         const eventsssrec = await eventsallrec();
                if(eventsssrec.success === 1){
                       console.log('eventsrec',eventsssrec.eventslistrec);
                       seteventsrec(eventsssrec.eventslistrec);
         }
  } catch (error) { throw error;}
}
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_Events);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [ishomebg, sethomebg] = useState([]);
 const homebg = async() =>{
 try {
        const homebgsss = await homebgall();
               if(homebgsss.success === 1){
                      console.log('homebg',homebgsss.homebglist);
                      sethomebg(homebgsss.homebglist);
        }
 } catch (error) { throw error;}
}
 const [pageNumber, setPageNumber] = useState(1);
 const observer = useRef()
 const loadingRef = useCallback(node => {
   
   if (observer.current) observer.current.disconnect()
   observer.current = new IntersectionObserver(entries => {
     if (entries[0].isIntersecting ) {
       setPageNumber(prevPageNumber => prevPageNumber + 1)
       //latestfeeds(prevPageNumber => prevPageNumber + 1)
     }
   })
   if (node) observer.current.observe(node)
 }, [])
 useEffect( () => {
   window.scrollTo(0, 0);
}, []); 
 useEffect( () => {
   if (isFetching === true) return;
   allevents(pageNumber);
       return () => {
         console.log("This will be logged on unmount");
       }
 }, [pageNumber])

 const [isallevents, setallevents] = useState([]);
 const [isFetching, setIsFetching] = useState(false);

 const allevents = async(pageNumber) => {
   const alleventsqqq = await alleventslist(pageNumber);
   if(alleventsqqq.success === 1){
          console.log('allevents',alleventsqqq);
          setallevents(prevBooks => {
                 return [...prevBooks, ...alleventsqqq.data]
          })
       
          if(alleventsqqq.data.length < 1){
                 setIsFetching(true);
          }
   }
}
const [iseventspin, seteventspin] = useState([]);
   const eventspin = async() =>{
   try {
          const eventssspin = await eventsallpin();
                 if(eventssspin.success === 1){
                        console.log('eventspin',eventssspin.eventslistpin);
                        seteventspin(eventssspin.eventslistpin);
          }
   } catch (error) { throw error;}
 }
 const categoryMode = (event) => {
   if(event.target.value === 'Newest')
   { 
      history.push(`/Events`);
   }
   if(event.target.value === 'Oldest')
   { 
      history.push(`/EventsOldest`);
   }

} 

  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- --------------------------------------------------------------------------------------------------- */}

<div className="sectiontwo resss2">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
           {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Event' ? 
                 <div dangerouslySetInnerHTML={{ __html: item.content }} />
               : null}
            </div>
         ))}
         </div>

         <div className="col-md-12"> 
            <select className="orderslistsss" onClick={categoryMode}>
            <option>Sort Events</option>
               <option value="Newest" >Newest</option>
               <option value="Oldest" >Oldest</option>
            </select>
         </div>
         {/* {iseventsrec.length > 0 ? 
            <div className="col-md-12 ">
               <Link to={`/Eventsrec`} className="link-btn evenasss" style={{marginBottom:"17px",textDecoration:"none"}}> Recurring Events </Link>
            </div>
         : null } */}
         {iseventspin.length > 0 ? <>   
         {iseventspin.map((item, index) => ( 
   <div key={item.Events_id}  className="col-md-12 events">
   <div className="col-md-3">
   <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
   </div>
   <div className="col-md-6">
   <h2>{item.title} </h2>
   <div className="block-ellipsis">
   <p dangerouslySetInnerHTML={{ __html: item.description }} />
   
   </div>
   <div className="block-ellipsis2">
 
      <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
      <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                 {item.date} 
                              </Moment>
                              {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
            <Moment format="MMM D, YYYY" withTitle>
                               {item.lastdate} 
            </Moment> 
           
      
       </> : null}   
        </p>
      <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
      {item.period !== '' ? 
         <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/> Recurring {item.period} </p>
      : null }
      <div className="dgt-blog-readmore">
      <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
     </div>
   </div>
   </div>
   <div className="col-md-3 resss">
   {item.organizer_image !== '' ?  
   <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
   : <> 
   {ishomebg.map((item, index) => ( 
      <div key={item.homeimages_id} >
         {item.image_name === 'Organizer' ? 
            <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
         : null}
      </div>
   ))}
</>
}
   {item.organizer_name !== '' ? 
      <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
      : <h6 className="sectiontwoqqq">Npcentre</h6> }
      <p>Organizer</p>
   </div>
   </div>
     
         ))}
         </> : null} 

         {isallevents.map((item, index) => ( 
         <div ref={loadingRef} key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3">
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
       
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment>
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   
              </p>
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/> Recurring {item.period} </p>
            : null }
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
               : null}
            </div>
         ))}
</>
}
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}
  

  </div>
   </div>
</div>

  


   </>
  );
}

export default Events;