import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 
import { EditorState, convertToRaw, ContentState,convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {toast} from "react-toastify";


function AdminpasterpagesUpdateCCC(props) {
  
  const {pasterpageupdatesallCCC} = useContext(MyContext);

  const [userInfo, setuserInfo] = useState({
       file:'',
       video:props.video
         
});
const onChangeValue = (e) => {
         
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 

  const [loadingadd, setloadingadd] = useState(false);

        let editorState = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.content)));
       const [heading, setheading] = useState(editorState);
       let editorStatetitle = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.heading)));
       const [headingtitle, setheadingtitle] = useState(editorStatetitle);
     

const html = '';
const contentBlock = htmlToDraft(html);
if (contentBlock) {
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
}
const onEditorStateChange = (editorState) => { setheading(editorState);}
const onEditorStateChangetitle = (editorStatetitle) => { setheadingtitle(editorStatetitle);}

const editcancel = () =>{
       props.cancel();
}
const aboutpageupdates = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                    
                     const dataadd = await pasterpageupdatesallCCC(userInfo,userInfo.heading.value,props.ids,userInfo.headingtitle.value);
                     if(dataadd.success === 1){
                            console.log('pasterpageupdatesall',dataadd);
                            setloadingadd(false);
                            event.target.reset(); 
                            props.cancel();
                            props.refresh();
                          
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}



  return (
   <>
     <div className="bookads2">
        <div className="col-md-12" style={{padding:0}}>
               <form onSubmit={aboutpageupdates} className="central-meta ">
               <div className="clearfix"></div>
               
                 
                     <div className="form-row">
                     <div className="form-group col-md-12 editor editorTitle textalignblocksss2">
                     <label className="font-weight-bold"> Title  </label>
                         <Editor
                             editorState={headingtitle}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChangetitle}
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.headingtitle = val}
                      value={draftToHtml(convertToRaw(headingtitle.getCurrentContent())) }
                      />
                     </div>

                     <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Youtube Video Url <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="video" value={userInfo.video} onChange={onChangeValue}  className="form-control" placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxxx" required />
                  </div>
                    <div className="form-group col-md-12 editor textalignblocksss4">
                    <label className="font-weight-bold"> Text </label>
                         <Editor
                             editorState={heading}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChange}
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.heading = val}
                      value={draftToHtml(convertToRaw(heading.getCurrentContent())) }
                      />
                     </div>
                     
                    

                     </div>
                 
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
                     <p onClick={editcancel} className="Adminbuttonlink2" style={{marginRight:"17px"}} ><span> <FontAwesomeIcon icon={['fas','edit']} />Cancel </span></p>
                     <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                      
                   </div> 
                    }
               
              
             
           </form>
           </div>
 
 
 
           </div>


   </>
  );
}

export default AdminpasterpagesUpdateCCC;