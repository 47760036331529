import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminBlogPreview(props) {
   const {bloglistpreviewall} = useContext(MyContext);

   useEffect( () => {
     bloglistpreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

 const [isbloglistpreview, setbloglistpreview] = useState([]);
 const bloglistpreview = async (ids) =>{
 try {
        const bloglistpreviewsss = await bloglistpreviewall(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('bloglistpreview',bloglistpreviewsss.bloglistpreviewlist);
                      setbloglistpreview(bloglistpreviewsss.bloglistpreviewlist);
        }
 } catch (error) { throw error;}
}
  return (
   <>

<div className="adminblogssspreviesss">
<Link to={`/AdminBlog`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>   
<div className="blogssspreviesss blogsss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isbloglistpreview.map((item, index) => ( 
               <div  key={item.blog_id } className="col-md-12">
                    
               <div className="sectionseven3">
               <h3> {item.title} </h3>
               <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
                  <img src={BaseURLuploads+item.image} alt="" />
                 
                 
                  <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: item.describtion }} /> 
                
                 
               </div>
               </div>
            ))}
  </div>
  </div>
   </div>
</div>
</div>






   </>
  );
}

export default AdminBlogPreview;