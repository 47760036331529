import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Moment from 'react-moment';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Videosearchall(props) {
  
  const {vodeosearchall} = useContext(MyContext);
 
  useEffect( () => {
      videosearchalllist(props.match.params.Addpreview);
       window.scrollTo(0, 0);
    }, []);
   
    

       const [isVideoAdspreview, setisVideoAdspreview] = useState([]);
       const videosearchalllist = async (searchValue) => {
              try {
                    
                     const ApiBlogAdsss = await vodeosearchall(searchValue);
                      if(ApiBlogAdsss.success === 1){
                            console.log('BlogAdsall',ApiBlogAdsss);
                             setisVideoAdspreview(ApiBlogAdsss.VideoAdsqqq);
                      }
              } catch (error) { throw error;}    
       }
     
       const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  
        
       
  return (
    // Pass on our props
    <div className="searchbar">
      
      <div className="container">
             <div className="row">
          
   
<div className="col-md-12 searchlistsss2">
<div className="searchheadingsss">
              <h1> Video </h1>
       </div>
     
       {isVideoAdspreview.map((item, index) => ( 
              <div key={item.Sermons_id} className="col-md-6 sectioneighttwosss ">
            
                
              {item.video.match(regExp) ? <> 
                 {item.video.match(regExp).length >= 2 ? <>      
                    <iframe id="ytplayer" type="text/html" className="videosss"
                       src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                              frameBorder="0" allowFullScreen></iframe> 
                 </>:null}
              </>:null}
             
              
                 <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
                 
                 <Link to={`/VideoPreview/${item.Sermons_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> More </span></Link>
               
                 
              </div>  
       ))}
       <div className="col-md-12">
          {isVideoAdspreview.length === 0 ? <p> No results found </p> : null}
       </div>

      



    </div>
    </div>
    </div>
    </div>
  );
};

export default Videosearchall;