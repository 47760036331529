import React,{useContext,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { WEB_URL_UPLOAD,CAPTCHA_SITE_KEY } from '../contexts/Apilinks';

const TEST_SITE_KEY = CAPTCHA_SITE_KEY;
const DELAY = 1500;
let BaseURLuploads = WEB_URL_UPLOAD;

function PrayerRequest() {
   const {Breadcrumbslistall,PrayerRequestadd} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_prayer);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }

const [value, setvalue] = useState("[empty]");
const [load, setload] = useState(false);
const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);
const [loadinglogin, setloadinglogin] = useState(false);
const _reCaptchaRef = React.createRef()
const [userInfo, setuserInfo] = useState({
  name: '',
  email: '',
  subject: '',
  request: '',
});
const onChangeValue = (e) => {
  setuserInfo({
      ...userInfo,
          [e.target.name]:e.target.value
  });
} 

useEffect( () => {
  setTimeout(() => {
    setload(true );
  }, DELAY);
  console.log("didMount - reCaptcha Ref-", _reCaptchaRef);
 }, []); 

 const handleChange = (value) => {
       setvalue(value);
      
     };
const asyncScriptOnLoad = () => {
  setrecaptchaLoaded(true);
  _reCaptchaRef.current.execute();
};

       const PrayerRequest = async (event) => {

              setloadinglogin(true);
              event.preventDefault();
              event.persist();
              const data = await PrayerRequestadd(userInfo,userInfo.recaptcha_response.value,isphonecountry);
                     console.log(data.memberid)
                     if(data.success === 1){
                            setloadinglogin(false);
                            event.target.reset(); 
                            toast.success( 'Prayer Request Sent Successfully.', {
                                   position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                   });  
                     
                     }
                     else{
                            setloadinglogin(false);
                            toast.error( data.msg, {
                            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                            });  
                     }

       }
      const [isphonecountry, setphonecountry] = useState('');      
      const handleOnChange =(phone)=>{
         setphonecountry(phone);
      }   

  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Our Team --------------------------------------------------------------------------------------------------- */}

<div className="prayerreqsss">
   <div className="container">
      <div className="row">
      <div className="col-md-1"> &nbsp; </div>
      <div className="col-md-8"> 
      <h5>  * indicates required </h5>
      <form onSubmit={PrayerRequest} className="login-forms">
             
             <div className="clearfix"></div>
         <div className="form-row">
         
             <div className="form-group col-md-12">
             <label className="font-weight-bolds "> Your Name  <span className="adverts-form-required"> *  </span> </label>
                 <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}className="form-control" placeholder="" required   />
                
             </div>
             <div className="form-group col-md-12">
             <label className="font-weight-bolds "> Your Email <span className="adverts-form-required"> * </span>  </label>
                 <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}className="form-control" placeholder="" required />
             </div>
              <div className=" col-md-12">
                <label className="font-weight-bolds "> Your Phone   </label>
              </div>
                          <PhoneInput
                          inputExtraProps={{
                            name: "phone",
                            autoFocus: true
                          }}
                country={'ca'}
                value={isphonecountry}
                onChange={handleOnChange}
              />
             <div className="form-group col-md-12">
             <label className="font-weight-bolds "> Prayer Subject  <span className="adverts-form-required"> * </span> </label>
                 <input type="subject" name="subject" value={userInfo.subject} onChange={onChangeValue}className="form-control" placeholder=" "  required />
             </div>
            
             <div className="form-group col-md-12">
               
                 <label className="font-weight-bolds "> Prayer Request  <span className="adverts-form-required"> * </span> </label>
                 <textarea  name="request" value={userInfo.request} className="form-control " rows="5"  placeholder=""  onChange={onChangeValue} required/>
             </div>
             
            
        
             <input type="hidden" name="recaptcha_response" ref={(val) => userInfo.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

             {load && (
       <ReCAPTCHA
         style={{ display: "inline-block" }}
         theme="dark"
         size="invisible"
         ref={_reCaptchaRef}
         sitekey={TEST_SITE_KEY}
         onChange={handleChange}
         asyncScriptOnLoad={asyncScriptOnLoad}
       />
     )}

             <div className="form-group col-md-12 ">
             {loadinglogin ?
                         <>
                                <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                         </>
                      :  <>
                         <button type="submit" className="btn btn-style-two buttonlink" disabled={!recaptchaLoaded}> Send </button>
                   </>
             }
             </div>
            
         </div>
        
     </form>  
     </div>

  </div>
   </div>
</div> 


   </>
  );
}

export default PrayerRequest;