import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminServicesall() {

  return (
   <div className="adminservicesorders">
<Link to={`/AdminFacilities`} className="Adminbuttonlink2 "><span>  Facilities </span></Link>
<Link to={`/AdminChurchChoir`} className="Adminbuttonlink2 "><span>  Church Choir </span></Link>
<Link to={`/AdminSundaySchool`} className="Adminbuttonlink2 "><span>  Sunday School </span></Link>

<Link to={`/AdminWomensForum`} className="Adminbuttonlink2 "><span>  Womens Forum </span></Link>
<Link to={`/AdminSocialWelfare`} className="Adminbuttonlink2 "><span>  Social Welfare </span></Link>

   </div>
  );
}

export default AdminServicesall;