import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminVideogallery() {
   const {allVideolistgallery} = useContext(MyContext);

 
 const [pageNumber, setPageNumber] = useState(1);
 const observer = useRef()
 const loadingRef = useCallback(node => {
   
   if (observer.current) observer.current.disconnect()
   observer.current = new IntersectionObserver(entries => {
     if (entries[0].isIntersecting ) {
       setPageNumber(prevPageNumber => prevPageNumber + 1)
       //latestfeeds(prevPageNumber => prevPageNumber + 1)
     }
   })
   if (node) observer.current.observe(node)
 }, [])
 useEffect( () => {
   window.scrollTo(0, 0);
}, []); 
 useEffect( () => {
   if (isFetching === true) return;
   allVideo(pageNumber);
       return () => {
         console.log("This will be logged on unmount");
       }
 }, [pageNumber])

 const [isallVideosss, setallVideosss] = useState([]);
 const [isFetching, setIsFetching] = useState(false);

 const allVideo = async(pageNumber) => {
   const allVideosss = await allVideolistgallery(pageNumber);
   if(allVideosss.success === 1){
          console.log('allVideo',allVideosss);
          setallVideosss(prevBooks => {
                 return [...prevBooks, ...allVideosss.data]
          })
       
          if(allVideosss.data.length < 1){
                 setIsFetching(true);
          }
   }
}
const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
  return (
   <>


<div className="Adminvideos">
       
<Link to={`/AdminAddVideogallery`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Add Youtube Video </span></Link>
<div className="videos">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isallVideosss.map((item, index) => ( 
         <div ref={loadingRef} key={item.videos_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-6" style={{padding:0}}>
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
        
         </div>
        
            <div className="col-md-6">
                          
                        
                          <Link to={`/AdminVidoEditgallery/${item.videos_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></Link>
                          <Link to={`/AdminVidoDeletegallery/${item.videos_id}`} className="Adminbuttonlink2" ><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></Link>
                 
              <div className="col-md-12" style={{padding:0,marginTop:"27px"}}>
                     <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
                     <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
              
              </div>
              </div>
         </div>  
      ))}
  </div>
  </div>
   </div>
</div>
</div>

  


   </>
  );
}

export default AdminVideogallery;