import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import AdminbannerEdittwo from './AdminbannerEdittwo';



function AdminSliderEdit(props) {
       

   const {bannerimgalleditlist} = useContext(MyContext);

   useEffect( () => {
       bannerimg(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 

 const [isbannerimgsss, setbannerimgsss] = useState([]);
 const bannerimg = async (ids) =>{
 try {
        const bloglistpreviewsss = await bannerimgalleditlist(ids);
               if(bloglistpreviewsss.success === 1){
                      console.log('bannerimg',bloglistpreviewsss.bannerimglist);
                      setbannerimgsss(bloglistpreviewsss.bannerimglist);
        }
 } catch (error) { throw error;}
}



  return (
   <>
       {isbannerimgsss.map((item, index) => ( 
             <div  key={item.banner_id} className="bannerimgsss">
                    
                 <AdminbannerEdittwo bannerids={item.banner_id} bannertext1={item.bannertext_1} bannertext2={item.bannertext_2} bannertext3={item.bannertext_3} />
                     
           </div>
          
            ))}
  




   </>
  );
}

export default AdminSliderEdit;