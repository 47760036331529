import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 

import {toast} from "react-toastify";


function AdmingalleryAdd(props) {

  let history = useHistory();

       
       const {Gallerysssall,galleryAddall} = useContext(MyContext);
       
       const [loadingadd, setloadingadd] = useState(false);
      
       const [userInfo, setuserInfo] = useState({
              category: '',
              file:'',
                
       });
     
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);

       useEffect( () => {
        window.scrollTo(0, 0);
        Gallerysss()
           }, []); 
           const [isallgallery, setallgallery] = useState([]);

           const Gallerysss = async() => {
             const Gallerysssqqq = await Gallerysssall();
             if(Gallerysssqqq.success === 1){
                    console.log('Gallerysssqqq',Gallerysssqqq.Galleryssslist);
                    setallgallery(Gallerysssqqq.Galleryssslist);
                 
             }
          }


    
       const onChangeValue = (e) => {
         
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 


       const galleryAddcategory = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                              toast.error(invalidImage, {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }
                           
                            const dataadd = await galleryAddall(userInfo);
                            if(dataadd.success === 1){
                                   console.log('galleryAddcategorysss',dataadd);
                                   //console.log(userInfo.description.value);
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   history.push(`/AdminGallery`);
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 600;
                var MAX_HEIGHT = 600;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
             
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 








        return(
               <div className="bookads2">
                      
                     <div className="">
                            <div className="">
                                  
                                   <div className="col-md-8" style={{padding:0}}>
                                  
       
              <form onSubmit={galleryAddcategory} className="central-meta">
            
              <div className="clearfix"></div>
              <div className="form-row">
             


             
                     
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Category Name <span className="adverts-form-required"> * </span> </label>
                      <select  name="category" value={userInfo.category} onChange={onChangeValue} className="wqsss form-control"  required>
                      <option value=""> Choose the field </option>
                      {isallgallery.map((item, index) => ( 
                              <option key={item.gallery_category_id }> {item.gallery_category_name }</option>
                      ))}
                              
                            </select>
                  </div>
                
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Image <span className="adverts-form-required"> * </span> </label>
                      <div className="clearfix"></div>
                      <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   required/>
                     
                  </div>
                  {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }
                 
                
              {isallgallery.length > 0 ? <>   
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                     <div className="form-group col-sm-12 text-right">
              
                            <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                            
                     </div> 
                   }
              </> : null }
             
              </div>
              
          </form>
          </div>


  
          
          </div>
          </div>





          </div>
        );
    }

export default AdmingalleryAdd;