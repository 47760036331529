import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Moment from 'react-moment';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Eventsearchall(props) {
  
  const {eventssearchall} = useContext(MyContext);
 
  useEffect( () => {
      eventsearchalllist(props.match.params.Addpreview);
       window.scrollTo(0, 0);
    }, []);
   
    

       const [isEventsAdspreview, setisEventsAdspreview] = useState([]);
       const eventsearchalllist = async (searchValue) => {
              try {
                    
                     const ApiBlogAdsss = await eventssearchall(searchValue);
                      if(ApiBlogAdsss.success === 1){
                            console.log('BlogAdsall',ApiBlogAdsss);
                            setisEventsAdspreview(ApiBlogAdsss.EventsAdsqqq);
                      }
              } catch (error) { throw error;}    
       }
     
     
        
       
  return (
    // Pass on our props
    <div className="searchbar">
      
      <div className="container">
             <div className="row">
          
   
<div className="col-md-12 searchlistsss2">
<div className="searchheadingsss">
              <h1> Events </h1>
       </div>
     
       {isEventsAdspreview.map((item, index) => ( 
               <div key={item.Events_id}  className="col-md-6 ">
               <div className="events">
               <div className="col-md-5">
               <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
               </div>
               <div className="col-md-7">
               <h2>{item.title} </h2>
               
               <div className="block-ellipsis2">
             
                  <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
                  <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                             {item.date} 
                                          </Moment> </p>
                  <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
                  <div className="dgt-blog-readmore">
                  <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
                 </div>
               </div>
               </div>
              
               </div>
               </div>
       ))}
  <div className="col-md-12">
          {isEventsAdspreview.length === 0 ? <p> No results found </p> : null}
       </div>
      



    </div>
    </div>
    </div>
    </div>
  );
};

export default Eventsearchall;