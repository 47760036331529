import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function EventsPreview(props) {
   const {Breadcrumbslistall,eventslistpreviewall,homebgall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     eventslistpreview(props.match.params.Addpreview);
     homebg();
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_Events);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [ishomebg, sethomebg] = useState([]);
 const homebg = async() =>{
 try {
        const homebgsss = await homebgall();
               if(homebgsss.success === 1){
                      console.log('homebg',homebgsss.homebglist);
                      sethomebg(homebgsss.homebglist);
        }
 } catch (error) { throw error;}
}
 const [iseventslistpreview, seteventslistpreview] = useState([]);
 const eventslistpreview = async (ids) =>{
 try {
        const eventslistpreviewsss = await eventslistpreviewall(ids);
               if(eventslistpreviewsss.success === 1){
                      console.log('eventslistpreview',eventslistpreviewsss.eventslistpreviewlist);
                      seteventslistpreview(eventslistpreviewsss.eventslistpreviewlist);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="previewevents sectiontwo">
   <div className="container">
      <div className="row">
         
         
         {iseventslistpreview.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-12">
         <h2> {item.title} </h2>
              <img src={BaseURLuploads+item.image} className="attachment-full size-fullwww" alt=""  />                        
         </div>
         <div className="col-md-12 previewevents2">
         <div className="col-md-6 ">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
               : null}
            </div>
         ))}
</>
}
            {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
           
            <p>Organizer</p>
         </div>
         <div className="col-md-6 ">
        
        
         <div className="block-ellipsis2">
       
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/><Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   
              </p>
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/>{item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/>Recurring  {item.period} </p>
            : null }
           
         </div>
         </div>
         </div>
         <div className="col-md-12">
         <p className="mnzxsss" dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
        
         </div>
     
         ))}
  

  </div>
   </div>
</div>






   </>
  );
}

export default EventsPreview;