import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Videolives(props) {
   const {VideoLiveall} = useContext(MyContext);

   useEffect( () => {
    
    videolistlive();
   
     window.scrollTo(0, 0);
  }, []);
 
  
 

 const [isvideolistpreview, setvideolistpreview] = useState([]);
 const videolistlive = async (ids) =>{
 try {
        const videolistpreviewsss = await VideoLiveall(ids);
               if(videolistpreviewsss.success === 1){
                      console.log('videolistpreview',videolistpreviewsss.videolistlivelist);
                      setvideolistpreview(videolistpreviewsss.videolistlivelist);
        }
 } catch (error) { throw error;}
}
const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
  return (
   <>

{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="videospreviews videos">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isvideolistpreview.map((item, index) => ( 
         <div key={item.videolive_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-12">
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
        
         </div>
         <div className="col-md-12">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
           
            </div>
         </div>  
      ))}
  </div>
  </div>
   </div>
</div>






   </>
  );
}

export default Videolives;