import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function SundaysPreview(props) {
   const {Breadcrumbslistall,Sundayallpreview} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     Sundaypreview(props.match.params.Addpreview);
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_SocialWelfare);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [isabouts, setabouts] = useState([]);
 const [isheadingsss, setheadingsss] = useState([]);
 const Sundaypreview = async(ids) =>{
 try {
        const aboutsss = await Sundayallpreview(ids);
               if(aboutsss.success === 1){
                      console.log('Sunday',aboutsss.SocialWelfarelist);
                      setabouts(aboutsss.SocialWelfarelist);
                      setheadingsss(aboutsss.SocialWelfarelist[0].heading1);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
        <h1> {isheadingsss} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- =--------------------------------------------------------------------------------------------------- */}
<div className="servicespagePreviews"> 
<div className="container">
      <div className="row">
         <div className="col-md-12">
{isabouts.map((item, index) => ( 
               <div  key={item.socialwelfare_id } className=" col-md-12 ">
 <div className="servicespagelist">
     
                  <h3 dangerouslySetInnerHTML={{ __html: item.heading1 }} /> 
      <div className={ "table-header " + (item.image1 !== '' ? " servicespagelisttext " : "servicespagelisttext2 ")}>
       {item.image1 !== '' ? 
                     <img className="attachmentsss" src={BaseURLuploads+item.image1} alt="" />
       : null}
               <div  dangerouslySetInnerHTML={{ __html: item.text1 }} /> 
         </div> 
         
         </div>
         </div>
    
))}  
 </div>
 </div>
 </div>
 </div>



   </>
  );
}

export default SundaysPreview;