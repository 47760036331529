import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { useHistory } from "react-router-dom";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminVidoDeletegallery(props) {
       const {VideogalleryPreviewall,deletevideolistgallery} = useContext(MyContext);
       let history = useHistory();   
   useEffect( () => {
     window.scrollTo(0, 0);
     videolistpreview(props.match.params.Addpreview);
  }, []);
 
  const [isvideolistpreview, setvideolistpreview] = useState([]);
 const videolistpreview = async (ids) =>{
 try {
        const videolistpreviewsss = await VideogalleryPreviewall(ids);
               if(videolistpreviewsss.success === 1){
                      console.log('videolistpreview',videolistpreviewsss.videolistpreviewlist);
                      setvideolistpreview(videolistpreviewsss.videolistpreviewlist);
        }
 } catch (error) { throw error;}
}
const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;

const deletes = (blogids) => {
  confirmAlert({
    title: 'Confirm to Delete ',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => deleteblogs(blogids)
      },
      {
        label: 'No',
      }
    ]
  })
};     
const deleteblogs = async (blogids) => {
      
  try {
         const deleteblogssss = await deletevideolistgallery(blogids);
         if(deleteblogssss.success === 1){
              history.push(`/AdminVideogallery`);

         }
  } catch (error) { throw error;}      


}

  return (
   <>
<div className="Adminvideosss">
<Link to={`/AdminVideogallery`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>  
<div className="videospreviews videos">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isvideolistpreview.map((item, index) => ( 
         <div key={item.videos_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-12">
         <p className="Adminbuttonlink2" onClick={()=>deletes(item.videos_id)}><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete </span></p>
         <br />
         <br />
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
        
         </div>
         <div className="col-md-12">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
           
            </div>
         </div>  
      ))}
  </div>
  </div>
   </div>
</div>

</div>
       
                    
  
  

   </>
  );
}

export default AdminVidoDeletegallery;