import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { confirmAlert } from 'react-confirm-alert'; 
import AdminTeamEdit from './AdminTeamEdit';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminServiceTime() {
   const {menuslistall,deleteTeamlist} = useContext(MyContext);

   useEffect( () => {
       menus();
     window.scrollTo(0, 0);
  }, []);
 
  const [ismenulist, setmenulist] = useState([]);
  const [isservicestime, setservicestime] = useState([]);
  const menus = async() =>{
  try {
         const menusresult = await menuslistall();
                if(menusresult.success === 1){
                       console.log('servicestime',menusresult.menulist);
                     setmenulist(menusresult.menulist);
                     setservicestime(menusresult.servicestime);
         }
  } catch (error) { throw error;}
}

const deletes = (blogids) => {
   confirmAlert({
     title: 'Confirm to Delete ',
     message: 'Are you sure to do this.',
     buttons: [
       {
         label: 'Yes',
         onClick: () => deleteblogs(blogids)
       },
       {
         label: 'No',
       }
     ]
   })
 };     
 const deleteblogs = async (blogids) => {
       
   try {
          const deleteblogssss = await deleteTeamlist(blogids);
          if(deleteblogssss.success === 1){
            
 
          }
   } catch (error) { throw error;}      
 
 
 }
  return (
   <>

   
<div className="Adminservisestimes">

<div className="">

{ismenulist.map((item, index) => ( 
              <div  key={item.menu_id} className="headerlefttimes2" >
                {item.menus === 'Church Service Time' ? <>
               
                    <div className="headerlefttimesqqq">
                    {isservicestime.map((itemsqltime, index) => ( 
                       <div  key={itemsqltime.serviessubtime_id}className="col-md-12 mqs2" style={{padding:0}}>
                         <div className="col-md-12 " style={{padding:0}}>
                            <h4  className="headerlefttimes3" >  <span className=""> {itemsqltime.days} </span>
                            </h4>
                            </div> 
                            <div className="col-md-9" style={{padding:0}}>
                            <p className="headerlefttimes3" > 
                            <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.nameone} </b> :  {itemsqltime.timeone} 
                            </span>
                            <br />
                            {itemsqltime.timetwo !== '' ? <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.nametwo} </b> :  {itemsqltime.timetwo} 
                            </span> : null}
                            {itemsqltime.timethree !== '' ? <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.namethree} </b> :  {itemsqltime.timethree} 
                            </span> : null}
                            
                            
                            </p>
                            </div> 
                            <div className="col-md-3 mbq" style={{padding:0}}>
                            <Link to={`/AdminserviestimeEdit/${itemsqltime.serviessubtime_id}`} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></Link>
                            </div> 
                        </div> 
                      ))}              
                      </div> 
              </>
              : null}
              </div>
            ))}




</div> 
</div> 


   </>
  );
}

export default AdminServiceTime;