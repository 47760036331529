import React,{useContext,useState,useEffect,useRef} from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { MyContext } from '../contexts/MyContext';
import Carousel, {autoplayPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Home() {
   const {bannerimgall,sectiononeall,Sermonsall,homebgall,eventsall,eventsallOldest,eventsallrec,aboutsall,blogsall,menuslistall,eventsallnew,eventsallpin} = useContext(MyContext);

   useEffect( () => {
     bannerimg();
     sectiononelist();
     Sermons();
     homebg();
     events();
     eventspin();
     eventsrec();
     abouts();
     blogs();
     menus();
     window.scrollTo(0, 0);
     seteventactive(false);
     eventsOldest();
     eventsnew();
  }, []);
 
  const [ismenulist, setmenulist] = useState([]);
  const [isserviceslist, setserviceslist] = useState([]);
  const menus = async() =>{
   try {
          const menusresult = await menuslistall();
                 if(menusresult.success === 1){
                        console.log(menusresult.menulist);
                        setmenulist(menusresult.menulist);
                        setserviceslist(menusresult.serviceslist);
          }
   } catch (error) { throw error;}
 }

  const [isbannerimg, setbannerimg] = useState([]);
   const bannerimg = async() =>{
   try {
          const bannerimgresult = await bannerimgall();
                 if(bannerimgresult.success === 1){
                        console.log('banner',bannerimgresult.bannerlist);
                        setbannerimg(bannerimgresult.bannerlist) ;
          }
   } catch (error) { throw error;}
 }
 const [issectionone, setsectionone] = useState([]);
 const [issectiononebg, setsectiononebg] = useState([]);
 const [issectiononebgtwo, setsectiononebgtwo] = useState([]);
 const [issectiononeactive, setsectiononeactive] = useState([]);
   const sectiononelist = async() =>{
   try {
          const sectiononelistqqq = await sectiononeall();
                 if(sectiononelistqqq.success === 1){
                        console.log('sectionone',sectiononelistqqq.sectiononelistall);
                        setsectionone(sectiononelistqqq.sectiononelistall) ;
                        setsectiononebg(sectiononelistqqq.sectiononelistall[0].bg) ;
                        setsectiononebgtwo(sectiononelistqqq.sectiononelistall[0].bg2) ;
                        setsectiononeactive(sectiononelistqqq.sectiononelistall[0].Active);
          }
   } catch (error) { throw error;}
 }
   
const [isSermons, setSermons] = useState([]);
   const Sermons = async() =>{
   try {
          const Sermonswww = await Sermonsall();
                 if(Sermonswww.success === 1){
                        console.log('Sermons',Sermonswww.Sermonslist);
                        setSermons(Sermonswww.Sermonslist);
                      
          }
   } catch (error) { throw error;}
 }
const [ishomebg, sethomebg] = useState([]);
   const homebg = async() =>{
   try {
          const homebgsss = await homebgall();
                 if(homebgsss.success === 1){
                        console.log('homebg',homebgsss.homebglist);
                        sethomebg(homebgsss.homebglist);
          }
   } catch (error) { throw error;}
 }
const [iseventspin, seteventspin] = useState([]);
   const eventspin = async() =>{
   try {
          const eventssspin = await eventsallpin();
                 if(eventssspin.success === 1){
                        console.log('eventspin',eventssspin.eventslistpin);
                        seteventspin(eventssspin.eventslistpin);
          }
   } catch (error) { throw error;}
 }
 const [isevents, setevents] = useState([]);
   const events = async() =>{
   try {
          const eventsss = await eventsall();
                 if(eventsss.success === 1){
                        console.log('events',eventsss.eventslist);
                        setevents(eventsss.eventslist);
          }
   } catch (error) { throw error;}
 }
 const [iseventsnew, seteventsnew] = useState([]);
   const eventsnew = async() =>{
   try {
          const eventsssnew = await eventsallnew();
                 if(eventsssnew.success === 1){
                        console.log('eventsssnew',eventsssnew.eventslistnew);
                        seteventsnew(eventsssnew.eventslistnew);
          }
   } catch (error) { throw error;}
 }
 const [iseventsOldest, seteventsOldest] = useState([]);
   const eventsOldest = async() =>{
   try {
          const eventsssOldest = await eventsallOldest();
                 if(eventsssOldest.success === 1){
                        console.log('eventsOldest',eventsssOldest.eventslistOldest);
                        seteventsOldest(eventsssOldest.eventslistOldest);
          }
   } catch (error) { throw error;}
 }
 const [iseventactive, seteventactive] = useState(false);
 const Eventsactives = async() =>{
   seteventactive(true);
 }
 const Eventsactivestwo = async() =>{
   seteventactive(false);
 }
 const [iseventsrec, seteventsrec] = useState([]);
   

   const eventsrec = async() =>{
   try {
          const eventsssrec = await eventsallrec();
                 if(eventsssrec.success === 1){
                        console.log('eventsrec',eventsssrec.eventslistrec);
                        seteventsrec(eventsssrec.eventslistrec);
          }
   } catch (error) { throw error;}
 }
const [isabouts, setabouts] = useState([]);
   const abouts = async() =>{
   try {
          const aboutsss = await aboutsall();
                 if(aboutsss.success === 1){
                        console.log('abouts',aboutsss.aboutslist);
                        setabouts(aboutsss.aboutslist);
          }
   } catch (error) { throw error;}
 }
const [isblogs, setblogs] = useState([]);
   const blogs = async() =>{
   try {
          const blogsss = await blogsall();
                 if(blogsss.success === 1){
                        console.log('blogs',blogsss.blogslist);
                        setblogs(blogsss.blogslist);
          }
   } catch (error) { throw error;}
 }
 const myRef = useRef(null)
 const executeScroll = () => { 
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   
 }
 const externallinks = (url) => {
   if (!!url && !!url.trim()) { 
          url = url.trim(); 
          if (!/^(https?:)?\/\//i.test(url)) { 
              url = 'http://' + url; 
              window.open(url, '_blank');
          }else{
                 window.open(url, '_blank');
          }
      } 
 }  
 const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
 const [isNewest, setNewest] = useState(false);
 const [isOldest, setOldest] = useState(false);
 const [issort, setsort] = useState(true);
 const categoryMode = (event) => {
   if(event.target.value === 'Newest')
   { 
      setsort(false);
      setNewest(true);
      setOldest(false);
   }
   if(event.target.value === 'Oldest')
   { 
      setNewest(false);
      setOldest(true);
      setsort(false);
   }

} 


// const currentDate = new Date('Fri Jan 29 2021 00:00:00 GMT-0500 (Eastern Standard Time)');

// const currentDayOfMonth = currentDate.getDate();
// const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
// const currentYear = currentDate.getFullYear();

// const dateString = currentDayOfMonth + "-" + (currentMonth + 1) + "-" + currentYear;

// console.log('xxxxxxxxyyy',dateString)

  return (
   <>
<div className="banner">
   <div className="container2">
      <div className="row2">
      <Carousel slidesPerScroll={1} slidesPerPage={1}   >
           
           {isbannerimg.map((item, index) => ( 
                             
               <div  key={item.banner_id} className="bannerimgsss">
                  <img src={BaseURLuploads+item.slider} className=""/>
                  <div className="banners2">
                  {item.bannertext_1 !== '' ?  <h1> {item.bannertext_1}</h1> : <h1> &nbsp; </h1>}
                  {item.bannertext_2 !== '' ?  <h1> {item.bannertext_2}</h1> : <h1> &nbsp; </h1>}
                  {item.bannertext_3 !== '' ?  <p> {item.bannertext_3}</p> : <p> &nbsp; </p>}
                  {item.bannertext_button !== '' ?  <h6 onClick={executeScroll} style={{cursor:"pointer"}}> <FontAwesomeIcon icon={['fas','arrow-down']} className="bounce" /> </h6> : <h6> &nbsp; </h6>}
                  </div>
               </div> 
           ))}</Carousel>
      </div>
   </div>
</div>
{/* -------------------------------------------------------------------------------------- */}
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'pastor' ? <>
         {item.Active === 'yes' || item.Active === '' ?    <>   
            <div className="sectionpastor" >
               <div className="container">
                  <div className="row">
                     
                  {item.images !== '' ?<>
                           <div className="col-md-3"> 
                              <img className="attachmentsss" src={BaseURLuploads+item.images} alt="" />
                           </div>
                        </>
                        : null}
                    <div class="col">
                        <div class="selectionemptsss"></div>
                     </div>
                     <div className={ " " + (item.images !== '' ? " col-md-8 " : "col-md-12 ")}>
                        <h1 className="sectionpastor3" dangerouslySetInnerHTML={{ __html: item.heading }} />
                        <div className="sectionpastor2" dangerouslySetInnerHTML={{ __html: item.content }} />
                     </div>
                     
                    

            </div>
            </div>
            </div>
         </> : null}
      </> : null}
    </div>
))}


{/* -------------------------------------------------------------------------------------- */}
{issectiononeactive === 'yes' || issectiononeactive === '' ?
<div className="Sectionone" style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>
<img className="p_absoulte" src={BaseURLuploads+issectiononebgtwo} alt={issectiononebgtwo} />
   <div className="container">
      <div className="row">
{issectionone.map((item, index) => ( 
   <div  key={item.hom_sec_oneid} className="">
       <img className="pegion" src={BaseURLuploads+item.bg4} alt="" />
         <div className="col-md-12">
         <h1 className="Sectiononetitle" dangerouslySetInnerHTML={{ __html: item.heading }} />
           
         </div>
         <div className="col-md-6 ">
            <div className="creative_about_img">
               <div className="creative_shap"></div>
               <img src={BaseURLuploads+item.images} alt="" />
               <img className="dot p_absoulte" src={BaseURLuploads+item.bg3} alt="" />
               </div>
            </div>
            <div className="col-md-6 Sectiononetitle2">
               <p> <b className="" dangerouslySetInnerHTML={{ __html: item.text_1 }} /> </p>
               <ul className="" dangerouslySetInnerHTML={{ __html: item.text_2 }} />
              
               <p className="" dangerouslySetInnerHTML={{ __html: item.text_3 }} />
            {item.buttons !== '' ?    
               <Link onClick={() => externallinks(item.buttonlink)} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> {item.buttons} </span></Link>
            : null}
              
         </div>
         </div>
))}
  </div>
   </div>
</div>
 : null}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------- Recent Sermons ------------------------------------------------------ */}
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'Sermons'? <>
         {item.Active === 'yes' || item.Active === '' ?    <>    
               <div ref={myRef}  className="sectioneight">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <h1 dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                     </div>
                  </div>
               </div>




<div className="sectioneighttwo">

{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'Sermonsbg' ? 
         <img className="p_absoulte2" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
      : null}
   </div>
))}
   <div className="container">
      <div className="row">
      {isSermons.map((item, index) => ( 
         <div key={item.Sermons_id} className="col-md-12 sectioneighttwosss ">
         <div className="col-md-5">
           
         {item.video.match(regExp) ? <> 
            {item.video.match(regExp).length >= 2 ? <>      
               <iframe id="ytplayer" type="text/html" className="videosss"
                  src={`https://www.youtube.com/embed/`+item.video.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
            </>:null}
         </>:null}
                 
         </div>
         <div className="col-md-6">
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.describtion }} />
            <Link to={`/VideoPreview/${item.Sermons_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> More </span></Link>
            </div>
         </div>  
      ))}
       <Link to={`/Video`} className="buttonlink7"><span>   View All  </span></Link>
         </div>
      </div>
   </div>

</>
: null}
</>: null}
</div>
        ))}
{/* ---------------------------------- Events ---------------------------------------------------- */}

{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'Event'? <>
         {item.Active === 'yes' || item.Active === '' ?    <>   

<div className="sectiontwo">
<img className="p_absoulte" src={BaseURLuploads+issectiononebgtwo} alt={issectiononebgtwo} />
   <div className="container">
      <div className="row">
         <div className="col-md-12">
                 <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          
         <div className="col-md-12"> 
            <select className="orderslistsss" onClick={categoryMode}>
               <option>Sort Events</option>
               <option value="Newest" >Newest</option>
               <option value="Oldest" >Oldest</option>
            </select>
         </div>
         {/* {iseventactive === false ? <> 
         {iseventsrec.length > 0 ? 
            <div className="col-md-12 ">
           <p onClick={Eventsactives} class="evenasss">  Recurring Events </p>
           </div>
         : null} */}

{iseventspin.length > 0 ? <>   
         {iseventspin.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3">
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   </p>
            
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/>Recurring  {item.period} </p>
            : null }
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
             
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
</>
}
          
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}
         </> : null} 
         
         {issort === true ? <>   
         {isevents.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3">
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   </p>
            
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/>Recurring  {item.period} </p>
            : null }
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
             
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
</>
}
          
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}
         </> : null} 
         {isNewest === true ? <>   
         {iseventsnew.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3">
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   </p>
            
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/>Recurring  {item.period} </p>
            : null }
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
             
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
</>
}
          
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}
         </> : null} 
         
{isOldest === true ? <>   
         {iseventsOldest.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3">
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   </p>
            
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            {item.period !== '' ? 
               <p><FontAwesomeIcon icon={['fas','recycle']} className="ion-location"/> Recurring {item.period} </p>
            : null }
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
             
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
</>
}
          
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}
</> : null} 
         
         {/* </> : <>  
         
         <div className="col-md-12 ">
           <p onClick={Eventsactivestwo} class="evenasss"> Events </p>
           </div>
         
         
         
         {iseventsrec.map((item, index) => ( 
         <div key={item.Events_id}  className="col-md-12 events">
         <div className="col-md-3">
         <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
         </div>
         <div className="col-md-6">
         <h2>{item.title} </h2>
         <div className="block-ellipsis">
         <p dangerouslySetInnerHTML={{ __html: item.description }} />
         
         </div>
         <div className="block-ellipsis2">
            <p><FontAwesomeIcon icon={['fas','clock']} className="ion-location"/>{item.time} </p>
            <p><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> 
                                    {item.lastdate !== '' && item.date !== item.lastdate ? <>   To &nbsp;
                  <Moment format="MMM D, YYYY" withTitle>
                                     {item.lastdate} 
                  </Moment> 
                 
            
             </> : null}   </p>
            <p><FontAwesomeIcon icon={['fas','map-marker-alt']} className="ion-location"/> {item.location} </p>
            <div className="dgt-blog-readmore">
            <Link to={`/EventsPreview/${item.Events_id}`} className="link-btn">  Read More </Link>
             
           </div>
         </div>
         </div>
         <div className="col-md-3 resss">
         {item.organizer_image !== '' ?  
         <img src={BaseURLuploads+item.organizer_image} className="attachmentsss" alt=""  />  
         : <> 
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Organizer' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
</>
}
          
         {item.organizer_name !== '' ? 
            <h6 className="sectiontwoqqq">{item.organizer_name}</h6>
            : <h6 className="sectiontwoqqq">Npcentre</h6> }
            <p>Organizer</p>
         </div>
         </div>
     
         ))}

  </> }  */}

  <Link to={`/Events`} className="buttonlink72"><span>   View All  </span></Link>
  
  </div>
   </div>
</div>


</>
: null}
</>: null}
</div>
        ))}
{/* -------------------------------------------------------------------------------------- */}

{/* ------------------------------- Vision & Mission ------------------------------------ */}
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'Vision'? <>
         {item.Active === 'yes' || item.Active === '' ?    <>   

<div className="sectionfive" style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>
   <div className="container" >
      <div className="row" >
      
      {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12">
         <div className="col-md-6" >
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Vision' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
         </div>
         <div className="col-md-6 " >
         <div className=" </div> " >
            <h1  dangerouslySetInnerHTML={{ __html: item.V_C_heading }} />
            <div  dangerouslySetInnerHTML={{ __html: item.Vision }} /> 
         </div>
            <Link to={`/About/#Vision`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
        
         </div>
         </div>
   ))}  
    {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12">     
         <div className="col-md-6 sectionfive2 " >
            <div className="sectionfive3" >
            <h1  dangerouslySetInnerHTML={{ __html: item.M_C_heading }} />
            <div  dangerouslySetInnerHTML={{ __html: item.Mission }} /> 
            </div>
            <Link to={`/About/#Mission`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
         </div>
         <div className="col-md-6 sectionfive2 " >
         {ishomebg.map((item, index) => ( 
            <div key={item.homeimages_id} >
               {item.image_name === 'Mission' ? 
                  <img className="attachmentsss" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
               : null}
            </div>
         ))}
         </div>
         </div>
   ))}  
      </div>
   </div>
</div>

</>
: null}
</>: null}
</div>
        ))}
{/* -------------------------------- We Love God We Believe in God ------------------------------------------------------ */}
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'WeLoveGod' ? <>
         {item.Active === 'yes' || item.Active === '' ?    <>   
            <div className="sectionfour" >

                <div className="swmsc_half_bg_container swmsc_half_bg_right col-md-6" style={{backgroundImage: `url(${BaseURLuploads+item.images})`,backgroundSize: 'cover'}}> 
                <div className="swmsc_half_bg2 swmsc_half_bg2sss">
                      <div className="row">
                         <div className="col-md-12">
                         <h1  dangerouslySetInnerHTML={{ __html: item.heading }} /> 
                         <div className="colmd12nesss" dangerouslySetInnerHTML={{ __html: item.content }} /> 
                         <Link to={`/${item.buttonlink}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> {item.buttons} </span></Link>
                         </div>
                      </div>
                    </div>
                </div>
             
  
   <div className="swmsc_half_bg col-md-6" > 
      <div className="swmsc_half_bg2">
         <div className="row">
            <div className="col-md-12">
               <h1  dangerouslySetInnerHTML={{ __html: item.heading }} /> 
               <div className="colmd12nesss" dangerouslySetInnerHTML={{ __html: item.content }} /> 
                 <Link  onClick={() => externallinks(item.buttonlink)}  className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> {item.buttons} </span></Link>
            </div>
         </div>
       </div>
   </div>




</div>

</>
: null}
</>: null}
</div>
        ))}
{/* -------------------------------- Niagara Prayer Center ------------------------------------------------------ */}
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'NPC' ? <>
         {item.Active === 'yes' || item.Active === '' ?    <>   

<div className="sectionnine" style={{backgroundImage: `url(${BaseURLuploads+issectiononebg})`}}>
   <div className="container">
      <div className="row">
        
         <div className="col-md-6">
            <h1  dangerouslySetInnerHTML={{ __html: item.heading }} /> 
            <div className="sectionninedesc" dangerouslySetInnerHTML={{ __html: item.content }} /> 
         </div>
         <div className="col-md-6">
               <img className="" src={BaseURLuploads+item.images} alt={issectiononebgtwo} />
         </div>
      </div>
   </div>
</div>
</>
: null}
</>: null}
</div>
        ))}
{/* -------------------------------------------------------------------------------------- */}
{ishomebg.map((item, index) => ( 
   <div key={item.homeimages_id} >
      {item.image_name === 'blog' ? <>
         {item.Active === 'yes' || item.Active === '' ?    <>   

<div className="sectionseven">
   <div className="container">
      <div className="row">
      <div className="col-md-12">
      <h1  dangerouslySetInnerHTML={{ __html: item.content }} /> 
      </div>
      
      {isblogs.map((item, index) => ( 
               <div key={item.blog_id } className="col-md-4">
               <div className="sectionseven3">
                  <img src={BaseURLuploads+item.image} alt="" />
                  <h3> {item.title} </h3>
                  <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
                  <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: item.describtion }} /> 
                
                  <Link to={`/BlogPreview/${item.blog_id}/${item.title.replace(/\s/g, '-')}`}  className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
                   
                   
                 
               </div>
               </div>
            ))}
     
      
      </div>
   </div>
</div>
</>
: null}
</>: null}
</div>
        ))}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}



<div id="Footer" className="footer">
        <div className="container">
          <div className="row">
           
            
        
          <div className="col-md-12 mnqwsssxxx">
            <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <h1 className="title"> Services </h1>
                      </div>

                      <div className="">
                        {ismenulist.map((item, index) => ( <ul  key={item.menu_id} >
                          {item.menus === 'Our Services' ? <>
                        
                            {isserviceslist.map((itemsql, index) => ( 
                                <li  key={itemsql.serviessubmenu_id } className="menu-itemfooter col-md-2"> 
                                <img src={BaseURLuploads+itemsql.image}  className="sqsss" /> 
                                <Link   to={`/${itemsql.servies_path}`}>{itemsql.service_menu}</Link></li>
                              ))}
                            </> : null }
                               </ul>
                        ))}
                        </div>
                </div>
              </div>
           

            </div> 

           
            {/* <div className="col-md-6 ">
            <div id="content_block_02">
                  <div className="content-box widget-flickr2">
                      <div className="sec-title ">
                        <h1 className="title"> Gallery  </h1>
                      </div>
                      <SRLWrapper options={options}>
          {isGalleryfooter.map((item, index) => ( 
                  <a  key={item.galleryall_id } className="col-md-3 widget-flickr" href={BaseURLuploads+item.galleryall_image}>
                  <img src={BaseURLuploads+item.galleryall_image} alt=""/>
                  </a>
            ))} 
                
         </SRLWrapper> 
             


                </div>
              </div>
            
               
            
           
            

            </div>  */}
           
           

            


          </div>
        </div>
      </div>

   </>
  );
}

export default Home;