import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Moment from 'react-moment';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Blogsearchall(props) {
  
  const {Blogsearchalllistall} = useContext(MyContext);
 
  useEffect( () => {
       Blogsearchalllist(props.match.params.Addpreview);
       window.scrollTo(0, 0);
    }, []);
   
    

       const [isBlogAdspreview, setisBlogAdspreview] = useState([]);
       const Blogsearchalllist = async (searchValue) => {
              try {
                    
                     const ApiBlogAdsss = await Blogsearchalllistall(searchValue);
                      if(ApiBlogAdsss.success === 1){
                            console.log('BlogAdsall',ApiBlogAdsss);
                             setisBlogAdspreview(ApiBlogAdsss.BlogAdsqqq);
                      }
              } catch (error) { throw error;}    
       }
     
     
        
       
  return (
    // Pass on our props
    <div className="searchbar">
      
      <div className="container">
             <div className="row">
          
   
<div className="col-md-12 searchlistsss2">
<div className="searchheadingsss">
              <h1> Blog </h1>
       </div>
     
       {isBlogAdspreview.map((item, index) => ( 
               <div key={item.blog_id } className="col-md-3">
               <div className="sectionseven3">
                  <img src={BaseURLuploads+item.image} alt="" />
                  <h3> {item.title} </h3>
                  <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
              
                
                  <Link to={`/BlogPreview/${item.blog_id}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
                   
                 
               </div>
               </div>
       ))}
       <div className="col-md-12">
          {isBlogAdspreview.length === 0 ? <p> No results found </p> : null}
       </div>

      



    </div>
    </div>
    </div>
    </div>
  );
};

export default Blogsearchall;