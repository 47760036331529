import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 

import {toast} from "react-toastify";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';

function AdminAddEvent(props) {

  let history = useHistory();

       
       const {rootState,EventsAddall} = useContext(MyContext);
       const {theUser} = rootState;
       const [loadingadd, setloadingadd] = useState(false);
       let editorState = EditorState.createEmpty();
       const [description, setDescription] = useState(editorState);
       const [userInfo, setuserInfo] = useState({
              title: '',
              file:'',
              location:'',
              time:'',
                
       });
     
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       const [sdate, setsdate] = useState(new Date());
       useEffect( () => {
        window.scrollTo(0, 0);
           }, []); 



       const html = '';
       const contentBlock = htmlToDraft(html);
       if (contentBlock) {
         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
         //const editorState = EditorState.createWithContent(contentState);
         //setDescription(editorState);
       }
       //text editior
       const onEditorStateChange = (editorState) => {
              setDescription(editorState);
            }
       const onChangeValue = (e) => {
         
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 

          const [state, setState] = useState([
            {
              startDate: new Date(),
              endDate: addDays(new Date(), 7),
              key: 'selection'
            }
          ]);

console.log('startDate',state[0].startDate);
                console.log(state[0].endDate);

const currentDate = state[0].startDate;
const currentDayOfMonth = currentDate.getDate();
const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
const currentYear = currentDate.getFullYear();

const StartdateString = currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;

console.log('startDatewww',StartdateString);

       const Eventsadd = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                              toast.error(invalidImage, {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }
                           
                            const dataadd = await EventsAddall(userInfo,userInfo.description.value,StartdateString,state[0].endDate);
                            if(dataadd.success === 1){
                                   console.log('blogAdds',dataadd);
                                   //console.log(userInfo.description.value);
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   history.push(`/AdminOrganzorsupdate/${dataadd.insertids}`);
                                 
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 600;
                var MAX_HEIGHT = 600;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
             
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 








        return(
               <div className="bookads2 textalignblocksss">
                <Link to={`/AdminEvents`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>       
                     <div className="">
                            <div className="">
                                  
                                   <div className="col-md-12" style={{padding:0}}>
                                  
       
              <form onSubmit={Eventsadd} className="central-meta">
            
              <div className="clearfix"></div>
              <div className="form-row">
             



                  <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Title <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="title" value={userInfo.title} onChange={onChangeValue}  className="form-control" placeholder="Title" required />
                  </div>
                
                  <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Image <span className="adverts-form-required"> * </span> </label>
                      <div className="clearfix"></div>
                      <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   required/>
                     
                  </div>
                 
                    {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }
                    <div className="form-group col-md-12 editor">
                    <label className="font-weight-bolds"> Description <span className="adverts-form-required"> * </span> </label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            
                        />
                     <textarea style={{display:'none'}}
                     disabled ref={(val) => userInfo.description = val}
                    
                     value={draftToHtml(convertToRaw(description.getCurrentContent())) }
                     />
                    </div>
                    <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Location <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="location" value={userInfo.location} onChange={onChangeValue}  className="form-control" placeholder="location" required />
                  </div>
                     <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Time <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="time" value={userInfo.time} onChange={onChangeValue}  className="form-control" placeholder="Eg: time 10:00 Am" required />
                  </div>
                    
                    <div className="form-group col-md-12">
                      <label className="font-weight-bolds"> Date <span className="adverts-form-required"> * </span>  </label>
                     
                      <DateRangePicker
                      onChange={item => setState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction="horizontal"
                  />
                        
                     
                  </div>
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
              
                      <button type="submit" className="btn btn-primary"> Next <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                     
                  </div> 
                   }
              
             
              </div>
              
          </form>
          </div>


  
          
          </div>
          </div>





          </div>
        );
    }

export default AdminAddEvent;