import React,{useContext,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import { WEB_URL_UPLOAD,CAPTCHA_SITE_KEY } from '../contexts/Apilinks';
import { useHistory } from "react-router-dom";
const TEST_SITE_KEY = CAPTCHA_SITE_KEY;
const DELAY = 1500;
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminLogin() {
    let history = useHistory();

    const {loginUser,isLoggedIn} = useContext(MyContext);
    
    useEffect( () => {
       window.scrollTo(0, 0);
    }, []);

 
 
const [value, setvalue] = useState("[empty]");
const [load, setload] = useState(false);
const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);
const _reCaptchaRef = React.createRef()


useEffect( () => {
  setTimeout(() => {
    setload(true );
  }, DELAY);
  console.log("didMount - reCaptcha Ref-", _reCaptchaRef);
 }, []); 

 const handleChange = (value) => {
       setvalue(value);
      
     };
const asyncScriptOnLoad = () => {
  setrecaptchaLoaded(true);
  _reCaptchaRef.current.execute();
};
   const [loadinglogin, setloadinglogin] = useState(false);
    const [userInfo, setuserInfo] = useState({
        email: '',
        password: '',
    });
    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
                [e.target.name]:e.target.value
        });
    } 
    const userLogin = async (event) => {
        setloadinglogin(true);
        event.preventDefault();
        event.persist();
        const data = await loginUser(userInfo,userInfo.recaptcha_response.value);
        if(data.success === 1){
            setloadinglogin(false);
            localStorage.setItem('loginTokenNPC',JSON.stringify(data.users[0].adminlogin_id));       
           
          event.target.reset(); 
          await isLoggedIn();
            history.push('/');
          
        }else{
            setloadinglogin(false);
            toast.error( data.msg, {
                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });  
        }
        
    }
   
        return(
              <div className="prayerreqsss">
              <div className="container">
                 <div className="row">
                 <div className="col-md-1"> &nbsp; </div>
                 <div className="col-md-6"> 
                
           <form onSubmit={userLogin} className="login-forms">
          
                 
                <div className="clearfix"></div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                      
                        <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}  className="form-control" placeholder="Email" required/>
                    </div>
                    <div className="form-group col-md-12">
                       
                        <input type="password" name="password" value={userInfo.password} onChange={onChangeValue} className="form-control" placeholder="Password" required/>
                    </div>
                    <input type="hidden" name="recaptcha_response" ref={(val) => userInfo.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

{load && (
<ReCAPTCHA
style={{ display: "inline-block" }}
theme="dark"
size="invisible"
ref={_reCaptchaRef}
sitekey={TEST_SITE_KEY}
onChange={handleChange}
asyncScriptOnLoad={asyncScriptOnLoad}
/>
)}

<div className="form-group col-md-12 ">
{loadinglogin ?
            <>
                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
            </>
         :  <>
            <button type="submit" className="btn btn-style-two buttonlink" disabled={!recaptchaLoaded}>  Login </button>
      </>
}
                    </div>
                </div>
               
                
            </form> 
            </div> 
            </div> 
            </div> 
            </div> 
                  
        );
}
export default AdminLogin;