import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminaboutpagesUpdate from './AdminaboutpagesUpdate';
import AdminaboutpagestwoUpdate from './AdminaboutpagestwoUpdate';
import AdminaboutpagesMCUpdate from './AdminaboutpagesMCUpdate';
import AdminaboutpagesVCUpdate from './AdminaboutpagesVCUpdate';
import AdminaboutpagesHCUpdate from './AdminaboutpagesHCUpdate';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminAbout() {
   const {aboutsall,SectionwwrcDisableall,SectionsofcDisableall,SectionsMCDisableall,SectionsVCDisableall,SectionsHCDisableall} = useContext(MyContext);

   useEffect( () => {
     abouts();
     window.scrollTo(0, 0);
  }, []);
 
  
 const [isabouts, setabouts] = useState([]);
 const abouts = async() =>{
 try {
        const aboutsss = await aboutsall();
               if(aboutsss.success === 1){
                      console.log('abouts',aboutsss.aboutslist);
                      setabouts(aboutsss.aboutslist);
        }
 } catch (error) { throw error;}
}

const [isaboutpagesEdit, setaboutpagesEdit] = useState(false);
const [isaboutpagestwoEdit, setaboutpagestwoEdit] = useState(false);
const [isaboutpagesMCEdit, setaboutpagesMCEdit] = useState(false);
const [isaboutpagesVCEdit, setaboutpagesVCEdit] = useState(false);
const [isaboutpagesHCEdit, setaboutpagesHCEdit] = useState(false);
const aboutpagesEdit = () =>{ setaboutpagesEdit(true); }
const aboutpagestwoEdit = () =>{ setaboutpagestwoEdit(true); }
const aboutpagesMCEdit = () =>{ setaboutpagesMCEdit(true); }
const aboutpagesVCEdit = () =>{ setaboutpagesVCEdit(true); }
const aboutpagesHCEdit = () =>{ setaboutpagesHCEdit(true); }
const editcancel = async() =>{ setaboutpagesEdit(false);}
const editcanceltwo = async() =>{ setaboutpagestwoEdit(false);}
const editcancelMC = async() =>{ setaboutpagesMCEdit(false);}
const editcancelVC = async() =>{ setaboutpagesVCEdit(false);}
const editcancelHC = async() =>{ setaboutpagesHCEdit(false);}

const SectionwwrcDisable = async(ids,datas) =>{
       try {
              const SectionwwrcDisablesss = await SectionwwrcDisableall(ids,datas);
                     if(SectionwwrcDisablesss.success === 1){
                            console.log('SectionwwrcDisable');
                            setabouts([]);
                            abouts();
                            
              }
       } catch (error) { throw error;}
     }
const SectionsofcDisable = async(ids,datas) =>{
       try {
              const SectionsofcDisablesss = await SectionsofcDisableall(ids,datas);
                     if(SectionsofcDisablesss.success === 1){
                            console.log('SectionsofcDisable');
                            setabouts([]);
                            abouts();
                            
              }
       } catch (error) { throw error;}
     }
const SectionsMCDisable = async(ids,datas) =>{
       try {
              const SectionsMCDisableqqq = await SectionsMCDisableall(ids,datas);
                     if(SectionsMCDisableqqq.success === 1){
                            console.log('SectionsMCDisable');
                            setabouts([]);
                            abouts();
                            
              }
       } catch (error) { throw error;}
     }
     const SectionsVCDisable = async(ids,datas) =>{
       try {
              const SectionsVCDisableqqq = await SectionsVCDisableall(ids,datas);
                     if(SectionsVCDisableqqq.success === 1){
                            console.log('SectionsVCDisable');
                            setabouts([]);
                            abouts();
                            
              }
       } catch (error) { throw error;}
     }
       const SectionsHCDisable = async(ids,datas) =>{
       try {
              const SectionsHCDisableqqq = await SectionsHCDisableall(ids,datas);
                     if(SectionsHCDisableqqq.success === 1){
                            console.log('SectionsHCDisable');
                            setabouts([]);
                            abouts();
                            
              }
       } catch (error) { throw error;}
     }


  return (
       <div className="Adminaboutpages" >

{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}

{isaboutpagesEdit === false ? 
<div className="aboutpages" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
           
            {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12">     
                {item.Who_We_Are_code === 'wwrc' ? <>
                <p onClick={aboutpagesEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                           
              {item.Who_We_Are_active === 'yes' || item.Who_We_Are_active === '' ? <>
               <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p onClick={()=>SectionwwrcDisable('no','wwrc')}    className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section </span></p>
               <p className="Adminbuttonlink2mmm"> This is section now visible on  About us page  </p>
               </>
            :
            <> 
               <p onClick={()=>SectionwwrcDisable('yes','wwrc')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
               <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on home page </span></p>
               <p  className="Adminbuttonlink2mmm2"> This is section now hidden on  About us page   </p>
            </>}
                     </> : null }

              <div className={ "col-md-12 " + (item.Who_We_Are_active === 'yes' || item.Who_We_Are_active === '' ? " adminenables " : " admindisablsss")}> 

                     
               <h1 className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.W_W_A_heading }} /> 
               <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.Who_We_Are }} /> 
            </div>
            </div>
      
            ))}
             </div>
        </div>
    </div>
</div>
: <>
 {isabouts.map((item, index) => ( 
       <div  key={item.about_id} className="col-md-12">  
              <AdminaboutpagesUpdate ids={item.about_id} content={item.Who_We_Are}  heading={item.W_W_A_heading} cancel={editcancel} refresh={ ()=>abouts()}  />
       </div>
      
))}


       </>
}
{/* -------------------- Statement of Faith --------------------------------------------------------------------------------------------------- */}


{isaboutpagestwoEdit === false ? 
<div className="aboutpages2" >
   <div className="container">
        <div className="row">
        {isabouts.map((item, index) => ( 
               <div  key={item.about_id} className="col-md-12 aboutpages2qqq">     
               {item.S_of_Faith_code === 'sofc' ? <>
                <p onClick={aboutpagestwoEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                       

              {item.S_of_Faith_active === 'yes' || item.S_of_Faith_active === '' ? <>
                     <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p onClick={()=>SectionsofcDisable('no','sofc')}   className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section  </span></p>
                     <p className="Adminbuttonlink2mmm"> This is section now visible on About us page  </p>
               </>
            :
            <> 
                     <p onClick={()=>SectionsofcDisable('yes','sofc')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on home page </span></p>
                     <p  className="Adminbuttonlink2mmm2"> This is section now hidden on the home page  </p>
            </>}
                     </> : null }
              <div className={ "col-md-12 " + (item.S_of_Faith_active === 'yes' || item.S_of_Faith_active === '' ? " adminenables " : " admindisablsss")}> 
                     <h1 className="aboutpagesqqq3" dangerouslySetInnerHTML={{ __html: item.S_O_F_heading }} />
               <div className="aboutpagesqqq4"  dangerouslySetInnerHTML={{ __html: item.Statement_of_Faith }} /> 
            </div>
            </div>
      
            ))}
           

        </div>
    </div>
</div>
: <>
{isabouts.map((item, index) => ( 
      <div  key={item.about_id} className="col-md-12">  
             <AdminaboutpagestwoUpdate ids={item.about_id} content={item.Statement_of_Faith}  heading={item.S_O_F_heading} cancel={editcanceltwo} refresh={ ()=>abouts()}  />
      </div>
     
))}


      </>
}
{/* --------------------  Mission --------------------------------------------------------------------------------------------------- */}
{isaboutpagesMCEdit === false ? <>
{isabouts.map((item, index) => ( 
       <div id="Mission"  key={item.about_id}  className="aboutpages3" style={{backgroundImage: `url(${BaseURLuploads+item.mission_img})`}}>
       <div className="container">
              <div className="row">
              
                     <div   className="col-md-12"> 
                     {item.Mission_code === 'MC' ? <>
                <p onClick={aboutpagesMCEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                       

              {item.Mission_active === 'yes' || item.Mission_active === '' ? <>
                     <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p onClick={()=>SectionsMCDisable('no','MC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section  </span></p>
                     <p className="Adminbuttonlink2mmm"> This is section now visible on About us page  </p>
               </>
            :
            <> 
                     <p onClick={()=>SectionsMCDisable('yes','MC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on home page </span></p>
                     <p  className="Adminbuttonlink2mmm2"> This is section now hidden on the home page  </p>
            </>}
                     </> : null }
                     <div className={ "col-md-12 " + (item.Mission_active === 'yes' || item.Mission_active === '' ? " adminenables " : " admindisablsss")}> 
                             <h1  className="aboutpagesqqq5" dangerouslySetInnerHTML={{ __html: item.M_C_heading }} />
                            <div   className="aboutpagesqqq6" dangerouslySetInnerHTML={{ __html: item.Mission }} /> 
                     </div>
                     </div>
       

              </div>
       </div>
       </div>
 ))}  
 </>
 : <>
 {isabouts.map((item, index) => ( 
       <div  key={item.about_id} className="col-md-12">  
              <AdminaboutpagesMCUpdate ids={item.about_id} content={item.Mission} heading={item.M_C_heading}  cancel={editcancelMC} refresh={ ()=>abouts()}  />
       </div>
      
 ))}
 
 
       </>
 }
{/* --------------------  Vision --------------------------------------------------------------------------------------------------- */}
{isaboutpagesVCEdit === false ? <>
{isabouts.map((item, index) => ( 
<div id="Vision" key={item.about_id} className="aboutpages" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
            {item.Vision_code === 'VC' ? <>
                <p onClick={aboutpagesVCEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                        
                     {item.Vision_active === 'yes' || item.Vision_active === '' ? <>
                     <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p onClick={()=>SectionsVCDisable('no','VC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section  </span></p>
                     <p className="Adminbuttonlink2mmm"> This is section now visible on About us page  </p>
               </>
            :
            <> 
                     <p onClick={()=>SectionsVCDisable('yes','VC')}className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on home page </span></p>
                     <p  className="Adminbuttonlink2mmm2"> This is section now hidden on the home page  </p>
            </>}
                     </> : null }
       <div className={ "col-md-12 " + (item.Vision_active === 'yes' || item.Vision_active === '' ? " adminenables " : " admindisablsss")}> 
              <h1  className="aboutpagesqqq" dangerouslySetInnerHTML={{ __html: item.V_C_heading }} />
              <div  className="aboutpagesqqq2" dangerouslySetInnerHTML={{ __html: item.Vision }} /> 
           
             </div>
             </div>
        </div>
    </div>
</div>
))}  
 </>
 : <>
 {isabouts.map((item, index) => ( 
       <div  key={item.about_id} className="col-md-12">  
              <AdminaboutpagesVCUpdate ids={item.about_id} content={item.Vision} heading={item.V_C_heading}  cancel={editcancelVC} refresh={ ()=>abouts()}  />
       </div>
      
 ))}
 
 
       </>
 }

{/* --------------------  History --------------------------------------------------------------------------------------------------- */}
{isaboutpagesHCEdit === false ? <>
{isabouts.map((item, index) => ( 
<div key={item.about_id} className="aboutpages4" >
   <div className="container">
        <div className="row">
            <div className="col-md-12">
       
            {item.History_code === 'HC' ? <>
                <p onClick={aboutpagesHCEdit} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit section </span></p>
                        

              {item.History_active === 'yes' || item.History_active === '' ? <>
                     <p className="Adminbuttonlink2 admindisablsss2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p onClick={()=>SectionsHCDisable('no','HC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye-slash']} /> Hide this section  </span></p>
                     <p className="Adminbuttonlink2mmm"> This is section now visible on About us page  </p>
               </>
            :
            <> 
                     <p onClick={()=>SectionsHCDisable('yes','HC')}  className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','eye']} /> Make this section vissible </span></p>
                     <p className="Adminbuttonlink2 admindisablsss2"> <FontAwesomeIcon icon={['fas','eye-slash']} /> <span> Hide this section on home page </span></p>
                     <p  className="Adminbuttonlink2mmm2"> This is section now hidden on the home page  </p>
            </>}

                     </> : null }
        
         <div className="col-md-6 " >
         <div className={ " " + (item.History_active === 'yes' || item.History_active === '' ? " adminenables " : " admindisablsss")}> 
              <h1  className="aboutpagesqqq7" dangerouslySetInnerHTML={{ __html: item.H_C_heading }} />
              <div  className="aboutpagesqqq8" dangerouslySetInnerHTML={{ __html: item.History }} /> 
              </div>
         </div>
         <div className={ "col-md-6" + (item.History_active === 'yes' || item.History_active === '' ? " adminenables " : " admindisablsss")}> 
                  <img className="attachmentsss" src={BaseURLuploads+item.history_img} alt="" />
         </div>
        
             </div>
        </div>
    </div>
</div>

))}  
 </>
 : <>
 {isabouts.map((item, index) => ( 
       <div  key={item.about_id} className="col-md-12">  
              <AdminaboutpagesHCUpdate ids={item.about_id} content={item.History} heading={item.H_C_heading} cancel={editcancelHC} refresh={ ()=>abouts()}  />
       </div>
      
 ))}
 
 
       </>
 }












   </div>
  );
}

export default AdminAbout;