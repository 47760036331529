import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { confirmAlert } from 'react-confirm-alert'; 
import AdminTeamEdit from './AdminTeamEdit';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminTeam() {
   const {ourteamsall,deleteTeamlist} = useContext(MyContext);

   useEffect( () => {
     ourteams();
     window.scrollTo(0, 0);
  }, []);
 
 const [isourteams, setourteams] = useState([]);
 const ourteams = async() =>{
 try {
        const ourteamsss = await ourteamsall();
               if(ourteamsss.success === 1){
                      console.log('ourteams',ourteamsss.ourteamslist);
                      setourteams(ourteamsss.ourteamslist);
        }
 } catch (error) { throw error;}
}
const [isourteamsids, setourteamsids] = useState(null);
const [isourteamsidsedit, setourteamsedit] = useState(false);
const editaminsteam = async(ids) =>{
   setourteamsids(ids);
   setourteamsedit(true);
}
const editcancel = async() =>{
   window.scrollTo(0, 0);
   setourteamsids(null);
   setourteamsedit(false);
}

const deletes = (blogids) => {
   confirmAlert({
     title: 'Confirm to Delete ',
     message: 'Are you sure to do this.',
     buttons: [
       {
         label: 'Yes',
         onClick: () => deleteblogs(blogids)
       },
       {
         label: 'No',
       }
     ]
   })
 };     
 const deleteblogs = async (blogids) => {
       
   try {
          const deleteblogssss = await deleteTeamlist(blogids);
          if(deleteblogssss.success === 1){
            setourteams([]);
            ourteams();
 
          }
   } catch (error) { throw error;}      
 
 
 }
  return (
   <>

   
<div className="Adminteams">
<Link to={`/AdminAddTeam`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','plus']} /> Add Team Member</span></Link>
<div className="sectionthree">
   <div className="container">
      <div className="row">

      {isourteams.map((item, index) => ( 
      <div key={item.ourteam_id }>
         {isourteamsids === item.ourteam_id &&  isourteamsidsedit === true ? <>
           <AdminTeamEdit teamids={item.ourteam_id} name={item.name} subname={item.name_position} facebook={item.facebook} twitter={item.twitter} instagram={item.instagram}  cancel={editcancel} refresh={ ()=>ourteams()} />
           
           </>
         : null } 
      </div> ))}

   {isourteamsidsedit === false ? <>
         {isourteams.map((item, index) => ( 
      <div key={item.ourteam_id } className="col-md-4">
        
            <div >
         
               <p onClick={()=>editaminsteam(item.ourteam_id)} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','edit']} /> Edit </span></p>
               <p onClick={()=>deletes(item.ourteam_id)} className="Adminbuttonlink2"><span> <FontAwesomeIcon icon={['fas','trash-alt']} /> Delete  </span></p>
            <div className="dgt-team-item-inner">
               <div className="dgt-team-avatar">
                  <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
               </div>
               <div className="dgt-team-content">
                  <div className="dgt-team-info">
                     <h4>{item.name}</h4>
                     <h6>{item.name_position}</h6>
                  </div>
               <div className="dgt-team-social">
               <div className="dgt-social icon-show name-hidden">
                     <ul>
                        {item.facebook !== '' ? 
                              <li className="facebook"><a title="facebook" href={item.facebook} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','facebook-f']} className="ion-social-twitter"/></a></li> 
                        : null} 
                        {item.twitter !== '' ? 
                  
                              <li className="twitter"><a title="twitter" href={item.twitter} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','twitter']} className="ion-social-twitter"/></a></li>
                        : null} 
                        {item.instagram !== '' ? 
                              <li className="instagram"><a title="instagram" href={item.instagram } rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','instagram']} className="ion-social-twitter"/></a></li>
                        : null} 
                     </ul>
               </div>
            </div>
         
                                                      </div>
                     </div>
                  </div>
 
                </div>

         ))}
  </>: null }

  </div>
   </div>
</div> 
</div> 


   </>
  );
}

export default AdminTeam;