import React,{useContext,useState,useEffect } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
import { useHistory } from "react-router-dom";
import Loader from 'react-loader-spinner';
let BaseURLuploads = WEB_URL_UPLOAD;

function AdminRearrangecategory() {
       let history = useHistory();
   const {Gallerysssall,galleryorderall} = useContext(MyContext);

   useEffect( () => {
     Gallerysss()
     window.scrollTo(0, 0);
  }, []);
 
  
  const [loadingadd, setloadingadd] = useState(false);
  const onChangeValue = (e) => {
         
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 
 const [isallgallery, setallgallery] = useState([]);

 const Gallerysss = async() => {
   const Gallerysssqqq = await Gallerysssall();
   if(Gallerysssqqq.success === 1){
          console.log('Gallerysssqqq',Gallerysssqqq.Galleryssslist);
          setallgallery(Gallerysssqqq.Galleryssslist);
       
   }
}
const [userInfo, setuserInfo] = useState({
       numberlist: '',
         
});
const Eventsadd = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                    
                     const dataadd = await galleryorderall( userInfo.ordernumber.value,userInfo.gallerycategoryid.value);
                     if(dataadd.success === 1){
                            console.log('rearrange order',dataadd);
                            //console.log(userInfo.description.value);
                            setloadingadd(false);
                            event.target.reset(); 
                            setallgallery([]);
                            Gallerysss();
                            setmoreids(null);
                          
                          
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}
const [issetmoreids, setmoreids] = useState(null);
const moreoptions = (ids) =>{
       setmoreids(ids)
       
}
  return (
   <>

{/* -------------------- --------------------------------------------------------------------------------------------------- */}


<div className="Admingallerysss">

<Link to={`/AdminGallery`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>
   
<div className="gallerysss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
        
            <table>
                     <tr>
                            <th>Category</th>
                            <th>Order No</th>
                            <th>Action </th>
                     </tr>
            {isallgallery.map((item, index) => ( 
               <tr  key={item.gallery_category_id } >
                   
             

              
                     <td>{item.gallery_category_name}</td>
                     <td>
                        
                            
                     {issetmoreids === item.gallery_category_id ? <>   
                            <form onSubmit={Eventsadd} className="central-meta">
                            <input type="number" name="ordernumber"  ref={(val) => userInfo.ordernumber = val}  className="form-control" placeholder="Order Number" required defaultValue={item.order_ids} />
                            <input type="hidden" name="gallerycategoryid" ref={(val) => userInfo.gallerycategoryid = val} className="form-control" defaultValue={item.gallery_category_id} /> <br/>
                    {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
              
                      <button type="submit" className="btn btn-primary"> Update <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                     
                  </div> 
                   }
                    </form>
                     
                     </> : <> {item.order_ids} </>}
                     
                     
                     </td>
                     <td onClick={() => moreoptions(item.gallery_category_id)} > <FontAwesomeIcon icon={['fas','edit']} /> Edit</td>
            

      
                 
              
               </tr>
            ))}

</table>

        
  </div>
  </div>
   </div>
</div>
</div>


   </>
  );
}

export default AdminRearrangecategory;