import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";
import {Link } from "react-router-dom"; 
function AdminserviestimeEdittwo(props) {
       
  let history = useHistory();

   const {servicestimeupdatesall} = useContext(MyContext);

   useEffect( () => {
     window.scrollTo(0, 0);
  }, []);
 
  const [loadingadd, setloadingadd] = useState(false);
    
const [userInfo, setuserInfo] = useState({
       nameone: props.nameone,
       nametwo: props.nametwo,
       namethree: props.namethree,
       timeone: props.timeone,
       timetwo: props.timetwo,
       timethree: props.timethree,
         
});


const onChangeValue = (e) => {
  
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 


const servicestimeupdates = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                    
                     const dataadd = await servicestimeupdatesall(userInfo,props.serviesids);
                     if(dataadd.success === 1){
                            console.log('servicestimeupdates',dataadd);
                            //console.log(userInfo.description.value);
                            setloadingadd(false);
                            event.target.reset(); 
                            history.push(`/AdminServiceTime`);
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}




  return (
   <>
     <div className="bookads2">
     <Link to={`/AdminServiceTime`} className="Adminbuttonlink2 Adminblogs4"><span> <FontAwesomeIcon icon={['fas','backward']} /> Back </span></Link>      
                      <div className="hrsss">
                             <div className="">
                                   
                                    <div className="col-md-12" style={{padding:0}}>
                                   
        
               <form onSubmit={servicestimeupdates} className="central-meta ">
             
               <div className="clearfix"></div>
               <div className="form-row">
               <label className="font-weight-bold"> {props.days}  </label>
<hr />
                   <div className="form-group col-md-3" style={{padding:0}}>
                          <label> Service Name 1 :</label>
                   </div>
                  <div className="form-group col-md-9">
                     <input type="text" name="nameone" value={userInfo.nameone} onChange={onChangeValue}  className="form-control" placeholder=""  />
                  </div>
                  <div className="form-group col-md-3" style={{padding:0}}>
                          <label> Service Time :</label>
                   </div>
                  <div className="form-group col-md-9">
                       <input type="text" name="timeone" value={userInfo.timeone} onChange={onChangeValue}  className="form-control" placeholder="ex : 9.00:AM to 5.00:PM"  />
                   </div>
                  
<hr />
                   <div className="form-group col-md-3" style={{padding:0}}>
                          <label> Service Name 2 :</label>
                   </div>
                  <div className="form-group col-md-9">
                     <input type="text" name="nametwo" value={userInfo.nametwo} onChange={onChangeValue}  className="form-control" placeholder=""  />
                  </div>
                  <div className="form-group col-md-3" style={{padding:0}}>
                          <label> Service Time :</label>
                   </div>
                  
                  <div className="form-group col-md-9">
                       <input type="text" name="timetwo" value={userInfo.timetwo} onChange={onChangeValue}  className="form-control" placeholder="ex : 9.00:AM to 5.00:PM"  />
                   </div>
 <hr />
                   <div className="form-group col-md-3" style={{padding:0}}>
                          <label> Service Name 3 :</label>
                   </div>
                  <div className="form-group col-md-9">
                     <input type="text" name="namethree" value={userInfo.namethree} onChange={onChangeValue}  className="form-control" placeholder=""  />
                  </div>
                  <div className="form-group col-md-3" style={{padding:0}}>
                          <label> Service Time :</label>
                   </div>
                  <div className="form-group col-md-9">
                       <input type="text" name="timethree" value={userInfo.timethree} onChange={onChangeValue}  className="form-control" placeholder="ex : 9.00:AM to 5.00:PM"  />
                   </div>
               
                   <hr />
                  
          
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
               
                       <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                   </div> 
                    }
               
              
               </div>
               
           </form>
           </div>
 
 
   
           
           </div>
           </div>
 
 
 
 
 
           </div>





   </>
  );
}

export default AdminserviestimeEdittwo;