import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 
import { EditorState, convertToRaw, ContentState,convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {toast} from "react-toastify";


function AdminaboutpagesHCUpdate(props) {
       
  let history = useHistory();
  const {secHCupdatesall} = useContext(MyContext);

  

  const [loadingadd, setloadingadd] = useState(false);
const [userInfo, setuserInfo] = useState({
    
       file:'',
});
        let editorState = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.content)));
       const [heading, setheading] = useState(editorState);
       let editorStatetitle = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.heading)));
       const [headingtitle, setheadingtitle] = useState(editorStatetitle);

const html = '';
const contentBlock = htmlToDraft(html);
if (contentBlock) {
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
}
const onEditorStateChange = (editorState) => { setheading(editorState);}
const onEditorStateChangetitle = (editorStatetitle) => { setheadingtitle(editorStatetitle);}


const [fileempty, setfileempty] = useState(false);
const [filetemp, setfiletemp] = useState(null);
const [invalidImage, setinvalidImage] = useState(null);

const onChangeValue = (e) => {
  
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 

const editcancel = () =>{
       props.cancel();
}
const secHCupdates = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                     if(invalidImage !== null){
                       toast.error(invalidImage, {
                         position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                       }); 
                       setloadingadd(false);
                       return;
                     }
                    
                     const dataaddHC = await secHCupdatesall(userInfo,userInfo.heading.value,userInfo.fileempty.value,props.ids,userInfo.headingtitle.value);
                     if(dataaddHC.success === 1){
                            console.log('secHCupdatesall',dataaddHC);
                            setloadingadd(false);
                            event.target.reset(); 
                            props.cancel();
                            props.refresh();
                          
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}
// {props.match.params.Add}

const resetFile = (event) => {
       event.preventDefault();
       setfiletemp(null)
     }

     let reader = new FileReader();

const handleChange = (event) => {
const imageFile = event.target.files[0];
      const imageFilname = event.target.files[0].name;

      if (!imageFile) {
       setinvalidImage('Please select image.');
        return false;
      }
  
      if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
       setinvalidImage('Please select valid image JPG,JPEG,PNG');
        return false;
      }
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
         var canvas = document.createElement('canvas');
         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0);

         var MAX_WIDTH = 600;
         var MAX_HEIGHT = 600;
         var width = img.width;
         var height = img.height;

         if (width > height) {
           if (width > MAX_WIDTH) {
             height *= MAX_WIDTH / width;
             width = MAX_WIDTH;
           }
         } else {
           if (height > MAX_HEIGHT) {
             width *= MAX_HEIGHT / height;
             height = MAX_HEIGHT;
           }
         }
         canvas.width = width;
         canvas.height = height;
         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0, width, height);
         ctx.canvas.toBlob((blob) => {
           const file = new File([blob], imageFilname, {
               type: 'image/jpeg',
               lastModified: Date.now()
           });
           setuserInfo({
              ...userInfo,
              file:file,
         })
         }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
       setfiletemp( URL.createObjectURL(imageFile))
       setfileempty(true);
       setinvalidImage(null)
       };
        img.onerror = () => {
              setinvalidImage('Invalid image content.');
          return false;
        };
        //debugger
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);

}; 






  return (
   <>
     <div className="bookads2">
        <div className="col-md-12" style={{padding:0}}>
               <form onSubmit={secHCupdates} className="central-meta ">
               <div className="clearfix"></div>
               
               <div className="col-md-4" >
                     <div className="form-row">
                     <div className="form-group col-md-12">
                            <label className="font-weight-bolds"> Background Image  </label>
                            <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   />
                           
                            {fileempty === true ? 

                            <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                            : 
                            <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                            }
                     </div>
                     
                            {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }

                            </div>  
                     </div>        
               <div className="col-md-8" >
                     <div className="form-row">
                     <div className="form-group col-md-12 editor editorTitle textalignblocksss2">
                     <label className="font-weight-bold"> Title  </label>
                         <Editor
                             editorState={headingtitle}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChangetitle}
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.headingtitle = val}
                      value={draftToHtml(convertToRaw(headingtitle.getCurrentContent())) }
                      />
                     </div>
                    <div className="form-group col-md-12 editor textalignblocksss4">
                    
                         <Editor
                             editorState={heading}
                             toolbarClassName="toolbarClassName"
                             wrapperClassName="wrapperClassName"
                             editorClassName="editorClassName"
                             onEditorStateChange={onEditorStateChange}
                         />
                      <textarea style={{display:'none'}}
                      disabled ref={(val) => userInfo.heading = val}
                      value={draftToHtml(convertToRaw(heading.getCurrentContent())) }
                      />
                     </div>
                    
                   
                     
                     
                            </div>
                     </div>
                 
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
                     <p onClick={editcancel} className="Adminbuttonlink2" style={{marginRight:"17px"}} ><span> <FontAwesomeIcon icon={['fas','edit']} />Cancel </span></p>
                     <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                      
                   </div> 
                    }
               
              
             
           </form>
           </div>
 
 
 
           </div>


   </>
  );
}

export default AdminaboutpagesHCUpdate;