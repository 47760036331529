import React,{useState,useEffect,useContext} from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import {NavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-confirm-alert/src/react-confirm-alert.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
import { MyContext } from '../contexts/MyContext';
import AdminNavbarmenu from '../controlpanel/menu/AdminNavbarmenu';
import AdminBlog from './AdminBlog';
import AdminBlogPreview from './AdminBlogPreview';
import AdminAddBlog from './AdminAddBlog';
import AdminBlogEdit from './AdminBlogEdit';
import AdminBlogDelete from './AdminBlogDelete';

import AdminPasterMessage from './AdminPasterMessage';

import AdminEvents from './AdminEvents';
import AdminAddEvent from './AdminAddEvent';
import AdminOrganzorsupdate from './AdminOrganzorsupdate';
import AdminEventsPreview from './AdminEventsPreview';
import AdminEventsEdit from './AdminEventsEdit';
import AdminEventsDelete from './AdminEventsDelete';



import AdminEventsrec from './AdminEventsrec';
import AdminAddEventrec from './AdminAddEventrec';
import AdminOrganzorsupdaterec from './AdminOrganzorsupdaterec';
import AdminEventsPreviewrec from './AdminEventsPreviewrec';
import AdminEventsEditrec from './AdminEventsEditrec';
import AdminEventsDeleterec from './AdminEventsDeleterec';


import AdminVideo from './AdminVideo';
import AdminAddVideo from './AdminAddVideo';
import AdminVidoEdit from './AdminVidoEdit';
import AdminVidoDelete from './AdminVidoDelete';

import AdminTeam from './AdminTeam';
import AdminAddTeam from './AdminAddTeam';

import AdminGallery from './AdminGallery';
import Admingallerycategory from './Admingallerycategory';
import AdmingallerycategoryEdit from './AdmingallerycategoryEdit';
import AdminGalleryPreview from './AdminGalleryPreview';
import AdmingalleryAdd from './AdmingalleryAdd';
import AdminRearrangecategory from './AdminRearrangecategory';

import AdminServiceTime from './AdminServiceTime';
import AdminserviestimeEdit from './AdminserviestimeEdit';

import AdminLive from './AdminLive';
import AdminAddVideolive from './AdminAddVideolive';
import AdminHome from './AdminHome';
import AdminSliderEdit from './AdminSliderEdit';

import AdminAbout from './AdminAbout';
import AdminCreateSlider from './AdminCreateSlider';

import AdminVideogallery from './AdminVideogallery';
import AdminAddVideogallery from './AdminAddVideogallery';
import AdminVidoEditgallery from './AdminVidoEditgallery';
import AdminVidoDeletegallery from './AdminVidoDeletegallery';

import AdminServicesall from './AdminServicesall';
import AdminFacilities from './AdminFacilities';
import AdminAddFacilitiessss from './AdminAddFacilitiessss';
import AdminFacilitiesEdit from './AdminFacilitiesEdit';
import AdminChurchChoir from './AdminChurchChoir';
import AdminAddChurchChoir from './AdminAddChurchChoir';
import AdminChurchChoirEdit from './AdminChurchChoirEdit';
import AdminSundaySchool from './AdminSundaySchool';
import AdminAddSundaySchool from './AdminAddSundaySchool';
import AdminSundaySchoolEdit from './AdminSundaySchoolEdit';
import AdminWorship from './AdminWorship';
import AdminAddWorship from './AdminAddWorship';
import AdminWorshipEdit from './AdminWorshipEdit';
import AdminWomensForum from './AdminWomensForum';
import AdminAddWomensForum from './AdminAddWomensForum';
import AdminWomensForumEdit from './AdminWomensForumEdit';
import AdminSocialWelfare from './AdminSocialWelfare';
import AdminAddSocialWelfare from './AdminAddSocialWelfare';
import AdminSocialWelfareEdit from './AdminSocialWelfareEdit';
import AdminContactDetails from './AdminContactDetails';
import AdminChangeLogo from './AdminChangeLogo';
import AdminChangePassword from './AdminChangePassword';
import AdminPasswordUpdateEmail from './AdminPasswordUpdateEmail';
import AdminGalleryMultiple from './AdminGalleryMultiple';


import Dashboard from '../components/Dashboard'

import Logout from './Logout';

import './AdminApp.css';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab)

let BaseURLuploads = WEB_URL_UPLOAD;

function AdminControlPanel() {
       const {rootState} = useContext(MyContext);
       const {isAuth,theUser} = rootState;

  useEffect( () => {
    window.addEventListener('scroll', toggleVisibility);
    document.body.classList.add('AdminpanelclassName');
  }, []);

  const scrollToTop =()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
const [is_visible, set_visible] = useState(false);

const toggleVisibility =()=> {
  if (window.pageYOffset > 700) {
    set_visible(true);
  
  } else {
    set_visible(false);
  }
}


  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      
<div className="App" id="App">
           <AdminNavbarmenu /> 
          
       <div className="app-content">
              <div className="page-content">
                     <Switch>
                     <Route exact path="/AdminBlog" component={AdminBlog}/>
                     <Route exact path="/Logout" component={Logout}/>
                     <Route exact path="/AdminBlogPreview/:Addpreview" component={AdminBlogPreview}/>
                     <Route exact path="/AdminAddBlog" component={AdminAddBlog}/>
                     <Route exact path="/AdminBlogEdit/:Addpreview" component={AdminBlogEdit}/>
                     <Route exact path="/AdminBlogDelete/:Addpreview" component={AdminBlogDelete}/>

                     <Route exact path="/AdminEvents" component={AdminEvents}/>
                     <Route exact path="/AdminAddEvent" component={AdminAddEvent}/>
                     <Route exact path="/AdminOrganzorsupdate/:Addpreview" component={AdminOrganzorsupdate}/>
                     <Route exact path="/AdminEventsPreview/:Addpreview" component={AdminEventsPreview}/>
                     <Route exact path="/AdminEventsEdit/:Addpreview" component={AdminEventsEdit}/>
                     <Route exact path="/AdminEventsDelete/:Addpreview" component={AdminEventsDelete}/>



                     <Route exact path="/AdminRearrangecategory" component={AdminRearrangecategory}/>
                     <Route exact path="/AdminEventsrec" component={AdminEventsrec}/>
                     <Route exact path="/AdminAddEventrec" component={AdminAddEventrec}/>
                     <Route exact path="/AdminOrganzorsupdaterec/:Addpreview" component={AdminOrganzorsupdaterec}/>
                     <Route exact path="/AdminEventsPreviewrec/:Addpreview" component={AdminEventsPreviewrec}/>
                     <Route exact path="/AdminEventsEditrec/:Addpreview" component={AdminEventsEditrec}/>
                     <Route exact path="/AdminEventsDeleterec/:Addpreview" component={AdminEventsDeleterec}/>

                     <Route exact path="/AdminVideo" component={AdminVideo}/>
                     <Route exact path="/AdminAddVideo" component={AdminAddVideo}/>
                     <Route exact path="/AdminVidoEdit/:Addpreview" component={AdminVidoEdit}/>
                     <Route exact path="/AdminVidoDelete/:Addpreview" component={AdminVidoDelete}/>

                     <Route exact path="/AdminTeam" component={AdminTeam}/>
                     <Route exact path="/AdminAddTeam" component={AdminAddTeam}/>

                     <Route exact path="/AdminGallery" component={AdminGallery}/>
                     <Route exact path="/Admingallerycategory" component={Admingallerycategory}/>
                     <Route exact path="/AdmingallerycategoryEdit/:Addpreview" component={AdmingallerycategoryEdit}/>
                     <Route exact path="/AdminGalleryPreview/:Addpreview" component={AdminGalleryPreview}/>
                     <Route exact path="/AdmingalleryAdd" component={AdmingalleryAdd}/>

                     <Route exact path="/AdminServiceTime" component={AdminServiceTime}/>
                     <Route exact path="/AdminserviestimeEdit/:Addpreview" component={AdminserviestimeEdit}/>

                     <Route exact path="/AdminLive" component={AdminLive}/>
                     <Route exact path="/AdminAddVideolive" component={AdminAddVideolive}/>
                     <Route exact path="/AdminHome" component={AdminHome}/>
                     <Route exact path="/AdminSliderEdit/:Addpreview" component={AdminSliderEdit}/>
                     <Route exact path="/AdminAbout" component={AdminAbout}/>
                     <Route exact path="/AdminPasterMessage" component={AdminPasterMessage}/>
                     <Route exact path="/AdminCreateSlider" component={AdminCreateSlider}/>
                     
                     <Route exact path="/AdminVideogallery" component={AdminVideogallery}/>
                     <Route exact path="/AdminAddVideogallery" component={AdminAddVideogallery}/>
                     <Route exact path="/AdminVidoEditgallery/:Addpreview" component={AdminVidoEditgallery}/>
                     <Route exact path="/AdminVidoDeletegallery/:Addpreview" component={AdminVidoDeletegallery}/>

                     <Route exact path="/AdminServicesall" component={AdminServicesall}/>
                     <Route exact path="/AdminFacilities" component={AdminFacilities}/>
                     <Route exact path="/AdminAddFacilitiessss" component={AdminAddFacilitiessss}/>
                     <Route exact path="/AdminFacilitiesEdit/:Addpreview" component={AdminFacilitiesEdit}/>
                     <Route exact path="/AdminChurchChoir" component={AdminChurchChoir}/>
                     <Route exact path="/AdminAddChurchChoir" component={AdminAddChurchChoir}/>
                     <Route exact path="/AdminChurchChoirEdit/:Addpreview" component={AdminChurchChoirEdit}/>
                     <Route exact path="/AdminSundaySchool" component={AdminSundaySchool}/>
                     <Route exact path="/AdminAddSundaySchool" component={AdminAddSundaySchool}/>
                     <Route exact path="/AdminSundaySchoolEdit/:Addpreview" component={AdminSundaySchoolEdit}/>
                     <Route exact path="/AdminWorship" component={AdminWorship}/>
                     <Route exact path="/AdminAddWorship" component={AdminAddWorship}/>
                     <Route exact path="/AdminWorshipEdit/:Addpreview" component={AdminWorshipEdit}/>
                     <Route exact path="/AdminWomensForum" component={AdminWomensForum}/>
                     <Route exact path="/AdminAddWomensForum" component={AdminAddWomensForum}/>
                     <Route exact path="/AdminWomensForumEdit/:Addpreview" component={AdminWomensForumEdit}/>
                     <Route exact path="/AdminSocialWelfare" component={AdminSocialWelfare}/>
                     <Route exact path="/AdminAddSocialWelfare" component={AdminAddSocialWelfare}/>
                     <Route exact path="/AdminSocialWelfareEdit/:Addpreview" component={AdminSocialWelfareEdit}/>

                     <Route exact path="/AdminContactDetails" component={AdminContactDetails}/>
                     <Route exact path="/AdminChangeLogo" component={AdminChangeLogo}/>
                     <Route exact path="/AdminChangePassword" component={AdminChangePassword}/>
                     <Route exact path="/AdminPasswordUpdateEmail/:Addpreview" component={AdminPasswordUpdateEmail}/>
                     <Route exact path="/AdminGalleryMultiple" component={AdminGalleryMultiple}/>
                     


                     <Route exact path="/" component={Dashboard}/>

                     
                     </Switch>
              <div className="clearfix"></div>
              </div>
       </div>

</div>
    </BrowserRouter>
  );
}

export default AdminControlPanel;
