import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import {Link } from "react-router-dom"; 

import {toast} from "react-toastify";


function AdminContactDetailsupdate(props) {
       
  let history = useHistory();

   const {ContactDetailsupdatesall} = useContext(MyContext);

   useEffect( () => {
     window.scrollTo(0, 0);
  }, []);
  const [loadingadd, setloadingadd] = useState(false);
const [userInfo, setuserInfo] = useState({
       maps: props.maps,
       address: props.address,
       phone: props.phone,
       email: props.email,
       twitter: props.twitter,
       facebook: props.facebook,
       instagram: props.instagram,
       youtube: props.youtube,
       getintouch: props.getintouch,
         
});
const onChangeValue = (e) => {
  
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
   } 

   const editcancel = () =>{
       props.cancel();
}
const blogupdates = async (event) => {
       try {
              setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                   
                     const dataadd = await ContactDetailsupdatesall(userInfo,props.ids);
                     if(dataadd.success === 1){
                            console.log('ContactDetailsupdatesall',dataadd);
                            //console.log(userInfo.description.value);
                            setloadingadd(false);
                            event.target.reset(); 
                            props.refresh();
                            props.cancel();
                     }else{
                            setloadingadd(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }

       } catch (error) { throw error;}    
}

  return (
   <>
     <div className="bookads2">
                      
                      <div className="">
                             <div className="">
                                   
                                    <div className="col-md-12" style={{padding:0}}>
                                   
        
               <form onSubmit={blogupdates} className="central-meta ">
             
               <div className="clearfix"></div>
               <div className="form-row">
              
 
 
 
                   <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Google Maps  </label>
                       <input type="text" name="maps" value={userInfo.maps} onChange={onChangeValue}  className="form-control" placeholder="Google Maps"  />
                   </div>
                 <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Address </label>
                       <input type="text" name="address" value={userInfo.address} onChange={onChangeValue}  className="form-control" placeholder="Address"  />
                   </div>
                 <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Phone  </label>
                       <input type="text" name="phone" value={userInfo.phone} onChange={onChangeValue}  className="form-control" placeholder="Phone"  />
                   </div>
                  <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Email </label>
                       <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}  className="form-control" placeholder="email"  />
                   </div>
                 <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Twitter </label>
                       <input type="text" name="twitter" value={userInfo.twitter} onChange={onChangeValue}  className="form-control" placeholder="Twitter"  />
                   </div>
                  <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Facebook </label>
                       <input type="text" name="facebook" value={userInfo.facebook} onChange={onChangeValue}  className="form-control" placeholder="Facebook"  />
                   </div>
                  <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Instagram </label>
                       <input type="text" name="instagram" value={userInfo.instagram} onChange={onChangeValue}  className="form-control" placeholder="Instagram"  />
                   </div>
                  <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> Youtube </label>
                       <input type="text" name="youtube" value={userInfo.youtube} onChange={onChangeValue}  className="form-control" placeholder="Youtube"  />
                   </div>
                 <div className="form-group col-md-12">
                       <label className="font-weight-bolds"> GET IN TOUCH </label>
                       <input type="text" name="getintouch" value={userInfo.getintouch} onChange={onChangeValue}  className="form-control" placeholder="GET IN TOUCH"  />
                   </div>
                 
                 
               
                   {loadingadd ?
                             <>
                                    <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                             </>
                          : 
               <div className="form-group col-sm-12 text-right">
                     <p onClick={editcancel} className="Adminbuttonlink2" style={{marginRight:"17px"}} ><span> <FontAwesomeIcon icon={['fas','edit']} />Cancel </span></p>
                     <button type="submit" className="btn btn-primary"> Submit  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      
                   </div> 
                    }
               
              
               </div>
               
           </form>
           </div>
 
 
   
           
           </div>
           </div>
 
 
 
 
 
           </div>





   </>
  );
}

export default AdminContactDetailsupdate;