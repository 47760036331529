import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../../contexts/MyContext';

import {toast} from "react-toastify";
import {NavLink} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL_UPLOAD } from '../../contexts/Apilinks';
import { propTypes } from 'react-bootstrap/esm/Image';
let BaseURLuploads = WEB_URL_UPLOAD;

function Navbarmenu() {
  const {menuslistall,addresslistall} = useContext(MyContext);

  useEffect( () => {
    menus();
    address();
 }, []);

 const searchsss = () => {
  setsearch(issearch === false ? true : false);

 }
 const [issearch, setsearch] = useState(false);
 const [ismenulist, setmenulist] = useState([]);
 const [islogo, setlogo] = useState([]);
 const [istopheaderbgimg, settopheaderbgimg] = useState([]);
 const [isserviceslist, setserviceslist] = useState([]);
 const [isservicestime, setservicestime] = useState([]);
 const [isgallerylist, setgallerylist] = useState([]);
 const [isaboutlist, setaboutlist] = useState([]);
  const menus = async() =>{
  try {
         const menusresult = await menuslistall();
                if(menusresult.success === 1){
                       console.log(menusresult.menulist);
                       setmenulist(menusresult.menulist);
                       setlogo(menusresult.menulist[0].logo);
                       settopheaderbgimg(menusresult.menulist[0].topheade_bgimg);
                       setserviceslist(menusresult.serviceslist);
                       setservicestime(menusresult.servicestime);
                       setgallerylist(menusresult.ggglllsss);
                       setaboutlist(menusresult.aboummmsss);
         }
  } catch (error) { throw error;}
}
const [isaddress, setaddress] = useState([]);
const [isphone, setphone] = useState([]);
const [isemail, setemail] = useState([]);
const [isfacebook, setfacebook] = useState([]);
const [istwitter, settwitter] = useState([]);
const [isinstagram, setinstagram] = useState([]);
const [isyoutube, setyoutube] = useState([]);
const [islivevideo, setlivevideo] = useState([]);
const [islivevideopath, setlivevideopath] = useState([]);
const address = async() =>{
  try {
         const addressresult = await addresslistall();
                if(addressresult.success === 1){
                       console.log(addressresult.addresslist);
                       setaddress(addressresult.addresslist[0].address);
                       setphone(addressresult.addresslist[0].phone);
                       setemail(addressresult.addresslist[0].email);
                       setfacebook(addressresult.addresslist[0].facebook);
                       settwitter(addressresult.addresslist[0].twitter);
                       setinstagram(addressresult.addresslist[0].instagram);
                       setyoutube(addressresult.addresslist[0].youtube);
                       setlivevideo(addressresult.addresslist[0].livevideo);
                       setlivevideopath(addressresult.addresslist[0].livevideo_path);
         }
  } catch (error) { throw error;}
}

  const [isMenu, setisMenu] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
};

      let boxClass = ["main-menu menu-right menuq1"];
     
      if(isMenu) {
        boxClass.push('menuq2');
      }else{
        boxClass.push('');
      }

      const [isMenusub, setisMenusub] = useState(false);
      const handleClicksub = () => {
        setisMenusub(isMenusub === false ? true : false);
        setisMenusubtime(false);
        setisMenusubgallery(false);
        setisMenusubAbout(false);
    };
    let boxClasssub = ["submenuss"];
    if(isMenusub) {
      boxClasssub.push('submenussress');
    }else{
      boxClasssub.push('');
    }

 const [isMenusubtime, setisMenusubtime] = useState(false);
      const handleClicksubtime = () => {
        setisMenusubtime(isMenusubtime === false ? true : false);
        setisMenusub(false);
        setisMenusubgallery(false);
        setisMenusubAbout(false);
    };
    let boxClasssubtime = ["submenuss"];
    if(isMenusubtime) {
      boxClasssubtime.push('submenussresstime');
    }else{
      boxClasssubtime.push('');
    }

const [isMenusubgallery, setisMenusubgallery] = useState(false);
      const handleClicksubgallery = () => {
        setisMenusubgallery(isMenusubgallery === false ? true : false);
        setisMenusub(false);
        setisMenusubtime(false);
        setisMenusubAbout(false);
    };
     
    let boxClasssubgallery = ["submenuss"];
    if(isMenusubgallery) {
      boxClasssubgallery.push('submenussressgallery');
    }else{
      boxClasssubgallery.push('');
    }

  const [isMenusubAbout, setisMenusubAbout] = useState(false);
    const handleClicksubAbout = () => {
      setisMenusubAbout(isMenusubAbout === false ? true : false);
      setisMenusub(false);
      setisMenusubtime(false);
      setisMenusubgallery(false);
  };
 let boxClasssubAbout = ["submenuss"];
    if(isMenusubAbout) {
      boxClasssubAbout.push('submenussressAbout');
    }else{
      boxClasssubAbout.push('');
    }

      return(
  <header className="header-middle">
  <div className="header-top-inner">
    <div className="header-top" style={{backgroundImage: `url(${BaseURLuploads+istopheaderbgimg})`}}>
      
      <div className="container">
        <div className="row">
            <div className="col-sm-4 col-md-4">
            {ismenulist.map((item, index) => ( 
              <div  key={item.menu_id} className="headerlefttimes2" >
                {item.menus === 'Church Service Time' ? <>
                <p className="headerlefttimes4">{item.menus} <FontAwesomeIcon icon={['fas','chevron-down']} style={{fontSize: "12px"}}/></p>
                    <div className="headerlefttimes">
                    
                    
                    {isservicestime.map((itemsqltime, index) => ( 
                       <div  key={itemsqltime.serviessubtime_id} className="col-md-12 " style={{padding:0}}>
                         {itemsqltime.timeone !== '' ?   <div className="mqs2">
                         <div className="col-md-12 " style={{padding:0}}>
                            <h4  className="headerlefttimes3" >  {itemsqltime.days}
                            </h4>
                            </div> 
                           
                            <div className="col-md-12" style={{padding:0}}>
                            <p className="headerlefttimes3" > 
                            <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.nameone} </b> :  {itemsqltime.timeone} 
                            </span>

                            <br />
                            {itemsqltime.timetwo !== '' ? <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.nametwo} </b> :  {itemsqltime.timetwo} 
                            </span> : null}
                            {itemsqltime.timethree !== '' ? <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.namethree} </b> :  {itemsqltime.timethree} 
                            </span> : null}

                           
                            
                            </p>
                            </div> 
                            </div> : null}
                        </div> 
                      ))}      














                      </div> 
              </>
              : null}
              </div>
            ))}
            </div>
            <div className="col-sm-8 col-md-8">
            <span className="res5c newresss" style={{ display: 'none' }} onClick={toggleClass} >  <FontAwesomeIcon icon={['fas','bars']} />  </span>
                <div className="pull-right">
                  <div className="dgt-social icon-show name-hidden pull-left">
                    <ul> 
                    
                    <li className="watchsss newresnnn"> <div className="button3">
                          <div className="innercircle circle" id="x"></div>
                          <div className="outcircle circle the3 animation31"></div>
                          <div className="innercircle circle" id="y"></div>
                        </div>  <NavLink   to={`/${islivevideopath}`} className="qqqmsss">{islivevideo}</NavLink>   </li>


                        
                        


                        {issearch === false ? <>
                           <li className="twitter" onClick={searchsss}> <NavLink exact  to="/Search"><span>search</span><FontAwesomeIcon icon={['fas','search']} className="ion-social-search"/></NavLink></li>  </>
                    : 
                    <li className="twitter" onClick={searchsss}> <NavLink exact  to="/"><span>search</span><FontAwesomeIcon icon={['fas','search']} className="ion-social-search"/></NavLink></li> 
                      }
                    {isemail !== ''  ? 
                      <li className="Email"><a title="Email" href={`mailto:${isemail}`} rel="noopener noreferrer" target="_blank"><span>Email</span><FontAwesomeIcon icon={['fas','envelope']} className="ion-social-email"/></a></li>
                     : null}
                    {istwitter !== '' ? 
                      <li className="twitter"><a title="Twitter" href={istwitter} rel="noopener noreferrer" target="_blank"><span>Twitter</span><FontAwesomeIcon icon={['fab','twitter']} className="ion-social-twitter"/></a></li>
                    : null}
                    {isfacebook !== '' ?
                      <li className="facebook"><a title="Facebook" href={isfacebook} rel="noopener noreferrer" target="_blank"><span>Facebook</span><FontAwesomeIcon icon={['fab','facebook-f']} className="ion-social-facebook"/></a></li>
                     : null}
                    {isinstagram !== '' ? 
                      <li className="Instagram"><a title="Instagram" href={isinstagram} rel="noopener noreferrer" target="_blank"><span>Instagram</span><FontAwesomeIcon icon={['fab','instagram']} className="ion-social-instagram"/></a></li>
                    : null}
                    {isyoutube !== '' ? 
                      <li className="youtube"><a title="Youtube" href={isyoutube} rel="noopener noreferrer" target="_blank"><span>Youtube</span><FontAwesomeIcon icon={['fab','youtube']} className="ion-social-youtube-outline"/></a></li>
                    : null}
                      
                     
                      
                      
                      
                      
                    </ul>
                  </div>                
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

    <nav className="navBar " >
        <div className="container">
             <div className="row">
             <div className=" col-md-3" style={{padding:0}}>
                    <div className=" logo">
                        <NavLink  to="/">
                          <img src={BaseURLuploads+islogo} alt="logo" /> 
                        </NavLink>
                    </div>
                    <li className="watchsss newresss"> <div className="button3">
                          <div className="innercircle circle" id="x"></div>
                          <div className="outcircle circle the3 animation31"></div>
                          <div className="innercircle circle" id="y"></div>
                        </div>  <NavLink   to={`/${islivevideopath}`} className="qqqmsss">{islivevideo}</NavLink>   </li>
                  
                  </div>
             <div className=" col-md-9" style={{padding:0}}>
                   
             <span className="res5c res5c2 newresnnn" style={{ display: 'none' }} onClick={toggleClass} >  <FontAwesomeIcon icon={['fas','bars']} />  </span>
                    <ul className={boxClass.join(' ')}>
                        {ismenulist.map((item, index) => ( 
                          <li  key={item.menu_id} className={`menu-item ${item.manu_path}`}  >
                            {item.menus === 'Our Services' ? <>

                              <NavLink onClick={handleClicksub}  to="#" >{item.menus} <FontAwesomeIcon icon={['fas','chevron-down']} style={{fontSize: "12px"}}/></NavLink>
                              <ul className={ boxClasssub.join(' ')} >
                              {isserviceslist.map((itemsql, index) => ( 
                                <li  key={itemsql.serviessubmenu_id } className="menu-item" onClick={toggleClass} >  <NavLink   to={`/${itemsql.servies_path}`}>{itemsql.service_menu}</NavLink></li>
                              ))}
                              </ul>
                              
                              </>
                           : <> 
                           {item.menus === 'Church Service Time' ? <>
                                <NavLink onClick={handleClicksubtime}  to="#" >{item.menus} <FontAwesomeIcon icon={['fas','chevron-down']} style={{fontSize: "12px"}}/></NavLink>
                                <ul className={ boxClasssubtime.join(' ')} >
                                {isservicestime.map((itemsqltime, index) => ( 
                                  <li  key={itemsqltime.serviessubtime_id } className="menu-item col-md-12 " onClick={toggleClass} >
                                    {itemsqltime.timeone !== '' ? <div className="mqs2" >

                                     <div className="col-md-12 mqz" >
                                     <h4  className="headerlefttimes3" >  {itemsqltime.days}
                            </h4>
                                       
                                        </div> 
                                        <div className="col-md-12" >
                                        <p className="headerlefttimes3" > 
                                        <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.nameone} </b> :  {itemsqltime.timeone} 
                            </span>

                            <br />
                            {itemsqltime.timetwo !== '' ? <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.nametwo} </b> :  {itemsqltime.timetwo} 
                            </span> : null}
                            {itemsqltime.timethree !== '' ? <span className="col-md-12" style={{padding:0}}> 
                            <b> {itemsqltime.namethree} </b> :  {itemsqltime.timethree} 
                            </span> : null}
                            
                                          </p>
                                          </div> 
                                    
                                          </div> : null}
                                    
                                    </li>
                                ))}
                                </ul>
                           
                           </> :  <> 




                           {item.menus === 'Gallery' ? <>
                           <NavLink onClick={handleClicksubgallery}  to="#" >{item.menus} <FontAwesomeIcon icon={['fas','chevron-down']} style={{fontSize: "12px"}}/></NavLink>
                              <ul className={ boxClasssubgallery.join(' ')} >
                              {isgallerylist.map((itemsql, index) => ( 
                                <li  key={itemsql.gallerysubmenu_id } className="menu-item" onClick={toggleClass} >  <NavLink   to={`/${itemsql.gallerysubmenu_path}`}>{itemsql.gallerysubmenu_menu}</NavLink></li>
                              ))}
                              </ul>
                           
                           </> : <>
                                  
                            

{item.manu_path === 'About' ? <>
                           <NavLink onClick={handleClicksubAbout}  to="#" >{item.menus} <FontAwesomeIcon icon={['fas','chevron-down']} style={{fontSize: "12px"}}/></NavLink>
                              <ul className={ boxClasssubAbout.join(' ')} >
                              {isaboutlist.map((itemsql, index) => ( 
                                <li  key={itemsql.aboutmenu_id } className="menu-item" onClick={toggleClass} >  <NavLink   to={`/${itemsql.aboutmenu_path}`}>{itemsql.aboutmenu_name}</NavLink></li>
                              ))}
                              </ul>
                           
                           </> :
                                  <NavLink exact activeClassName='is-active' onClick={toggleClass}  to={`/${item.manu_path}`}>{item.menus}</NavLink>
                                }


</>   }










                           
                                  </>
                           
                           }
                               
                             
                             </>
                             
                             }
                           
                           </li>
                        ))}
                      
                        
                </ul>
                        </div>
            </div>


            </div>
    </nav>
  </header>
        );
    }

export default Navbarmenu;


const style = {
  overflow: 'visible',
  cursor: 'pointer',
  // disable touch highlighting on devices
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
}