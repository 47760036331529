import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function OurTeam() {
   const {Breadcrumbslistall,ourteamsall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     ourteams();
     window.scrollTo(0, 0);
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_team);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 const [isourteams, setourteams] = useState([]);
 const ourteams = async() =>{
 try {
        const ourteamsss = await ourteamsall();
               if(ourteamsss.success === 1){
                      console.log('ourteams',ourteamsss.ourteamslist);
                      setourteams(ourteamsss.ourteamslist);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Our Team --------------------------------------------------------------------------------------------------- */}

<div className="sectionthree">
   <div className="container">
      <div className="row">
      
         <div className="col-md-12">
            <h1> Our  <span> Team </span> </h1>
            
         </div>
         
         {isourteams.map((item, index) => ( 
      <div key={item.homeimages_id} className="col-md-4">
         <div className="dgt-team-item-inner">
            <div className="dgt-team-avatar">
               <img src={BaseURLuploads+item.image} className="attachment-full size-full" alt=""  />                        
            </div>
            <div className="dgt-team-content">
               <div className="dgt-team-info">
                  <h4>{item.name}</h4>
                  <h6>{item.name_position}</h6>
               </div>
            <div className="dgt-team-social">
            <div className="dgt-social icon-show name-hidden">
                  <ul>
                     {item.facebook !== '' ? 
                             <li className="facebook"><a title="facebook" href={item.facebook} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','facebook-f']} className="ion-social-twitter"/></a></li> 
                     : null} 
                     {item.twitter !== '' ? 
                
                            <li className="twitter"><a title="twitter" href={item.twitter} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','twitter']} className="ion-social-twitter"/></a></li>
                     : null} 
                     {item.instagram !== '' ? 
                             <li className="instagram"><a title="instagram" href={item.instagram } rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','instagram']} className="ion-social-twitter"/></a></li>
                     : null} 
                   </ul>
              </div>
         </div>
        
                                                    </div>
                    </div>
                </div>

         ))}

  </div>
   </div>
</div> 


   </>
  );
}

export default OurTeam;