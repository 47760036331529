import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {SRLWrapper } from 'simple-react-lightbox';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

const options = {
   settings: {
     overlayColor: "#034cfc",
     autoplaySpeed: 1500,
     transitionSpeed: 900,
   },
   buttons: {
     backgroundColor: "black",
     iconColor: "rgba(126, 172, 139, 0.8)",
   },
   caption: {
     captionColor: "#a6cfa5",
     captionFontFamily: "Raleway, sans-serif",
     captionFontWeight: "300",
     captionTextTransform: "uppercase",
   }
 };

function GalleryPreview(props) {
   const {Breadcrumbslistall,GalleryPreviewlistall} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
     GalleryPreviewlist(props.match.params.Addpreview);
   
     window.scrollTo(0, 0);
  }, []);

 

  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_gallery);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 

 const [isGalleryPreview, setGalleryPreview] = useState([]);
 const GalleryPreviewlist = async (ids) =>{
 try {
        const GalleryPreviewlistqqq = await GalleryPreviewlistall(ids);
               if(GalleryPreviewlistqqq.success === 1){
                      console.log('GalleryPreview',GalleryPreviewlistqqq.GalleryPreviewlistsss);
                      setGalleryPreview(GalleryPreviewlistqqq.GalleryPreviewlistsss);
        }
 } catch (error) { throw error;}
}
  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- Who We Are --------------------------------------------------------------------------------------------------- */}
<div className="gallerysss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         <div className="godgrace-go-categories">
            <Link to="/Gallery" className="buttonlink">Go back to the categories</Link>
         </div> 
         <SRLWrapper options={options}>
          {isGalleryPreview.map((item, index) => ( 
                  <a  key={item.galleryall_id } className="col-md-4" href={BaseURLuploads+item.galleryall_image}>
                  <img src={BaseURLuploads+item.galleryall_image} alt=""/>
                  </a>
            ))} 
                
         </SRLWrapper> 
                 
              
  </div>
  </div>
   </div>
</div>






   </>
  );
}

export default GalleryPreview;