import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from './contexts/MyContext';
import Dashboard from './components/Dashboard'
import AdminControlPanel from './controlpanel/AdminControlPanel'

function Apppremission() {
       const {rootState} = useContext(MyContext);
       const {isAuth,theUser} = rootState;
      
  return (
   <>
       {isAuth === true && theUser !== null ?
              <> <AdminControlPanel storageid={theUser}/> </>
       : null } 
       {isAuth === false && theUser === null ?
              <>  <Dashboard/> </>
       : null } 
      
        

        </>
  );
}

export default Apppremission;