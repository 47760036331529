import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Blog() {
   const {Breadcrumbslistall,allblogslist} = useContext(MyContext);

   useEffect( () => {
     Breadcrumbslist();
    
  }, []);
 
  const [isBreadcrumbsimg, setBreadcrumbsimg] = useState([]);
  const [isBreadcrumbsheading, setBreadcrumbsheading] = useState([]);
  const [isBreadcrumbsnav, setBreadcrumbsnav] = useState([]);
   const Breadcrumbslist = async() =>{
   try {
          const Breadcrumbslistsss = await Breadcrumbslistall();
              if(Breadcrumbslistsss.success === 1){
                     console.log('Breadcrumbs',Breadcrumbslistsss.Breadcrumbslistqqq);
                     setBreadcrumbsimg(Breadcrumbslistsss.Breadcrumbslistqqq[0].Breadcrumbs_img) ;
                     setBreadcrumbsheading(Breadcrumbslistsss.Breadcrumbslistqqq[0].heading_Blog);
                     setBreadcrumbsnav(Breadcrumbslistsss.Breadcrumbslistqqq[0].nav_home);
          }
   } catch (error) { throw error;}
 }
 
 const [pageNumber, setPageNumber] = useState(1);
 const observer = useRef()
 const loadingRef = useCallback(node => {
   
   if (observer.current) observer.current.disconnect()
   observer.current = new IntersectionObserver(entries => {
     if (entries[0].isIntersecting ) {
       setPageNumber(prevPageNumber => prevPageNumber + 1)
       //latestfeeds(prevPageNumber => prevPageNumber + 1)
     }
   })
   if (node) observer.current.observe(node)
 }, [])
 useEffect( () => {
   window.scrollTo(0, 0);
}, []); 
 useEffect( () => {
   if (isFetching === true) return;
   allblogs(pageNumber);
       return () => {
         console.log("This will be logged on unmount");
       }
 }, [pageNumber])

 const [isallblogs, setallblogs] = useState([]);
 const [isFetching, setIsFetching] = useState(false);

 const allblogs = async(pageNumber) => {
   const allblogsss = await allblogslist(pageNumber);
   if(allblogsss.success === 1){
          console.log('allblogs',allblogsss);
          setallblogs(prevBooks => {
                 return [...prevBooks, ...allblogsss.data]
          })
       
          if(allblogsss.data.length < 1){
                 setIsFetching(true);
          }
   }
}

  return (
   <>
<div className="breadcrumps" style={{backgroundImage: `url(${BaseURLuploads+isBreadcrumbsimg})`}}>
   <div className="container2">
        <div className="row2">
    <h1> {isBreadcrumbsheading} </h1>
    <div className="godgrace_breadcrumbs">
     <Link to="/" className="godgrace-bc-trail-begin">{isBreadcrumbsnav}</Link> 
            <span className="godgrace_bc_sep"><FontAwesomeIcon icon={['fas','angle-right']} className="" /></span>
            <span className="godgrace-bc-trail-end">{isBreadcrumbsheading}</span>
            <div className="clear"></div>
     </div>

        </div>
    </div>
</div>
{/* -------------------- --------------------------------------------------------------------------------------------------- */}

<div className="blogsss">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
         {isallblogs.map((item, index) => ( 
               <div ref={loadingRef} key={item.blog_id } className="col-md-4">
               <div className="sectionseven3">
                  <img src={BaseURLuploads+item.image} alt="" />
                  <h3> {item.title} </h3>
                  <p className="sectionseven2s"><FontAwesomeIcon icon={['fas','calendar-alt']} className="ion-location"/> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment> </p>
                  <p className="sectionseven2" dangerouslySetInnerHTML={{ __html: item.describtion }} /> 
                
                  <Link to={`/BlogPreview/${item.blog_id}/${item.title.replace(/\s/g, '-')}`} className="buttonlink"><span>  <FontAwesomeIcon icon={['fas','cross']} className="ion-android-call"/> Read More </span></Link>
                   
                 
               </div>
               </div>
            ))}
  </div>
  </div>
   </div>
</div>

  


   </>
  );
}

export default Blog;